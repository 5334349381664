import React, { useState,useEffect,useRef } from "react";
import icon from '../../../images/icon-logo.svg';
import Modal from 'react-bootstrap/Modal'
import parse from 'html-react-parser';
import './alert-style.css';
function ErroAlert(props) {
  const show = props.show;
  const message = props.message;
  const closeModal = () => {
        props.setAlertShow(false); 
    }
    // setTimeout(closeModal,4000);
    return(
      <Modal show={show} onHide={closeModal} size="md">
            <Modal.Header closeButton>
            <div className="img-box  align-items-center">
                <img src="/assets/img/icon-logo.svg" />
            </div>
            </Modal.Header>
            <Modal.Body>
            <div className="singup-form-div">
                <div className="form-box flyer_popup">
                    <div className="row">
                        <div className="col-md-12 wel_text">
                          <p>{props.message}</p>
                        </div>
                    </div>
                </div>
            </div>
            </Modal.Body>
      </Modal>
    );
}
export default ErroAlert;
