import React,{useState,useEffect,useRef} from "react";
import { Range } from 'rc-slider';
import axios from 'axios';
import { Redirect,useHistory,useLocation } from "react-router-dom";
import LoadingIcon from '../../helpers/LoadingIcon';
import SearchListSec from './../../page/search/blocks/SearchListSec';
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import 'rc-slider/assets/index.css';
import '../../../css/search.css';

function Search(props) {
  let history = useHistory();
  let location = useLocation();
  const postedForm = location.state && location.state.homeFormData ?location.state.homeFormData : {};
  const currentPage = location.pathname;
  const initTimeRange = [0,1440];
  const searchMainRef = useRef();
  const searchFrom = useRef();
  const [activeBox,setActiveBox] = useState(null);
  const [loadedClasses,setLoadedClasses] = useState({});
  const [inputExclusiveChannels,setInputExclusiveChannels] = useState(null);
  const [activeEleBlock,setActiveEleBlock] = useState(null);
  const [timeRange,setTimeRange] = useState(initTimeRange);
  const [isLoading,setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [dateErrors, setDateErrors] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateInputs, setDateInputs] = useState({});
  const { register,handleSubmit,clearErrors,setError,errors } = useForm({defaultValues:postedForm});
  const [daysInputs, setDaysInputs] = useState({});
  const [inputs, setInputs] = useState({});
  
  const [categories, setCategories] = useState({});
  const [classLengths, setClassLengths] = useState({});
  const [classFormats, setClassFormats] = useState({});
  const [classLanguages, setClassLanguages] = useState({});
    const handelShowActiveBox = (event,box)=>{
    setActiveEleBlock(event.target.parentNode);
    setActiveBox(box);
  };
  const handleHideActiveBox = () =>{
    setActiveBox(null);
  }

  const isSearchPage = () =>{
    return currentPage == '/search'?true:false;
  }

  const ages = [3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,99];

    
  const handleTimeRange = (values) =>{
    var hours1 = Math.floor(values[0] / 60);
    var minutes1 = values[0] - (hours1 * 60);

    if (hours1.length == 1) hours1 = '0' + hours1;
    if (minutes1.length == 1) minutes1 = '0' + minutes1;
    if (minutes1 == 0) minutes1 = '00';
    if (hours1 >= 12) {
        if (hours1 == 12) {
            hours1 = hours1;
            minutes1 = minutes1 + " PM";
        } else {
            hours1 = hours1 - 12;
            minutes1 = minutes1 + " PM";
        }
    } else {
        hours1 = hours1;
        minutes1 = minutes1 + " AM";
    }
    if (hours1 == 0) {
        hours1 = 12;
        minutes1 = minutes1;
    }
    const time1= hours1+':'+minutes1;
    
    var hours2 = Math.floor(values[1] / 60);
    var minutes2 = values[1] - (hours2 * 60);

    if (hours2.length == 1) hours2 = '0' + hours2;
        if (minutes2.length == 1) minutes2 = '0' + minutes2;
        if (minutes2 == 0) minutes2 = '00';
        if (hours2 >= 12) {
            if (hours2 == 12) {
              hours2 = hours2;
              minutes2 = minutes2 + " PM";
            } else if (hours2 == 24) {
              hours2 = 11;
              minutes2 = "59 PM";
            } else {
                hours2 = hours2 - 12;
                minutes2 = minutes2 + " PM";
              }
            } else {
            hours2 = hours2;
            minutes2 = minutes2 + " AM";
          }
          const time2= hours2+':'+minutes2;
          
          setTimeRange(values);
          setDateInputs(dateInputs => ({...dateInputs, start_time:time1,end_time:time2} ) );
        };

    const handleClickOutside = (event) =>{
          if(event.target.className && event.target.className=='react-datepicker__close-icon'){
            return true;
          }
          if(event.target.parentNode && event.target.parentNode.className && event.target.parentNode.className == 'react-datepicker__week'){
            return true;
          } 
          if (activeBox && searchMainRef && searchMainRef.current && !searchMainRef.current.contains(event.target)) {
            setActiveBox(null);
          }
        };
        
        useEffect(() => {
          handleTimeRange(timeRange);
          if(isSearchPage()){
            if(location.state && location.state.prevData){
              const prevData = location.state.prevData;
              setTimeRange(prevData.timeRange);
              setStartDate(prevData.startDate);
              setEndDate(prevData.endDate);
              handleTimeRange(prevData.timeRange);
            }
            formSubmit(postedForm);
          }
          getClassFormats();
          getCategories();
          getClassLengths();
          getClassLanguages();
          
        },[]);

        useEffect(() =>{
          if(inputExclusiveChannels){
            handleSubmit(formSubmit)();
          }
        },[inputExclusiveChannels])
       
        async function getCategories() {
          const dbChannels = await axios.get("get-channels",{params:{show_in_interests:'yes'}});
          setCategories(dbChannels.data.data);
        }
        async function getClassLengths() {
          const dbClassLengths = await axios.get("get-class_lengths");
          setClassLengths(dbClassLengths.data.data);
        }
        async function getClassFormats() {
          const dbClassFormats = await axios.get("get-class_formats");
          setClassFormats(dbClassFormats.data.data);
        }
        async function getClassLanguages() {
          const dbClassLanguages = await axios.get("get-class_languages");
          setClassLanguages(dbClassLanguages.data.data);
        }


    //Form submitting    
        const formSubmit = (data)=>{
          if(!isSearchPage()){
            setActiveBox(null);
            window.scrollTo(0, 0);
            history.push({pathname:'/search',state: { homeFormData:data, prevData:{startDate:startDate,endDate:endDate,timeRange:timeRange}}});
          }

          var formdata = new FormData();
          if(startDate || endDate){
            if(startDate && endDate){
              if(startDate.getTime() > endDate.getTime()){
                setActiveBox('date');
                setDateErrors('Please select valid date ranges.');
                return false;
              }
              //positive case
              setDateErrors(null); 
              formdata.append('start_date',startDate.toLocaleDateString());
              formdata.append('end_date',endDate.toLocaleDateString());
            }else{
              setActiveBox('date');
              setDateErrors('Please select both start and end dates.');
              return false;
            }
          }else{
            formdata.delete('start_date')
            formdata.delete('end_date')
            setDateErrors(null);
          }
          Object.keys(data).map(function(key) {
            if(typeof(data[key]) == 'string'){
              formdata.append(key,data[key]);
            }else{
              console.log(key);
              console.log(data[key]);
              formdata.append(key,JSON.stringify(data[key]));
            }
        });
        setIsLoading(true);
        setLoadedClasses({});

    axios.post('get-search-results', formdata).then(response => {
        let res = response.data;
        if(res && res.status && res.status=='success'){
          setIsLoading(false);
          setActiveBox(null);
          setLoadedClasses(res.data);
        }else{
          alert('Invalid reponse from server.')
        }
    }).catch(error => {
       console.log(error);
        alert('Could not connect to server.');
    });        
    
  };
  document.addEventListener('click', handleClickOutside,false);
  return (
    <>
<section className="search_section search-className" ref={searchMainRef}>
  <div className="container-fluid cf">
    <div className="row">
      <div className="col-md-12">
        <div className="jumbotron">
          <form ref={searchFrom} onSubmit={handleSubmit(formSubmit)}>
            <div className="row">
              <div className="col-sm-8 col-md-10">
                <div className="form-group">
                  <input type="hidden" name="channel_type" ref={register} value={inputExclusiveChannels?inputExclusiveChannels:''} />
                  <input type="text" className="form-control" ref={register} id="search" placeholder="Search..." name="search_keyword" />
                </div>
              </div>
              <div className="col-sm-4 col-md-2">
                <div className="form-group">
                  <button type="submit" className="btn btn-primary search_btn"><img src="assets/img/search.svg" /> SEARCH</button>
                </div>
              </div>
            </div>  
            <div className="row">
              <div className="col-md-12">
                <ul className="row align-items-center search-items-main">
                  <li className="col box-height1"><a onClick={(event)=>handelShowActiveBox(event,'Dates')}>Dates</a>
                    <div id="myDIV" className={"search_tabs "+(activeBox=='Dates'?'show':'')}>
                        <fieldset>
                        <p className="dates_p">Starting </p>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="control-group">
                              <div className="controls">
                                <label className="radio inline" htmlFor="date_period-0">
                                  <input type="radio" ref={register} name="date_period" className="search_input" id="date_period-0" value="this_week" /> This week or later</label>
                              </div>
                          </div>
                          </div>
                          <div className="col-md-4">
                            <div className="control-group">
                              <div className="controls">
                                <label className="radio inline" htmlFor="date_period-1">
                                  <input type="radio" name="date_period" ref={register} className="search_input" id="date_period-1" value="next_week" /> Next week or later</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="control-group">
                              <div className="controls">
                                <label className="radio inline" htmlFor="date_period-2">
                                  <input type="radio" name="date_period" ref={register} className="search_input" id="date_period-2" value="next_month" /> Next month or later</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="date_hr" />
                        <p className="dates_p">Or specific dates:</p>
                        <div className="control-group">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="controls">
                                    <DatePicker
                                        className="form-control input-large hasDatepicker"
                                        selected={startDate}
                                        isClearable
                                        autoComplete="off"
                                        onChange={date => setStartDate(date)}
                                        selectsStart
                                        startDate={startDate}
                                        placeholderText="Start after"
                                        endDate={endDate}
                                        minDate={new Date()}
                                        showDisabledMonthNavigation
                                      />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="controls">
                                    <DatePicker
                                    isClearable
                                    className="form-control input-large hasDatepicker"
                                      selected={endDate}
                                      onChange={date => setEndDate(date)}
                                      selectsEnd
                                      autoComplete="off"
                                      startDate={startDate}
                                      placeholderText="End by"
                                      endDate={endDate}
                                      minDate={startDate}
                                    />
                                    </div>
                                </div>
                                {dateErrors && <p className="text-danger">{dateErrors}</p>}
                            </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="control-group">
                              <div className="controls">
                                <button id="confirmsignup" name="confirmsignup" type="button" onClick={handleHideActiveBox} className="btn btn-success register-btn cancel_btn">Cancel</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6  text-right">
                            <div className="control-group">
                              <div className="controls">
                                <button id="confirmsignup" name="confirmsignup" type="submit" className="btn btn-success register-btn">Apply</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        </fieldset>
                    </div>
                  </li>
                  <li className="col box-height1"><a onClick={(event)=>handelShowActiveBox(event,'daysTime')}>Days/Time</a>
                   <div id="myDIV1" className={"search_tabs "+(activeBox=='daysTime'?'show':'')} >
                        <fieldset>
                              <ul className="days_ul">
                                  <li><input className="checkbox-req-time-day-week" ref={register} type="checkbox" id="M" value="1" name="search_week"/><a className="a-req-time-day"><label htmlFor="M">M</label></a></li>
                                  <li><input className="checkbox-req-time-day-week" type="checkbox" id="tue" ref={register} value="2" name="search_week"/><a className="a-req-time-day"><label htmlFor="tue">T</label></a></li>
                                  <li><input className="checkbox-req-time-day-week" type="checkbox" id="wed" ref={register} value="3" name="search_week"/><a className="a-req-time-day"><label htmlFor="wed">W</label></a></li>
                                  <li><input className="checkbox-req-time-day-week" type="checkbox" id="thu" ref={register} value="4" name="search_week"/><a className="a-req-time-day"><label htmlFor="thu">T</label></a></li>
                                  <li><input className="checkbox-req-time-day-week" type="checkbox" id="fri" ref={register} value="5" name="search_week"/><a className="a-req-time-day"><label htmlFor="fri">F</label></a></li>
                                  <li><input className="checkbox-req-time-day-week" type="checkbox" id="sat" ref={register} value="6" name="search_week"/><a className="a-req-time-day"><label htmlFor="sat">S</label></a></li>
                                </ul>
                        <div className="row">
                          <div id="time-range">
                            <p><span className="slider-time">{dateInputs.start_time}</span> <span className="slider-time2 float-right">{dateInputs.end_time}</span></p>
                            <div className="sliders_step1">
                                <div>
                                  <Range min={0} max={1440} step={15} value={timeRange} onChange={handleTimeRange}/>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="control-group">
                              <div className="controls">
                                <button id="confirmsignup" name="confirmsignup" type="button" onClick={handleHideActiveBox} className="btn btn-success register-btn cancel_btn">Cancel</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 text-right">
                            <div className="control-group">
                              <div className="controls">
                                <button id="confirmsignup" name="confirmsignup" type="submit" className="btn btn-success register-btn">Apply</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        </fieldset>
                    </div>
                  </li>
                  <li className="col box-height1"><a onClick={(event)=>handelShowActiveBox(event,'Length')}>Length</a>
                    <div id="myDIV2" className={"search_tabs "+(activeBox=='Length'?'show':'')}>
                        <fieldset>
                          <div className="row">
                        {Object.keys(classLengths).length  && classLengths.map((length,index) => {
                              return (<div className="col-md-4" key={index}>
                              <div className="control-group">
                                <div className="controls">
                                  <label className="radio inline" htmlFor={'Semester'+length.id}>
                                    <input type="checkbox" ref={register} className="search_input" name="input_class_lengths" id={'Semester'+length.id} value={length.id} /> {length.length_title}</label>
                                </div>
                               </div>
                            </div>
                            )
                          })}
                          </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="control-group">
                              <div className="controls">
                                <button id="confirmsignup" name="confirmsignup" type="button" onClick={handleHideActiveBox} className="btn btn-success register-btn cancel_btn">Cancel</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 text-right">
                            <div className="control-group">
                              <div className="controls">
                                <button id="confirmsignup" name="confirmsignup" className="btn btn-success register-btn">Apply</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        </fieldset>
                    </div>
                  </li>
                  <li className="col box-height1 active"><a onClick={(event)=>handelShowActiveBox(event,'Age')}>Age</a>
                    <div id="myDIV3" className={"search_tabs "+(activeBox=='Age'?'show':'')}>
                        <fieldset>
                          <div className="row age_form">
                                {ages.map(function (age, index) {
                                    return(
                                    <div className="col-md-3" key={index}>
                                      <div className="control-group">
                                        <div className="controls">
                                          <label className="radio inline" htmlFor={'age-'+age}>
                                            <input type="checkbox" className="search_input" ref={register} name="input_ages" id={'age-'+age} value={age} />&nbsp; {age==99?'Parents':age} </label>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                            </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="control-group">
                              <div className="controls">
                                <button id="confirmsignup" name="confirmsignup" type="button" onClick={handleHideActiveBox} className="btn btn-success register-btn cancel_btn">Cancel</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 text-right">
                            <div className="control-group">
                              <div className="controls">
                                <button id="confirmsignup" name="confirmsignup" className="btn btn-success register-btn">Apply</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        </fieldset>
                    </div>
                  </li>
                  <li className="col box-height1"><a onClick={(event)=>handelShowActiveBox(event,'Subject')}>Subject</a>
                    <div id="myDIV4" className={"search_tabs "+(activeBox=='Subject'?'show':'')}>
                        <fieldset>
                          <div className="row">
                            {Object.keys(categories).length && categories.map((channel,index)=>{
                              return (
                                <div className="col-md-4" key={index}>
                                  <div className="control-group">
                                    <div className="controls">
                                    <label className="radio inline" htmlFor={'channel'+channel.id}>
                                      <input type="checkbox" name="input_channels" className="search_input" ref={register} id={'channel'+channel.id} value={channel.id} /> {channel.channel_name}</label>
                                    </div>
                                  </div>
                                </div>
                              )
                            }) }
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="control-group">
                              <div className="controls">
                                <button id="confirmsignup" name="confirmsignup" type="button" onClick={handleHideActiveBox} className="btn btn-success register-btn cancel_btn">Cancel</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 text-right">
                            <div className="control-group">
                              <div className="controls">
                                <button id="confirmsignup" name="confirmsignup" className="btn btn-success register-btn">Apply</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        </fieldset>
                    </div>
                  </li>
                  <li className="col box-height1"><a onClick={(event)=>handelShowActiveBox(event,'Format')}>Format</a>
                    <div id="myDIV5" className={"search_tabs "+(activeBox=='Format'?'show':'')}>
                        <fieldset>
                          <div className="row">
                          {Object.keys(classFormats).length && classFormats.map((format,index)=>{
                              return (
                              <div className="col-md-6" key={index}>
                                  <div className="control-group">
                                      <div className="controls">
                                        <label className="radio inline" htmlFor={"format"+index}>
                                        <input type="checkbox" name="input_formats" ref={register} className="search_input" id={"format"+index} value={format.id} /> {format.format_name}</label>
                                      </div>
                                  </div>
                              </div>
                              )
                          })}
                          </div>
                          
                        </fieldset>
                    </div>
                  </li>
                  <li className="col box-height1"><a onClick={(event)=>handelShowActiveBox(event,'Language')}> Language</a>
                    <div id="myDIV6" className={"search_tabs "+(activeBox=='Language'?'show':'')}>
                        <fieldset>
                        <div className="row">
                        {Object.keys(classLanguages).length && classLanguages.map((language,index)=>{
                              return (
                                <div className="col-md-6" key={index}>
                                  <div className="control-group">
                                    <div className="controls">
                                      <label className="radio inline" htmlFor={'lang-'+index}>
                                        <input type="checkbox"  className="search_input" name="input_languages" ref={register} id={'lang-'+index} value={language.id} /> {language.language}</label>
                                    </div>
                                    </div>
                                </div>
                              )
                            })}
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="control-group">
                              <div className="controls">
                                <button id="confirmsignup" name="confirmsignup" type="button" onClick={handleHideActiveBox} className="btn btn-success register-btn cancel_btn">Cancel</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 text-right">
                            <div className="control-group">
                              <div className="controls">
                                <button id="confirmsignup" name="confirmsignup" className="btn btn-success register-btn">Apply</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        </fieldset>
                     </div>
                   </li>
                  <li className="col box-height1"><a href="#">Affiliation</a></li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
{isLoading ?
<section className="search-topic">
    <div className="container-fluid cf">
      <div className="row text-center results_records">
         <LoadingIcon />  
      </div>
      </div>
    </section>
    :''}

  {isSearchPage() && !isLoading && <SearchListSec setIsLoading={setIsLoading} inputExclusiveChannels={inputExclusiveChannels} setInputExclusiveChannels={setInputExclusiveChannels} classes={loadedClasses} /> }
</>
  );
}

export default Search;
