import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom'
import {getLoggedFullname} from '../../../../auth/Athentication'

export default function ClassRoomTab(props) {
    const sectionId = props.section_id;
    const [classInfo,setClassInfo] = useState()

    useEffect(() => {
        getClassRoomInfo();
        }, []);

        async function getClassRoomInfo()
        {
            const res = await axios.get('zoom/get-classroom-info/'+sectionId);
            if(res.data && res.data.status=='success'){
                console.log(res.data.data)
                if(res.data.data){
                   setClassInfo(res.data.data); 
                }
            }
        } 
    return (
        <>
        <div className="row">
          <div className="col-md-12">
            <p>Hi, <span>{getLoggedFullname()}</span></p>
            <p>This page is for class announcements and discussions. Please refresh the page to see the latest attendance information.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="meet_box">
                { !classInfo || !classInfo.zoom_meeting_id ?  <p>No meeting found.</p> :
                    <Link target="_blank" to={"/classroom-zoom-meting-start/"+classInfo.zoom_meeting_id+'/'+classInfo.id} className="get_button">Start Live Meeting</Link>
                }
              <p><a href="#">Watch now: Tips for teaching live online classes...</a></p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="post_box">
              <h2>Add new post</h2>
              <form>
                <div className="col-md-12">
                  <label className="label_info" htmlFor="teacher">Only teachers can add new posts. <a href>Copy existing post...</a></label> <br />
                  <div className="controls">
                    <textarea name="topics" className="form-control text-area" placeholder="Type your message here.." defaultValue={""} />
                  </div>
                  <div className="controls">
                    <button href="age-selection.html" className="btn btn-success register-btn">Send</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="controls meeting_div">
              <span>Showing:</span>
              <select name="name" id="mname" className="form-control">
                <option value="published">Published</option>
                <option value="unpublished">Unpublished</option>
              </select>
            </div>
          </div> 
        </div>
        <div className="row">
          <div className="col-md-12 classroom_p">
            <p>Wed Aug 5 at 9am - 9:55am IST </p>
            <p><strong>Scheduled meeting did not happen</strong></p>
            <p>No one joined.</p>
          </div> 
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="post_box">
              <div className="row">
                <div className="col-md-8">
                  <div className="tb-row">
                    <div className="tb-cell">
                      <img src="/assets/img/smile.png" />
                    </div>
                    <div className="tb-cell text-left pd-left">
                      <p>Wed Aug 5 at 9am - 9:55am IST</p>
                      <p><a href="#teacher"><strong>Mrs. Frenzel</strong></a></p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-right">
                  <button href="age-selection.html" className="btn btn-success register-btn">Edit Post</button>
                </div>
              </div>
              <div className="col-md-12">
                <p>Welcome! I'm excited that you joined this class. Would you please introduce yourself to me and the other learners? And please share any background or questions you have in this topic area.</p>
              </div>
              <hr />
              <form>
                <div className="col-md-12">
                  <div className="controls">
                    <textarea name="topics" className="form-control text-area" placeholder="Write a comment" defaultValue={""} />
                  </div>
                  <div className="controls">
                    <button href="age-selection.html" className="btn btn-success register-btn">Send</button>
                    <button href="age-selection.html" className="btn btn-success register-btn meet_btn1">Cancel</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        </>
    )
}