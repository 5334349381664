import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ClassSummaryUpcommingSection from './ClassSummaryUpcommingSection';
import '../../../../css/class-summary.css'
import {getCleanStatus,GetStringForUrl} from '../../../helpers/StringHelper';

export default function ClassSummaryTab(props) {
  const classTitle = props.title;
  const [classInfo,setclassInfo] = useState(props.classInfo);
  const classID = props.class_id;
  const reqType = props.reqType; 
  const [successMsg,setSuccessMsg] = useState({})
  const [upcomingSections, setUpcomingSections] = useState({});
  const [pastSections, setPastSections] = useState({});

  useEffect(() => {
    // getClassDetails();
    getUpcomingSections();
    getPastSections();

  }, []);

    async function getUpcomingSections()
    {
      axios.get('sections/upcoming-sections',{params:{class_id:classID}}).then(res=>{
        setUpcomingSections(res.data.data);
      });
    }
    
    async function getPastSections()
    {
      axios.get('sections/upcoming-sections',{params:{class_id:classID,'section_type':'past'}}).then(res=>{
        setPastSections(res.data.data);
      });
    }
    // async function getClassDetails()
    //   {
    //     const classInfo = await axios.get("get-class_deatails/"+classID);
    //     if(typeof(classInfo.data.status) !='undefined' && classInfo.data.status == 'success'){
    //        var response = classInfo.data.data;
    //        setclassInfo(response);
    //     }else{
    //         alert('Internal Server Error...')
    //     }
    //   }

      //Archive/unArchive Class
      async function updateArchiveStatus(setTo) 
      {
        const classInfo = await axios.post("update-class-archive",{class_id:classID,status:setTo})
        .then(response => {
          if(response.data.status=='success'){
            setclassInfo(classInfo => ({...classInfo, is_archived:setTo}));
            setSuccessMsg({'class':'success',message:response.data.message}); 
            }else{
              setSuccessMsg({'class':'danger',message:response.data.message});
          }
          setTimeout(()=>setSuccessMsg({}),5000);
        })
        .catch(error => {
          alert('Internal server Error');
          window.location.reload();
        });

        }

        async function updateClassStatus(setTo)
        { 
        const classInfo = await axios.post("update-class-status",{class_id:classID,status:setTo})
        .then(response => {
          if(response.data.status=='success'){
            setclassInfo(classInfo => ({...classInfo, status:setTo}));
            setSuccessMsg({'class':'success',message:response.data.message}); 
            }else{
              setSuccessMsg({'class':'danger',message:response.data.message});
          }
          setTimeout(()=>setSuccessMsg({}),5000);
        })
        .catch(error => {
          alert('Internal server Error');
          window.location.reload();
        });


        }
 
    return (
      classInfo.length <= 0 ?'' :         
      <div role="tabpanel" id="two">
        <div className="row">
          <div className="col-md-12">
            {typeof(successMsg.message) !='undefined' ? <div className={"alert alert-"+successMsg.class}>{successMsg.message}</div>:''}
            <div className="summery-div">
              <div className="row">
                <div className="col-md-12">
                  <h2></h2>
                </div>
              </div>
              <div className="info_box">
                <div className="row">
                  <div className="col-md-5">
                    <p>{getCleanStatus(classInfo.status) }</p>
                  </div>
                  <div className="col-md-7 text-right">
                    {classInfo.is_archived && classInfo.is_archived =='yes' ?( 
                    <button id="unarchive-btn" name="unarchive" onClick={()=>updateArchiveStatus('no')} className="btn btn-success register-btn summary_btn">UArchive</button>):(
                      <>
                      <button id="archive-btn" name="archive" onClick={()=>updateArchiveStatus('yes')} className="btn btn-success register-btn summary_btn">Archive</button>
                      {classInfo.status == 'draft'?
                      <button id="finishdraft-btn" onClick={()=>updateClassStatus('pending')} name="confirmsignup" className="btn btn-success register-btn summary_btn">Finish Draft</button>
                      :<button id="draft-btn" onClick={()=>updateClassStatus('draft')} name="confirmsignup" className="btn btn-success register-btn summary_btn">Save as Draft</button>} </> )}
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <p>You can publish classes after you are approved to teach. <a href="#">Learn more</a></p>
                  <p><Link to={"/class-details/"+GetStringForUrl(classInfo.title?classInfo.title:'')+'/'+classInfo.class_uuid} className="view-list"><i className="bx bx-image-alt" /> View class listing</Link></p>
                </div>
                <div className="col-md-12">
                  <h2>Parent Interest</h2>
                  <p className="parent_p"><i className="bx bxs-heart red_clr" /> <span>0 saves</span></p>
                  <p className="parent_p">When you list a section for this class, we will notify each parent who saved it.</p>
                </div>
                <div className="col-md-12">
                  <h2>Upcoming Sections</h2>
                  {Object.keys(upcomingSections).length ? 
                    Object.keys(upcomingSections).map((index)=>{
                    return <ClassSummaryUpcommingSection classInfo={classInfo} type='upcoming' key={index} section={upcomingSections[index]} />
                  }):<p>No sections created yet.</p>}
                  <br />
                  <p className="parent_p">Add a section for each time that you will offer the class. Learners will enroll in a specific section.</p>
                  <p className="parent_p">Need some help picking a time? <a href>Click more</a> for guidance on when to schedule.</p>
                </div>
                <div className="col-md-4">
                  <a href={"/section/"+classInfo.class_uuid} id="confirmsignup" name="confirmsignup" className="btn btn-success register-btn summary_btn1">Add a Section</a>
                </div>
                <div className="clearfix" />
                <div className="col-md-12">
                  <br clear="all" />
                  <h2>Past Sections</h2>
                  {Object.keys(pastSections).length ? 
                    Object.keys(pastSections).map((index)=>{
                    return <ClassSummaryUpcommingSection type='past' key={index} classInfo={classInfo} section={pastSections[index]} />
                  }) : <p className="parent_p">None yet.</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
