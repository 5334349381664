import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import addDays from 'date-fns/addDays'
import "react-datepicker/dist/react-datepicker.css";
import { HiddenCropper } from "react-bootstrap-image-cropper";
import { useForm,Controller,useFieldArray,useWatch } from "react-hook-form";
import {getStatusLableClass,getCleanStatus} from '../../../helpers/StringHelper';
import '../../../../css/class-form.css'

export default function ClassFormTab(props) {
    const classTitle = props.title;
    const classID = props.class_id;
    const reqType = props.reqType;
    const initialInputs = {
        title:classTitle,
        channel_type:'public',
        description:'',
        short_description:'',
        age_range_start:'3',
        age_range_end:'5',
        min_guests:'3',
        max_guests:'4',
        is_admin_approved:'no',
        class_duration_minutes:15,
        learning_objective:'',
        assignments:'',
        time_commitments:'',
        certificate_program:'',
        supply_required:'',
        enroll_welcome_post:'',
        social_share_description:'',
        class_length_id:'',
        class_format_id:'',
        channel_id:'',
        call_language_id:'',
        calls_in_week:1,
        call_pricing_type_id:'1',
        price:'',
        class_valid_from:'',
        class_valid_to:'',
        discount:'',
        no_of_weeks:1,
        submit_type:'',
        status:'',
        coupon_amount:'',
        coupon_code:'',
    }
    const initialDisables = {
        title:false,
        channel_type:false,
        channel_id:false,
        call_language_id:false,
        class_length_id:false,
        class_duration_minutes:false,
        calls_in_week:false,
        no_of_weeks:false,
    }
    const EditFormDisabledInuts = {
        title:false,
        channel_type:false,
        channel_id:false,
        call_language_id:false,
        class_length_id:true,
        class_duration_minutes:false,
        calls_in_week:true,
        no_of_weeks:true,
    }

    const defaultCouponFields = {
        defaultValues: {
          couponFields: [{ coupon_code: "", coupon_amount: ""}]
        }
      };
    const [inputs, setInputs] = useState(initialInputs);   
    const [classLengths, setClassLengths] = useState([]);   
    const [classFormats, setClassFormats] = useState([]);   
    const [classLanguages, setClassLanguages] = useState([]);   
    const [couponsData, setCouponsData] = useState([]);   
    const [categories, setCategories] = useState([]);   
    const [classValidTo, setClassValidTo] = useState(new Date('9999-12-31'));   
    const triggerRef = useRef();    
    const triggerRef1 = useRef();    
    const [classPhotoSrc, setClassPhotoSrc] = useState(null);    
    const [maxCouponsLimit, setMaxCouponsLimit] = useState(3);    
    const [classCertificateSrc, setClassCertificateSrc] = useState(null);    
    const [classPhotoError, setClassPhotoError] = useState(false);   
    const [isClassVideoSelected, setIsClassVideoSelected] = useState(null);   
    const [isClassAttachmentSelected, setIsClassAttachmentSelected] = useState(null);   
    const [isSubmitted, setIsSubmitted] = useState(false);   
    const [successMessage, setSuccessMessage] = useState(null);   
    const [formErrors, setFormErrors] = useState(false);      
    const [isSubmitting, setIsSubmitting] = useState(false);      
    const { register,reset,handleSubmit,control,clearErrors,getValues,focusErrorField,setError,errors } = useForm(defaultCouponFields);
    const [disabledInputs, setDiabledInputs] = useState(initialDisables);   
    const [classLengthStr, setClassLengthStr] = useState('-');   
    const [estimatedEarning, setEstimatedEarning] = useState(null);   
    const [selectedClassFormat, setSelectedClassFormat] = useState({min_guests:null,max_guests:null});   
    const formdata = new FormData();   //formdata object
    const [fileClassPhoto,setFileClassPhoto] = useState({})
    const [fileClassCertificate,setFileClassCertificate] = useState({})
    const [fileClassVideo,setFileClassVideo] = useState({})
    const [fileClassAttachment,setFileClassAttachment] = useState({})
    const { fields,trigger, append, remove, insert } = useFieldArray({
        control,
        name: "couponFields"
      });
    useEffect(() => {
        
        getClassFormats();
        getClassLengths();
        getClassLanguages();
        if(reqType =='edit'){
           getClassDetails(classID)
           calculateEarnings();
        }else{
            getCategories('public');
        }
      }, []);

      useEffect(() => {
            calculateEarnings();
      }, [estimatedEarning]);

      async function getClassDetails()
      {
        const classInfo = await axios.get("teacher/get-class_deatails/"+classID);
        if(typeof(classInfo.data.status) !='undefined' && classInfo.data.status == 'success'){
            console.log(classInfo.data.data);
           var response = classInfo.data.data;
            setInputs(response);
            if(response.coupons_data.length){
               setCouponsData(response.coupons_data);
            }
            getCategories(response.channel_type);
            if(response.class_valid_to){
                setClassValidTo(new Date(response.class_valid_to))
            }
           setClassPhotoSrc(response.classPhotoSrc);
           setClassCertificateSrc(response.classCertificateSrc);
           if(response.status == 'active' || response.is_admin_approved == 'yes'){
                setDiabledInputs(EditFormDisabledInuts)
           }
           if(response.class_length_id == '1'){
            setInputs(inputs => ({...inputs, calls_in_week:1,no_of_weeks:1} ) );
            setDiabledInputs(disabledInputs => ({...disabledInputs, calls_in_week:true,no_of_weeks:true} ) );
        }else{
            if(response.class_format.min_guests == response.class_format.max_guests){
                setInputs(inputs => ({...inputs, min_guests:response.class_format.max_guests,min_guests:response.class_format.max_guests} ) );
                setDiabledInputs(disabledInputs => ({...disabledInputs, min_guests:true,max_guests:true} ) );
               }
           }

        }else{
            alert('Internal Server Error...')
        }
      }
      async function getCategories(type) {
        const dbChannels = await axios.get("get-channels/"+type);
        setCategories(dbChannels.data.data);
      }
      async function getClassLengths() {
        const dbClassLengths = await axios.get("get-class_lengths");
        setClassLengths(dbClassLengths.data.data);
      }
      async function getClassFormats() {
        const dbClassFormats = await axios.get("get-class_formats");
        setClassFormats(dbClassFormats.data.data);
      }
      async function getClassLanguages() {
        const dbClassLanguages = await axios.get("get-class_languages");
        setClassLanguages(dbClassLanguages.data.data);
      }

     //for form inputs 
     const handleInputChange = async (event) => {
         event.persist();
         const input = event.target;
            setInputs(inputs => ({...inputs, [input.name]: input.value}));
        
        if(input.name=='age_range_start' && input.value == 99){
            setInputs(inputs => ({...inputs, age_range_end:99} ) );
        }else if(input.name == 'channel_type') { //for Public/exclusive channels
            getCategories(input.value);
        }else if(input.name == 'class_length_id') { //for Disable Class frequncies
            if(input.value == '1'){ //1= one time course.
                setInputs(inputs => ({...inputs, calls_in_week:1,no_of_weeks:1} ) );
                setDiabledInputs(disabledInputs => ({...disabledInputs, calls_in_week:true,no_of_weeks:true} ) );
            }else{
                setDiabledInputs(disabledInputs => ({...disabledInputs, calls_in_week:false,no_of_weeks:false} ) );
            }
                var labelText = event.target.getAttribute('data-labeltxt');
                setClassLengthStr(labelText);

        }else if(input.name == 'class_format_id') {
            const inputFormat = classFormats.filter(function(format){ 
                return format.id == input.value;
            });
            const c_min_guests = inputFormat[0].min_guests;
            const c_max_guests = inputFormat[0].max_guests;
            setSelectedClassFormat({min_guests:c_min_guests,max_guests:c_max_guests});
            // console.log(c_min_guests+'--'+c_max_guests)
            if(c_min_guests && c_max_guests && parseInt(c_min_guests) === parseInt(c_max_guests) ) {
                setInputs(inputs => ({...inputs, min_guests:c_min_guests,max_guests:c_max_guests} ) );
                setDiabledInputs(disabledInputs => ({...disabledInputs, min_guests:true,max_guests:true} ) );
            }else{
                setDiabledInputs(disabledInputs => ({...disabledInputs, min_guests:false,max_guests:false} ) );
            }
        }
        setTimeout(calculateEarnings(),5000);

    }

    const handleRemoveRecord = (e,index) =>{
        e.preventDefault();
        if(fields.length<2){
          return false;
        }
           remove(index);
      }
    const validateCouponDiscount = (event) =>{
        event.persist();
         const input = event.target;
            if(input.value > 100){
                input.value = input.value.slice(0, -1)
            }
    }
    const validateCouponCode = (event) =>{
        event.persist();
        const input = event.target;
        input.value = input.value.trim();
    }

    function calculateEarnings() 
    {
        var p_type = inputs.call_pricing_type_id;
        var price = inputs.price?inputs.price:0;
        var disc = inputs.discount?inputs.discount:0;
        var minGuests = inputs.min_guests;
        var maxGuests = inputs.max_guests;
        var calls_in_week = inputs.calls_in_week;
        var calLen = inputs.class_length_id;
        if(p_type !='2'){
            var e_min_earn = parseInt(price)*parseInt(minGuests); 
            var e_max_earn = parseInt(price)*parseInt(maxGuests);
            if(disc){
                e_min_earn = e_min_earn - e_min_earn * (parseInt(disc) /100);
                e_max_earn = e_max_earn - e_max_earn * (parseInt(disc) /100);
            }
            e_min_earn = e_min_earn - (e_min_earn * (30 /100));
            e_max_earn = e_max_earn - (e_max_earn * (30 /100));
            var f_earning = '$'+ parseInt(e_min_earn)+' - '+parseInt(e_max_earn);
            setEstimatedEarning(f_earning);
        }
    } 

    const handlePricings = (event) => {
        var input = event.target;
        setInputs(inputs => ({...inputs, call_pricing_type_id:input.value} ) );
        calculateEarnings();
    }

    //class video
    const handleOnChangeVideo = (event) => {
        var inputName = event.target.name;
        const file = event.target.files[0];
        if(!file){
            return;
        }
        const fileSize = Math.round((file.size / 1024)); 
        if(inputName == 'class_video'){
            if (fileSize >= 5096) { 
                setError("class_video",{type:"manual",message:"File too large, please select less then 5MB."});
            }else{
                clearErrors(['class_video']);
                    setFileClassVideo({file:file,name:file.name});
                    setIsClassVideoSelected("Selected Video: "+file.name)
                }
            }else if(inputName == 'welcome_post_attachment'){
                if (fileSize >= 2096) {
                    setError("welcome_post_attachment",{type:"manual",message:"File too large, please select less then 2MB."});
                }else{
                    clearErrors(['welcome_post_attachment']);
                        setFileClassAttachment({file:file,name:file.name});  
                    setIsClassAttachmentSelected("Selected Video: "+file.name)
            }
        }

    }

      //class Image  
      function handleCropped(file,field) {
          if(field == 'class_photo'){
              setClassPhotoSrc(URL.createObjectURL(file));  
              setFileClassPhoto({file:file,name:'class_photo.png'});
            }else{
                setClassCertificateSrc(URL.createObjectURL(file));  
                setFileClassCertificate({file:file,name:'certificate.png'});  

          }
      }
     
    var ageStart=[],ageEnd=[],minGuests=[],maxGuests=[],class_durations=[],sec_meetings=[],input_weeks = [];
    for (var i = 15; i < 185; i=i+5) {
        class_durations.push(<option value={i} key={i}>{i}</option>);
    }
    for (var i = 1; i < 31; i++) {
        if(i>2 && i < 20){
            ageStart.push(<option value={i} key={i}>{i}</option>);
            ageEnd.push(<option value={i} key={i}>{i}</option>);
        }
        if(i<21){
            input_weeks.push(<option value={i} key={i}>{i}</option>);
            sec_meetings.push(<option value={i} key={i}>{i}</option>);
        }
        minGuests.push(<option value={i} key={i}>{i}</option>);
        maxGuests.push(<option value={i} key={i}>{i}</option>);
    }

    //delete coupon 

    const handleDeleteCoupon = (event,code) =>{
        event.preventDefault();
        axios.post('teacher/class/delete-coupon',{coupon_code:code,call_id:classID}).then((res)=>{
            console.log(res.data);
            if(res.data.status == 'success'){
                let filteredCoupons = couponsData.filter(item =>item.coupon_code !== code);
                setCouponsData(filteredCoupons)
            }
        })

    }

//submission
const formSubmit = (data)=>{
    setIsSubmitting(true);
for ( var key in inputs ) {
    formdata.append(key, inputs[key]);
}
data.couponFields && Object.keys(data.couponFields).length && data.couponFields.map((field,index)=>{
    if(field.coupon_code){
        formdata.append('coupon_code[]',field.coupon_code);
        formdata.append('coupon_amount[]',field.coupon_amount);
    }
});

formdata.append('form_action_for',reqType);

if(typeof(fileClassPhoto.file) != 'undefined'){
    formdata.append("class_photo",fileClassPhoto.file,fileClassPhoto.name);
}
if(typeof(fileClassCertificate.file) != 'undefined'){
    formdata.append("class_certificate",fileClassCertificate.file,fileClassCertificate.name);
}
if(typeof(fileClassVideo.file) !='undefined'){ 
    formdata.append("class_video",fileClassVideo.file,fileClassVideo.name);
}
if(typeof(fileClassAttachment.file) !='undefined'){
    formdata.append("welcome_attachment",fileClassAttachment.file,fileClassAttachment.name);
}

//age validation
    const ageStart = parseInt(inputs.age_range_start);
    const ageEnd = parseInt(inputs.age_range_end);
    const inputMinGuests = parseInt(inputs.min_guests);
    const inputMaxGuests = parseInt(inputs.max_guests);
    let has_error = null;

    if(ageStart > ageEnd){
        has_error = "Please select valid Ages.";
        setError("age_range_end",{type:"manual",message:has_error});
    }else if(ageStart < 12 && (ageEnd - ageStart) > 4){  
        has_error = "Age range can not exceed more than 5yrs.";      
        setError("age_range_end",{type:"manual",message:has_error});
    }else if(inputMinGuests > inputMaxGuests) { //Guests validations
        has_error = "Please select Valid guest ranges.";
        setError("max_guests",{type:"manual",message:has_error});
    }else if(selectedClassFormat.min_guests && parseInt(selectedClassFormat.min_guests) > parseInt(inputs.min_guests) ) {
        has_error = 'We recommend class sizes graater than : '+selectedClassFormat.min_guests;
        setError('max_guests',{type:'manual',message:has_error});
    }else if(selectedClassFormat.max_guests && parseInt(selectedClassFormat.max_guests) < parseInt(inputs.max_guests) ){
        has_error = 'We recommend class sizes smaller than : '+selectedClassFormat.max_guests;
        setError('max_guests',{type:'manual',message:has_error});
    }else if(!classPhotoSrc){
        setClassPhotoError(true);
        has_error = 'Please upload photo';
    }else if(inputs.call_pricing_type_id !='3'){
        if(!parseInt(inputs.price) || parseInt(inputs.price)<=0){
            has_error = 'Please enter valid price.';
            setError('price',{type:'manual',message:has_error});
        }
    }else if(inputs.call_pricing_type_id =='2' ){
        if(!parseInt(inputs.discount) || parseInt(inputs.discount)<=0){
            has_error = 'Please enter Discount percentage.';
            setError('discount',{type:'manual',message:has_error});
        }
    }else if(inputs.coupon_amount !='' || inputs.coupon_code !=''){
        if(inputs.coupon_amount ==''){
            has_error = 'Please enter Coupon amount.';
            setError('coupon_amount',{type:'manual',message:has_error});
        }else if(inputs.coupon_code ==''){
            has_error = 'Please enter Coupon code.';
            setError('coupon_code',{type:'manual',message:has_error});
        }
        if(inputs.coupon_amount > 100 && inputs.coupon_amount_type =='percentage'){
            has_error = 'Please enter valid coupon percentage';
            setError('coupon_amount',{type:'manual',message:has_error});
        }
    }  

    if(classValidTo.getTime() < new Date().getTime()){
        has_error = 'Class valid date should be greter than today';
        setError('classValidTo',{type:'manual',message:has_error});
    }else{
        clearErrors(['classValidTo']);
    }

    if(has_error !== null) {
        window.scrollTo(0, 0)
        // focusErrorField();
        setFormErrors(has_error);
        setIsSubmitting(false);
        return false;
    }
    
// axios.post("teacher/add-class", formdata,{headers: {'content-type': 'multipart/form-data'}})
var post_url = reqType == 'edit'?'teacher/update-class/'+classID:'teacher/add-class';
axios.post(post_url, formdata)
    .then(response => {
        let res = response.data;
        if(res.data){
            window.scrollTo(0, 0)
            localStorage.setItem('teacherTabPage','Classes');
            setSuccessMessage(res.data.message);
            setIsSubmitted(true);
        }else{
            let msg_str ='';
            // console.log(res.message.max_guests[0]);
            Object.keys(res.message).map((ele)=>{
                res.message[ele].map((msg,key) => {
                    console.log(msg)
                    msg_str += msg+'\n';
                    setIsSubmitting(false);
                    setFormErrors(msg_str);
                    setError(ele,{type:"manual",message:msg});
                    window.scrollTo(0, 0)
                    // setError(ele,"serverError",msg);
                });

            });
            
        }     
    })
    .catch(error => {
        setIsSubmitting(false);
        console.log(error);
        // console.log(error.status);
        // setFormErrors(errors.data.data.message);
            alert('Internal server error');
    });
}

    return (
        isSubmitted ? <Redirect 
        to={{pathname:"/teacher-dashboard",state:{message:(successMessage?successMessage:'Class saved Successfully')}}} /> :
        <div role="tabpanel" id="one">
        <div className="row">
        <div className="col-md-12">
            {reqType == 'edit' && inputs.is_archived =='yes' ?<h4><span className="badge badge-dark">Archived</span></h4>: 
            (
                <h4>
                    <span className={'badge badge-'+getStatusLableClass(inputs.status)}>{getCleanStatus(inputs.status)}</span>    
                </h4>
            )}
            <div className="editclass">
            <Form className="form-horizontal teacher_form" onSubmit={handleSubmit(formSubmit)}>
                <fieldset>
                {/* Sign Up Form */}
                <div className="step-div">
                    { formErrors && <div className="alert alert-danger">{formErrors}</div> }
                    <div className="row">
                        <div className="col-md-6">
                            <div className="control-group">
                                <div className="row">
                                <div className="col-md-6">
                                    <div className="controls">
                                    <label className="radio inline label_name cursor-pointer">
                                    <input type="radio" disabled={disabledInputs.channel_type == true} checked={ inputs.channel_type=='public'}  
                                        ref={register({
                                        required: 'This field is required'})} onChange={handleInputChange} value="public" name="channel_type" /> Public
                                    </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="controls">
                                    <label className="radio inline label_name cursor-pointer">
                                    <input type="radio" disabled={disabledInputs.channel_type == true} checked={ inputs.channel_type=='exclusive'} ref={register({
                                        required: 'This field is required',})}  className="form-check-input" onChange={handleInputChange} value="exclusive" name="channel_type" /> Exclusive
                                    </label>
                                    </div>
                                </div>
                                {errors.channel_type && <p className="text-danger">{errors.channel_type.message}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 text-right">
                            <div className="controls">
                                <button href="#" className="btn btn-success register-btn upload_video">Tips for a great listing</button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="step-div" />
                <h3>Title</h3>
                {/* Text input*/}
                <div className="control-group">
                    <div className="row">
                    <div className="col-md-12">
                        <label className="label_info" htmlFor="teacher">Choose a catchy and descriptive title. Use title case (eg. "Introduction to Extrasolar Planets"), no more than one exclamation mark, and no emojis. </label><br clear="all" />
                    </div>
                    <div className="col-md-12">
                        <div className="controls">
                        <input id="title" name="title" ref={register({required: 'This field is required', maxLength: {value:255,message:'This field maximum length is 255'} })}  className="form-control input-large" type="text" onChange={handleInputChange} defaultValue={inputs.title} />
                        {errors.title && <p className="text-danger">{errors.title.message}</p>}
                        </div>
                    </div>
                    </div>
                </div>
                <h3>Summary</h3>
                {/* Text input*/}
                <div className="control-group">
                    <div className="row">
                    <div className="col-md-12">
                        <label className="label_info" htmlFor="teacher">Add a single sentence or phrase to describe your class. The summary should be clear and descriptive rather than a teaser, and include keywords so parents can find your class easily. Use no more than one exclamation mark, and no emojis. </label><br clear="all" />
                    </div>
                    <div className="col-md-12">
                        <div className="controls">
                        <input id="short_description" name="short_description" defaultValue={inputs.short_description} ref={register({required: 'This field is required', maxLength: {value:255, message:'This field maximum length is 255'} })} className="form-control input-large" type="text" placeholder="e.g., In this 8-week online course, students will write creative fiction essays about themselves."  onChange={handleInputChange} />
                        {errors.short_description && <p className="text-danger">{errors.short_description.message}</p>}
                        </div>
                    </div>
                    </div>
                </div>
                <h3>Category</h3>
                {/* Text input*/}
                <div className="control-group">
                    <div className="row">
                    <div className="col-md-12">
                        <label className="label_info" htmlFor="teacher">Choose a single category that best represents the course.</label><br clear="all" />
                    </div>
                    <div className="col-md-12">
                        <div className="controls">
                        <div className="selectdiv">
                            <select name="channel_id" disabled={disabledInputs.channel_id} ref={register({required: 'This field is required', })} value={inputs.channel_id} id="channel-id" className="form-control" onChange={handleInputChange}>
                            <option value="">Category...</option>
                            {categories &&
                                categories.map((category) => {
                                return  <option value={category.id} key={category.id}>{category.channel_name}</option>
                                    })  }
                            </select>
                                {errors.channel_id && <p className="text-danger">{errors.channel_id.message}</p>}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <h3>Call Length</h3>
                {/* Text input*/}
                <div className="control-group">
                    <div className="row">
                    <div className="col-md-12">
                        <label className="label_info" htmlFor="teacher">You cannot change this setting once the course is listed. </label><br clear="all" />
                        <label className="label_info" htmlFor="teacher">Because you are new to TunedIn, you will not be able to request a flexible schedule class listing until after you have completed your first live class.</label><br clear="all" />
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                        {classLengths &&
                                classLengths.map((length) => {
                                return(
                                    <div className="col-md-6" key={length.id}>
                                        <input id={'c_lenght'+length.id} checked={inputs.class_length_id==length.id} disabled={disabledInputs.class_length_id} onChange={handleInputChange} data-labeltxt={length.length_title} ref={register({required: 'This field is required' })} type="radio" name="class_length_id" value={length.id} className="hidden-input-file" />
                                        <label htmlFor={'c_lenght'+length.id} className="course-type">
                                            <h3>{length.length_title}</h3>
                                            <p>{length.description}</p>
                                        </label>
                                    </div>
                                )
                            })  } 
                            {errors.class_length_id && <p className="text-danger">{errors.class_length_id.message}</p>}                   
                        </div>
                    </div>
                    </div>
                </div>
                <hr />
                <div className="step-div">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Image and Video</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="img-video-div">
                                <img id="input-class-photo" src={ classPhotoSrc ? classPhotoSrc : require('../../../../images/class_placeholder.png') } />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <p><strong>Cover Image</strong></p>
                            <p>Choose an image that is engaging, free of words or text, age-appropriate and at least 600 pixels wide. Make sure you have permission to use your image (no copyrighted images, please).</p>
                            <div className="controls text-center1">
                                <p id="input-img-error" className="text-success">{fileClassPhoto.name?'Image selected successfully.':''}</p>
                                <button onClick={() => triggerRef.current.trigger()} type="button" className="btn btn-success register-btn upload_video">Add an Image</button>
                                {classPhotoError && <p className="text-danger">Please upload photo.</p>}
                            </div>
                            <hr />
                            <p><strong>Course Description Video (optional)</strong></p>
                            <p>Show and tell learners what to expect from class. Recommended length: 30-90 seconds.</p>
                            <br />
                            <div className="controls text-center1">
                                <label htmlFor="input-class-video" className="btn btn-success register-btn upload_video">{inputs.class_video ?'Add new video':'Add a Video'} </label>
                                <input type="file" className="hidden-input-file"  ref={register} name="class_video" id="input-class-video" accept="video/*" onChange={handleOnChangeVideo} />
                            {isClassVideoSelected && <p className="text-success">{isClassVideoSelected}</p>}
                            {errors.class_video && <p className="text-danger">{errors.class_video.message}</p>}
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="step-div">
                    <div className="row">
                    <div className="col-md-12">
                        <h2>Call Format</h2>
                    </div>
                    </div>
                    <div className="row">
                    {classFormats &&
                                classFormats.map((format) => {
                                return ( <div className="col" key={format.id}>
                                <div className="control-group">
                                    <div className="controls">
                                        <label className="radio inline cursor-pointer" htmlFor={'format'+format.id}>
                                        <input type="radio" ref={register({ required: 'This field is required'})} checked={inputs.class_format_id==format.id} className="search_input" name="class_format_id" value={format.id} id={'format'+format.id} onChange={handleInputChange} />&nbsp; {format.format_name} </label>
                                    </div>
                                </div>
                                </div>
                                )})  }
                
                    </div>
                    {errors.class_format_id && <p className="text-danger">{errors.class_format_id.message}</p>}
                </div>
                <hr />
                <div className="step-div">
                    <div className="row">
                    <div className="col-md-12">
                        <p><strong>Class validity</strong></p>
                        <label className="label_info" htmlFor="teacher">Valid from {inputs.class_valid_from ? '('+inputs.class_valid_from+')' :'date get set when the class has been approved'}.</label><br clear="all" />
                    </div>
                    <div className="col-md-6">
                        <p><strong>Valid to date</strong></p>
                        <div className="controls">
                            <DatePicker
                                className="form-control"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormat="MM/dd/yyyy"
                                todayButton="Go to Today"
                                selected={classValidTo}
                                onChange={date => setClassValidTo(date)}
                                minDate={addDays(new Date(),0)}
                                placeholderText="Class valid till"
                                autoComplete='off'
                              />
                        </div>
                        {errors.classValidTo && <p className="text-danger">{errors.classValidTo.message}</p>}
                    </div>
                    </div>
                    <br />

                    <div className="row">
                    <div className="col-md-12">
                        <p><strong>Age Range</strong></p>
                        <label className="label_info" htmlFor="teacher">What age range is the class designed for? Age ranges cannot exceed 5 years. We’ve found that students tend to thrive in classes with other kids who match their own developmental stage, so we recommend narrow age ranges (between 3-4 years) for most classes.</label><br clear="all" />
                    </div>
                    <div className="col-md-12">
                        <div className="controls age_div">
                        <select value={inputs.age_range_start} name="age_range_start"  ref={register({
                                required: 'This field is required',
                                validate: {
                                    abc: (value) => {
                                        const { age_range_end } = getValues();
                                        return parseInt(age_range_end) >= parseInt(value) || 'Please select valid age ranges.';
                                    },
                                    xyz: (value) => {
                                        const { age_range_end } = getValues();
                                        return parseInt(age_range_end) <= parseInt(value)+5 || 'Age range should not be exceed 5yrs.';
                                    },
                                }})} className="form-control" onChange={handleInputChange} >
                            {ageStart}
                            <option value={99} >Parents</option>
                        </select>
                        <span>to</span>
                        <select value={inputs.age_range_end} name="age_range_end" ref={register({
                                required: 'This field is required',
                                validate: {
                                    minMaxValidation1: (value) => {
                                        const { age_range_start } = getValues();
                                        return parseInt(age_range_start) <= parseInt(value) || 'Please select valid age ranges.';
                                    },
                                    ageRestrictions1: (value) => {
                                        const { age_range_start } = getValues();
                                        return parseInt(age_range_start)+5 >= parseInt(value) || 'Age range should not be exceed 5yrs.';
                                    },
                                }})}
                                id="age_end" className="form-control" onChange={handleInputChange} >
                            {ageEnd}
                            <option value={99}>Parents</option>
                        </select>
                        <span>years old</span>
                        </div>
                        {errors.age_range_end && <p className="text-danger">{errors.age_range_end.message}</p>}
                        {errors.abc && <p className="text-danger">{errors.abc.message}</p>}
                        {errors.xyz && <p className="text-danger">{errors.xyz.message}</p>}
                        {errors.minMaxValidation1 && <p className="text-danger">{errors.minMaxValidation1.message}</p>}
                        {errors.ageRestrictions1 && <p className="text-danger">{errors.ageRestrictions1.message}</p>}
                    </div>
                    </div>
                    <br />
                    <div className="row">
                    <div className="col-md-12">
                        <p><strong>Class Size</strong></p>
                        <label className="label_info" htmlFor="teacher">We recommend small classes in general and especially for younger students. For ages 6 and below, up to 6 learners. For ages 6-12, up to 9 learners. For ages 12 and above, up to 12 learners.</label><br clear="all" />
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12">
                        <label className="label_info" htmlFor="teacher">Because you are new to TunedIn, you will not be able to request a listing for a tutoring class with one learner until after you have completed your first live class.</label><br clear="all" />
                    </div>
                    <div className="col-md-12">
                        <div className="controls age_div">
                        <select name="min_guests"disabled={disabledInputs.min_guests} value={inputs.min_guests}  className="form-control" onChange={handleInputChange} >
                            {minGuests}
                        </select>
                        <span>to</span>
                        <select disabled={disabledInputs.max_guests} value={inputs.max_guests} name="max_guests" ref={register} className="form-control" onChange={handleInputChange} >
                            {maxGuests}
                        </select>
                        <span>learners</span>
                        </div>
                        {errors.max_guests && <p className="text-danger">{errors.max_guests.message}</p>}
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12">
                        <p><strong>Class Frequency</strong></p>
                                        <label className="label_info" htmlFor="teacher">Select how often you will meet and for how long. Course type: <strong>{classLengthStr}</strong></label><br clear="all" />
                    </div>
                    <div className="col-md-12">
                        <div className="controls age_div">
                        <span>This course will meet live</span>
                        <select disabled={disabledInputs.calls_in_week} value={inputs.calls_in_week} onChange={handleInputChange} name="calls_in_week" id="calls_in_week" className="form-control" onChange={handleInputChange} >
                            {sec_meetings}
                        </select>
                        <span>time per week,</span>
                        <select name="class_duration_minutes" value={inputs.class_duration_minutes} id="class_duration_minutes" className="form-control" onChange={handleInputChange} >
                        {class_durations}
                        </select>
                        <span>minutes per meeting, for</span>
                        <select name="no_of_weeks" id="no_of_weeks" disabled={disabledInputs.no_of_weeks} value={inputs.no_of_weeks} onChange={handleInputChange} className="form-control" >
                            {input_weeks}
                        </select>
                        <span>week</span>
                        </div>
                    </div>
                    </div>
                </div>
                <hr />
                <div className="step-div">
                    <div className="row">
                    <div className="col-md-12">
                        <h2>Description</h2>
                        <p>Descriptions that are well-written and highly detailed will help set expectations and get enrollments.</p>
                        <p><strong>Class Experience</strong></p>
                        <p>Explain additional class details that a parent might want. The longer the class, the more details you should provide. For a half hour class, a few sentences is sufficient. For a semester-long course, please include a detailed curriculum.<br />
                        Multi-week classes should specify which topics are covered each week, at least for the first 4 weeks. A class that meets 6 or more times should have a description of at least 200 words.</p>
                        <p><strong>Topics to include:</strong></p>
                        <ul>
                        <li>What will be taught?</li>
                        <li>What topics will you cover?</li>
                        <li>How is your class structured?</li>
                        <li>How will you teach?</li>
                        <li>What's your teaching style?</li>
                        <li>How much will learners get to interact with you and each other (mention specifics like: lecture, games, slides, video clips, discussion)?</li>
                        <li>Any experience or knowledge learners need?</li>
                        </ul>
                        <div className="controls">
                                <textarea name="description" ref={register({ required: 'This field is required',})} onChange={handleInputChange} className="form-control text-area" defaultValue={inputs.description} />
                        {errors.description && <p className="text-danger">{errors.description.message}</p>}
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12">
                        <label className="label_info" htmlFor="teacher">Learning objective (optional)<br />
                        </label> <br />
                        <div className="controls">
                                <textarea name="learning_objective" className="form-control text-area"  onChange={handleInputChange} value={inputs.learning_objective} />
                        {errors.learning_objective && <p className="text-danger">{errors.learning_objective.message}</p>}
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12">
                        <label className="label_info" htmlFor="teacher">Assignments (optional)<br />
                        </label> <br />
                        <div className="controls">
                                <textarea name="assignments" className="form-control text-area"  onChange={handleInputChange} value={inputs.assignments} />
                                {errors.assignments && <p className="text-danger">{errors.assignments.message}</p>}
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12">
                        <label className="label_info" htmlFor="teacher">Time commitments (optional)<br />
                        </label> <br />
                        <div className="controls">
                                <textarea name="time_commitments" className="form-control text-area"  onChange={handleInputChange} value={inputs.time_commitments} />
                                {errors.time_commitments && <p className="text-danger">{errors.time_commitments.message}</p>}
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                           <p><label className="label_info" htmlFor="teacher">Certificate program tranfer credits (optional)<br/></label></p>
                        </div>
                        <div className="col-md-3 certificate-image text-right m-auto">
                                <img className="img-thumbnail" id="input-class-photo" src={ classCertificateSrc ? classCertificateSrc : require('../../../../images/class_placeholder.png') } />
                        </div>
                        <div className="col-md-9">
                            <div className="controls certificate-input">
                                <textarea name="certificate_program" className="form-control text-area"  onChange={handleInputChange} value={inputs.certificate_program} />
                                    {errors.certificate_program && <p className="text-danger">{errors.certificate_program.message}</p>}
                                <button onClick={() => triggerRef1.current.trigger()} type="button" className="btn btn-success register-btn upload_video">Add an Image</button>
                                    <p id="input-img-error" className="text-success">{fileClassCertificate.name?'Certificate uploaded successfully.':''}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="step-div">
                    <div className="row">
                    <div className="col-md-12">
                        <label className="label_info" htmlFor="teacher">Supplies (optional)<br />List any materials learners will need to complete the class. This may include ingredients for a recipe, supplies for a project, or books/textbooks. If the supplies are not commonly found around the home, include the price. If you will supply curriculum or materials, note that here.
                        </label> <br />
                        <div className="controls">
                                <textarea name="supply_required" className="form-control text-area" placeholder="e.g., A one-page handout on first session will be provided." onChange={handleInputChange} defaultValue={inputs.supply_required} />
                        {errors.supply_required && <p className="text-danger">{errors.supply_required.message}</p>}
                        </div>
                    </div>
                    </div>
                </div>
                <div className="step-div">
                    <div className="row">
                    <div className="col-md-12">
                        <label className="label_info" htmlFor="teacher">Social share description</label> <br />
                        <div className="controls">
                                <textarea name="social_share_description" ref={register({})} className="form-control text-area" onChange={handleInputChange} defaultValue={inputs.social_share_description} />
                        {errors.social_share_description && <p className="text-danger">{errors.social_share_description.message}</p>}
                        </div>
                    </div>
                    </div>
                    <hr />
                </div>
                <div className="step-div">
                    <div className="row">
                    <div className="col-md-12">
                        <h2>Language</h2>
                    </div>
                    </div>
                    <div className="row">
                    {classLanguages &&
                                classLanguages.map((language) => {
                                return ( <div className="col-md-2" key={language.id}>
                                <div className="control-group">
                                    <div className="controls">
                                        <label className="radio inline cursor-pointer" htmlFor={'lang'+language.id}>
                                        <input type="radio" ref={register({ required: 'This field is required'})} checked={inputs.call_language_id==language.id} placeholder="Select language" className="" name="call_language_id" id={'lang'+language.id} value={language.id} onChange={handleInputChange} />&nbsp; {language.language} </label>
                                    </div>
                                </div>
                            </div>
                        )})}
                    </div>
                    {errors.call_language_id && <p className="text-danger">{errors.call_language_id.message}</p>}
                </div>
                <hr />
                <div className="step-div">
                    <div className="row">
                    <div className="col-md-12">
                        <h2>For Enrolled Learners</h2>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12">
                        <label className="label_info" htmlFor="teacher">Welcome Post<br />This message will be displayed as the first post on the classroom page, and is included in the welcome email each enrolled family receives before class. Please include anything needed for the first class in this message. Changes will only affect newly-created sections, not existing ones.
                        </label> <br />
                        <div className="controls">
                        <textarea name="enroll_welcome_post" onChange={handleInputChange} className="form-control text-area" placeholder="Welcome! I'm excited that you joined this class. Would you please introduce yourself to me and the other learners? And please share any background or questions you have in this topic area."  />
                        </div>
                        <label htmlFor="input-welcome-attach" className="btn btn-success register-btn upload_video">Add a File</label>
                        {}
                        <input type="file" className="hidden-input-file"  ref={register} name="welcome_post_attachment" id="input-welcome-attach" onChange={handleOnChangeVideo} />
                        {errors.welcome_post_attachment && <p className="text-danger">{errors.welcome_post_attachment.message}</p>}
                        {isClassAttachmentSelected && <p className="text-success">{isClassAttachmentSelected}</p>}
                    </div>
                    </div>
                    <hr />
                </div>
                <div className="step-div">
                    <div className="row">
                    <div className="col-md-12">
                        <h2>Pricing</h2>
                        <p>How much will you charge for your course?</p>

                        <div className="col-md-12 mt-2">
                        <div className="row">
                            <div className="col-md-2">
                                <label htmlFor="call_pricing_paid" className="cursor-pointer">
                                    <input type="radio" id="call_pricing_paid" name="call_pricing_type_id" checked={inputs.call_pricing_type_id == '1'} onChange={handlePricings} value="1" /> Paid
                                </label>       
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="call_pricing_free" className="cursor-pointer">
                                    <input type="radio" id="call_pricing_free" checked={inputs.call_pricing_type_id == '3'} name="call_pricing_type_id" onChange={handlePricings} value="3" /> Included at no cost
                                </label>       
                            </div>

                            <div className="col-md-2">
                                <label htmlFor="call_pricing_discount" className="cursor-pointer">
                                    <input type="radio" id="call_pricing_discount" checked={inputs.call_pricing_type_id == '2'} name="call_pricing_type_id" onChange={handlePricings} value="2" /> Offer Discount
                                </label>       
                            </div>
                        </div>
                    </div>

                    </div>
                    <div className="col-md-12" style={{display:(inputs.call_pricing_type_id =='3'?'none':'')}}>
                        <label className="label_info" htmlFor="teacher">List Price<br />This is the price parents will see per learner for the entire course. We suggest around $48 for a course that meets for an hour for 4 weeks. Pricing will vary based on class length, format and teacher.</label> <br />
                        <div className="controls age_div">
                        <span>$ </span>
                        <input name="price" type="number" value={inputs.price} id="price" onChange={handleInputChange} ref={register()} className="form-control" step="0.1"/>
                        <span>USD per student</span>
                        </div>
                        {errors.price && <p className="text-danger">{errors.price.message}</p>}
                    </div>
                    
                    <div className="col-md-12" style={{display:(inputs.call_pricing_type_id =='2'?'block':'none')}}>
                        <label className="label_info">Discount<br /></label>
                        <div className="controls age_div">
                        <span>% </span>
                            <input name="discount" type="number" onChange={handleInputChange} id="discount" ref={register()} value={inputs.discount} className="form-control" step="0.1"/>
                        </div>
                    </div>
                    {/* <div className="col-md-12" style={{display:(inputs.call_pricing_type_id =='3'?'none':'')}}>
                        <label className="label_info" htmlFor="teacher">Estimated Earnings<br />This is an estimate of your weekly earnings for this class. It’s based on the price per learner, class size, course duration, and the 30% TunedIn service fee.</label> <br />
                        { estimatedEarning && <h4>{estimatedEarning} USD per week</h4> }
                        <label className="label_info" htmlFor="teacher">Refund Policy<br />All One-Time classes have a standard refund policy: "Full refund until a week in advance, or within 24 hours of purchase (until class starts)."</label> <br />
                        <a href="#">Learn more</a>
                    </div> */}
                    {inputs.call_pricing_type_id != '3' ?
                        <div className="col-md-12 mt-3">
                            <label htmlFor="label_info"><strong>Discount Coupon</strong></label>
                            <div className="control-group">
                            {couponsData && couponsData.map((coupon,index)=>{
                                return (
                                    <p key={index}>
                                        Coupon code: <strong>{coupon.coupon_code} </strong> with <strong> {'('+coupon.amount+'%)'} </strong> discount <a href="#" className="text-danger font-weight-bold" onClick={(e)=>handleDeleteCoupon(e,coupon.coupon_code)}><i className="icofont-close" /></a> 
                                    </p>
                                )
                            })}
                            </div>
                            <div className="control-group">
                            { couponsData.length < maxCouponsLimit && fields.map((item, index) => {
                                return (
                                <div key={index} className="row">
                                    <div className="col-md-2 align-self-center text-right">
                                        <span>Coupon Code</span>
                                        </div>
                                    <div className="col-md-3">
                                        <input name="coupon_code" onChange={validateCouponCode} autoComplete={'off'} type="text" id="coupon_code"name={`couponFields[${index}].coupon_code`}  ref={register} className="form-control mr-2"/>
                                    </div>
                                    <div className="col-md-2 align-self-center text-right">
                                        <span>Discount (%)</span>
                                    </div>   
                                    <div className="col-md-3">
                                        <input name="coupon_amount" type="number" id="coupon_amount" name={`couponFields[${index}].coupon_amount`}  ref={register} onChange={validateCouponDiscount} className="form-control mr-2" max={100} step="0.1"/>
                                    </div>
                                    <div className="col-md-1 align-self-center">
                                        <button type="button" id="plus" onClick={(e) => {handleRemoveRecord(e,index)}} name="plus" className="btn btn-danger"><i className="icofont-close" /></button>
                                    </div>
                                </div>
                                    ) }) }
                                {errors.coupon_code && <p className="text-danger">{errors.coupon_code.message}</p>}
                                {errors.coupon_amount && <p className="text-danger">{errors.coupon_amount.message}</p>}
                                <div>{fields.length+couponsData.length < maxCouponsLimit ?
                                <button onClick={(e) => {e.preventDefault(); append({coupon_code:'',coupon_amount:''});}} className="btn btn-success1"><i className="icofont-plus"></i> Add</button> : ''}
                                </div>
                        </div>
                        </div>
                        :''}
                    </div>
                    <hr />
                </div>
                {reqType == 'edit' && inputs.is_archived =='yes' ?<h4><span className="badge badge-dark">Archived</span></h4>: 
                (
                <h4>
                    <span className={'badge badge-'+getStatusLableClass(inputs.status)}>{getCleanStatus(inputs.status)}</span>    
                </h4>
                 )}
                <div className="row">
                    <div className="col-md-6">
                    {/* Button */}
                    <div className="control-group">
                        {isSubmitting? <p>Form is submitting please wait...</p>:''}
                        {inputs.status!='active' &&
                            <div className="controls">
                                <input id="confirmsignup" type="submit" name="submit_type" className="btn btn-success register-btn save_btn" onClick={handleInputChange} value="Save as Draft" />
                            </div>
                        }
                    </div>
                    </div>
                    <div className="col-md-6">
                    {/* Button */}
                    <div className="control-group">
                        <div className="controls">
                        <input id="req_listing_btn" type="submit" name="submit_type" className="btn btn-success register-btn" onClick={handleInputChange}  value={inputs.status && inputs.status != 'draft'?'Update details':'Request Listing'} />
                        </div>
                    </div>
                    </div>
                </div>
                </fieldset>
            </Form>
            </div>
        </div>
        </div>
        <HiddenCropper
        triggerRef={triggerRef}
        onCropped={(cropped)=>{handleCropped(cropped,'class_photo')}}
        cropOptions={{ aspect: 4 / 4, maxZoom: 5 }}
        outputOptions={{ maxWidth: 380, maxHeight: 380 }}
        previewOptions={{ width: 380, height: 380 }}
      />
        <HiddenCropper
        triggerRef={triggerRef1}
        onCropped={(cropped)=>{handleCropped(cropped,'certificate')}}
        cropOptions={{ aspect: 4 / 4, maxZoom: 5 }}
        outputOptions={{ maxWidth: 350, maxHeight: 350 }}
        previewOptions={{ width: 350, height: 350 }}
      />
        </div>
    );
}