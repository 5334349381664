import React, { useState, useEffect, useRef } from "react";
import {checkLogin,UserRole, logout} from "../auth/Athentication";
import { NavLink,useHistory,Link } from 'react-router-dom'

function HeaderLoggedInNav(props) {
    
    const node = useRef();
    let history = useHistory();
    const userRole = UserRole();
    const [isLoggedOut, setIsLoggedOut] = useState(false);     
    const [isUserDDmenuOpen, setIsUserDDmenuOpen] = useState(false);
    const dashboardLink = userRole=='teacher'?'/teacher-dashboard':'/user-dashboard';
    const profileLink = userRole=='teacher'?'/teacher-dashboard':'/user-profile';
    const settingsLink = userRole=='teacher'?'/teacher-dashboard':'/user-settings';
    const scheduleLink = userRole=='teacher'?'/teacher-dashboard':'/user-schedule';
    const closeUserDDMenu = (e) => {
        if (node.current.contains(e.target)) {
          // inside click
          return;
        }
        // outside click
        setIsUserDDmenuOpen(false);
    };
      
    const userLogout = async () =>{
      const loggedOut = await logout();
      if(loggedOut == true){
        setIsLoggedOut(true);
      }
  }
    
    useEffect(() => {
      chekUserLogin();
    },[]);

    useEffect(() => {
        if (isUserDDmenuOpen) {
            document.addEventListener("mousedown", closeUserDDMenu);
        } else {
            document.removeEventListener("mousedown", closeUserDDMenu);
        }
        return () => {
            document.removeEventListener("mousedown", closeUserDDMenu);
        };
    }, [isUserDDmenuOpen]);

    async function chekUserLogin()
    {
      const res = await checkLogin();
      if(res!=true){
        await logout();
        history.push('/');  
      }

    }
    
  return (
    <>
      <nav ref={node} className="nav-menu desktop_menu">
        <ul className="float-left">
          <li>
          <NavLink to={dashboardLink} onClick={()=>{localStorage.setItem('teacherTabPage','Dashboard');}} activeClassName="selected">
            Dashboard
          </NavLink>
          </li>  
          <li>
            <NavLink to="/search"><img src="/assets/img/search.svg"  className="search-icon"/> Search for classes</NavLink>
          </li>
          <li>
            <Link onClick={()=>{localStorage.setItem('teacherTabPage','Schedule')}} to={scheduleLink}>Schedule</Link>
          </li>
          <li>
            <Link to="/help">Help</Link>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbardrop"
              data-toggle="dropdown"
              onClick={() =>
                setIsUserDDmenuOpen(isUserDDmenuOpen ? false : true)
              }
            >
              <img src="/assets/img/smile.png" />
            </a>
            <div
              className={
                "dropdown-menu " + (isUserDDmenuOpen == true ? "show" : "")
              }
            >
              <Link className="dropdown-item" onClick={()=>setIsUserDDmenuOpen(false)} to="/user/class-room">
                <img src="/assets/img/heart.svg" /> Clasroom
              </Link>
              <Link className="dropdown-item" onClick={()=>setIsUserDDmenuOpen(false)} to="/favourite-classes">
                <img src="/assets/img/heart.svg" /> Favorites
              </Link>
              <Link className="dropdown-item" onClick={()=>setIsUserDDmenuOpen(false)} to="/purchases">
                <img src="/assets/img/money.svg" /> Purchases
              </Link>
              <Link className="dropdown-item" onClick={()=>setIsUserDDmenuOpen(false)} to="/teach">
                <img src="/assets/img/team.svg" /> Partner
              </Link>
              <Link className="dropdown-item" onClick={()=>setIsUserDDmenuOpen(false)}  to="/teach">
                <img src="/assets/img/planner.svg" /> Volunteers
              </Link>
              <Link className="dropdown-item" onClick={()=>setIsUserDDmenuOpen(false)} to="/friends-group">
                <img src="/assets/img/group-of-men.svg" /> Cohort
              </Link>
              <Link onClick={()=>{localStorage.setItem('teacherTabPage','Profile'); setIsUserDDmenuOpen(false)}} className="dropdown-item" to={profileLink}>
                <img src="/assets/img/user.svg" /> Profile
              </Link>
              <Link onClick={()=>{localStorage.setItem('teacherTabPage','Settings'); setIsUserDDmenuOpen(false);}} className="dropdown-item" to={settingsLink}>
                <img src="/assets/img/gear.svg" /> Settings
              </Link>
              
              <Link to="#" className="dropdown-item" onClick={userLogout}>
                <img src="/assets/img/logout.png" /> Logout
              </Link>
            </div>
          </li>
        </ul>
      </nav>
      <div className={`${props.isMobileMenuHidden ? "mobile-nav-active" : ""}`}>
        <nav className="mobile-nav">
          <ul className="float-left">
           
            <li className="dropdown-item" >
              <Link to="./" onClick={()=>{props.setIsMobileMenuHidden(false); }}>
                <img src="/assets/img/home.svg" /> Home
              </Link>
            </li>
            <li className="dropdown-item">
              <Link to="/search" onClick={()=>{props.setIsMobileMenuHidden(false)}}> 
                <img src="/assets/img/search_menu.svg" /> Search
              </Link>
            </li>
            <li className="dropdown-item">
              <Link to="/favourite-classes" onClick={()=>{props.setIsMobileMenuHidden(false)}}>
                <img src="/assets/img/heart.svg" /> Favorites
              </Link>
              </li>
              <li className="dropdown-item" onClick={()=>{props.setIsMobileMenuHidden(false)}}>
                <Link to="/friends-group">
                  <img src="/assets/img/group.svg" /> Cohort
                </Link>
              </li>
              <li className="dropdown-item">
              <Link to="/teacher-dashboard" onClick={()=>{props.setIsMobileMenuHidden(false); localStorage.setItem('teacherTabPage','Schedule') } }>
                <img src="/assets/img/calendar.svg" /> Schedule
              </Link>
              </li>
              <li className="dropdown-item">
              <Link to="/purchases" onClick={()=>{props.setIsMobileMenuHidden(false)}}>
                <img src="/assets/img/money.svg" /> Purchases
              </Link>
              </li>
              {/* <li className="dropdown-item">
              <Link to="/teach">
                <img src="/assets/img/team.svg" /> Teach
              </Link>
              </li> */}
              
              {/* <li className="dropdown-item">
              <Link to="/teach">
                <img src="/assets/img/team.svg" /> Partner
              </Link>
              </li> */}
              {/* <li className="dropdown-item">
              <Link to="/teach">
                <img src="/assets/img/planner.svg" /> Volunteers
              </Link>
              </li> */}
              <li className="dropdown-item">
              <Link onClick={()=>{localStorage.setItem('teacherTabPage','Profile'); props.setIsMobileMenuHidden(false) }} to={profileLink}>
                <img src="/assets/img/user.svg" /> Profile
              </Link>
              </li>
              <li className="dropdown-item">
              <Link onClick={()=>{localStorage.setItem('teacherTabPage','Settings'); props.setIsMobileMenuHidden(false) }} to={settingsLink}>
                <img src="/assets/img/gear.svg" /> Settings
              </Link>
              </li>
              <li className="dropdown-item">  
                  <Link to="#" onClick={()=>{ userLogout(); props.setIsMobileMenuHidden(false); }}>
                    <img src="/assets/img/logout.png" /> Logout
                  </Link>
              </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default HeaderLoggedInNav;
