import React, { useState, useEffect } from 'react';
import DashboardClassListItem from './block/DashboardClassListItem';
import { Link } from 'react-router-dom';
import axios from 'axios';
import LoadingIcon from '../../../helpers/LoadingIcon';

export default function TeacherClassesTab(props) {
  const [records, setRecords] = useState([]);
  const [isFetched,setIsFetched]=useState(false)
  
  useEffect(() => 
  {
    axios.get('teacher/get-classes').then(res=>{      
      setRecords(res.data.data.classes);
      setIsFetched(true)
    });
  },[]);
    return (
      <div role="tabpanel" id="two">
        <div className="row class-board-sec">
          <div className="col-md-12">
            <h2>My Classes</h2>
            <div>
              <Link to={ '/add-class-step1'} className="get_button">
                Add New Class
              </Link>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
            {isFetched && records ? records.map((record, index) => {
            return  <div className="myclass col-md-4" key={index}>
                    <DashboardClassListItem class={record} />
                    </div>
            }) : '' }
            {!isFetched ? <LoadingIcon /> :''}
      
        </div>
          </div>
        </div>
      </div>
    );
}
