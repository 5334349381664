import React, { useState, useEffect,useRef,useContext } from 'react';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import HeaderTextBanner from '../../../layout/HeaderTextBanner'
import LoadingIcon from '../../../helpers/LoadingIcon';
import ClassListings from './ClassListings';
import {Link,useLocation} from 'react-router-dom';
import ReactStars from "react-rating-stars-component";
import {getLoggedFullname} from '../../../auth/Athentication'

import '../../../../css/about.css'
import GlobalContext from "../../../../GlobalContext";

export default function MyClassRoom(props) {
  const {setIsInnerPage} = useContext(GlobalContext)
  setIsInnerPage(true)
  const [classList,setClassList]=useState([])
  const [userReviews,setUserReviews]=useState([])
  const [isFetched, setIsFetched] = useState(false);
  const [roomInfo, setRoomInfo] = useState({});
  const [inputs,setInputs]=useState({description:'',rating:0});
  const [teacherInfo, setTeacherInfo] = useState({});
  const [upcommingClasses, setUpcommingClasses] = useState([]);
  const [completedClasses, setCompletedClasses] = useState([]);
  const [isClassesFetching, setIsClassesFetching] = useState(true);
  const [selectedClassId, setSelectedClassId] = useState(null);
  
  useEffect(() => {
    getEnrolledClassList();
  },[]);
  
  useEffect(() => {
    getClassMeetings(selectedClassId);
  }, [selectedClassId]);

  async function getEnrolledClassList(){ //select box list and 
    const res = await axios.get("get-user-enrolled-class-list");
    if(res.data.data){
      setClassList(res.data.data.classes);
      setRoomInfo(res.data.data.meetingInfo)
    }
    setIsFetched(true)
  }

  const handleOnChange = (event)=>{
    event.preventDefault();
    const input = event.target;
    if(event.target.name == 'description'){
      setInputs(inputs => ({...inputs, [input.name]: input.value}));
    }
  }
  const handleRatingChange = (inputRating)=>{
    setInputs(inputs => ({...inputs, rating: inputRating}));
  }

  const handleSeletedClassId = (event)=>{
    const input = event.target;
    setSelectedClassId(input.value);
  }

  async function getClassMeetings(classID){
    setIsClassesFetching(true)
    const reqData = {
      params:{
        class_id:classID,
      }
    }
    const res = await axios.get("get-user-class-meetings",reqData);
    if(res.data.data){
      setUpcommingClasses(res.data.data.upcomming);
      setCompletedClasses(res.data.data.completed)
      setUserReviews(res.data.data.user_reviews)
      setTeacherInfo(res.data.data.teacher_info);
    }
    setIsClassesFetching(false)
  }
  const submitFormHandler = (event) =>{
    event.preventDefault();
    let formdata = new FormData();
   
    for ( var key in inputs ) {
      formdata.append(key, inputs[key]);
    }

    axios.post('submit-user-rating',formdata).then((res)=>{
      console.log(res.data);  
    }).catch((err)=>{
      console.log(err)
    })

  }
  
  return (
    <>
    <HeaderTextBanner heading="Class Room" />
    <section className="user-dashboard">
        <div className="container-fluid cf">
          <div className="parent-profile">
            <div className="row">
              <div className="col-md-12">
                {isFetched ?
               <>
               <div className="row">
                    <div className="col-md-12">
                        <p>Hi, <span>{getLoggedFullname()}</span></p>
                        <p>Here is the link to attend the current section</p>
                    </div>
                </div>
             {classList.length > 0 ? 
             <>  
                <div className="row">
                    <div className="col-md-12">
                            { Object.keys(roomInfo).length > 0  ? 
                      <p>Next meeting for a class <strong>{roomInfo.title}</strong> is on {roomInfo.start_time}</p>
                      :''}
                        <div className="meet_box">
                            { Object.keys(roomInfo).length > 0  ? 
                                <a target="_blank" href={roomInfo.join_url} className="get_button">Join Meeting at {roomInfo.start_time}</a>
                               : <p>No meeting found.</p>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-5">
                        <div className="control">
                            <select className="form-control" onChange={handleSeletedClassId}  name="class-id" id="class">
                                <option value="">Select Class</option>
                                {classList.length > 0 && classList.map((call,index)=>{
                                  return (
                                    <option key={index} value={call.id}>{call.title}</option>
                                  )
                                })} 
                            </select>
                        </div>  
                        </div>
                      </div>
                        {isClassesFetching ?
                      <LoadingIcon /> :
                      <>
                        <div className="class-list-wrapper">
                          <h3>Upcoming Sections</h3>
                          {upcommingClasses.length ?
                              <ClassListings classes={upcommingClasses}  /> : 
                          <p>No upcomming sections found. </p> }
                        </div>
                        <hr/>
                        <div className="class-list-wrapper">
                          <h3>Completed Sections</h3>
                          {completedClasses.length ?
                              <ClassListings classes={completedClasses}  /> : 
                          <p>No completed sections found. </p> }
                        </div>
                        </> }
                    </div>
                    <div className="col-md-4">
                      {userReviews.length ? 
                        <>
                          <h3>Reviews</h3>
                          {userReviews.map((review,index)=>{
                            return(
                              <>
                                <p>{review.review}</p>
                                <p>
                                <ReactStars count={5} size={24} activeColor="#FFC107"/>
                                </p>
                              </>
                            )
                          })}

                        </>
                      : selectedClassId && Object.keys(teacherInfo).length > 0 ? 
                        <div className="review-block pt-4">
                          <form onSubmit={submitFormHandler}>
                          <div className="row">
                            <div className="col-md-12 card card-body">
                                <h4>Rating</h4>
                                <p>Please leave the review for the Teacher <strong>{teacherInfo.teacher_name}</strong> </p>
                                <div className="form-group">
                                  <label htmlFor="">Description</label>
                                  <textarea name="review" id="review-description" rows={5} className="form-control"></textarea>
                                </div>
                                <div className="form-group">
                                  <ReactStars onChange={handleRatingChange} count={5} size={45} activeColor="#FFC107"/>
                                </div>
                                <div className="form-group">
                                  <button className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                          </div>
                          </form>
                        </div>
                      : ''}
                    </div>
                </div>
                          </>: 
                        <div className="row">
                          <div className="col-md-12">
                            You have not purchased any Class.
                          </div>
                        </div>   
                      }

               </>
                : <LoadingIcon /> }
              </div>
            </div>
          </div>
        </div>
      </section>
     
    </>
  );
}
