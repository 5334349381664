import React,{useContext,useEffect} from "react";
import { Link } from "react-router-dom";
import {IsAuthenticated} from '../../auth/Athentication'
import {isFovoriteClass,addToFavorites,removeFavorites} from '../../api/FavoritesApi'
import {GetStringForUrl} from '../../helpers/StringHelper'
import GlobalContext from "../../../GlobalContext";
export default function ClassListItem(props)
{
    const item = props.class;
    const {myFavorites,setMyFavorites} = useContext(GlobalContext)

        function time_convert(num)
        { 
        var hours = Math.floor(num / 60);  
        var minutes = num % 60;
            hours = hours>9?hours:'0'+hours;
            minutes = minutes>9?minutes:'0'+minutes;

            return (hours>0?hours+':':'') + minutes+'mins';         
        }

        async function handleFavorite(event,itemId,action)
        {
            event.preventDefault();
            if(action=='add'){
                const res = await addToFavorites(itemId);
                if(res){
                    const obj = {call_id:itemId,id:res};
                    setMyFavorites([...myFavorites,obj]);
                }
            }else{
                const res = await removeFavorites(itemId);
                if(res){
                    setMyFavorites(myFavorites =>myFavorites.filter(fav => fav.call_id !== itemId));
                }
            }
        }

    return (
        item.frequency ? 
        <div className="ss-item">
            <div className="col-md-12">
                <div className="trend-box">
                <Link to={"/class-details/"+GetStringForUrl(item.title)+'/'+item.class_uuid}>
                    <img src={item.class_photo} className="img-fluid" />
                <div className="free_tag">
                    <ul>
                    {item.channel_type =='exclusive' && <li>Exclusive</li>}
                    {item.pricing_type =='Free' && <li>Included at no cost</li>}
                    </ul>
                </div>
                </Link>
                <div className="box-info">
                    <ul className="float-left">
                    <li><img src="/assets/img/star.svg" /></li>
                    <li><img src="/assets/img/star.svg" /></li>
                    <li><img src="/assets/img/star.svg" /></li>
                    <li><img src="/assets/img/star.svg" /></li>
                    <li><img src="/assets/img/star.svg" /></li>
                    </ul>
                    { IsAuthenticated() ? 
                        <ul className="float-right favorite_ul">
                            {isFovoriteClass(myFavorites,item.id) ? <li><a href="#" onClick={(event)=>handleFavorite(event,item.id,'remove')} ><span className="favorite"><i className="bx bxs-heart"/></span></a></li>
                            :
                            <li><a href="#" onClick={(event)=>handleFavorite(event,item.id,'add')}><span className="favorite"><i className="bx bx-heart" /></span></a></li>
                            }
                        </ul>:'' }
                    <div className="clearfix" />
                        <h4><Link to={"/class-details/"+GetStringForUrl(item.title)+'/'+item.class_uuid}>{item.title}</Link></h4>
                    <ul className="details">
                        <li><span>Age:</span> {parseInt(item.age_range_start) > 18 ? 'Parent' : item.age_range_start +'-'+item.age_range_end}</li>
                        <li>|</li>
                        {/* <li><span>Duration:</span> {time_convert(parseInt(item.class_duration_minutes))}</li> */}
                        <li><span>Duration:</span> {item.class_duration_minutes+' Mins'}</li>
                        <li>|</li>
                        <li><span>Frequency:</span> {item.frequency.no_of_weeks} week{parseInt(item.frequency.no_of_weeks)>1?'s':''}, {item.frequency.calls_in_week}xweek{parseInt(item.frequency.calls_in_week)>1?'s':''}</li>
                    </ul>
                    <hr />
                    <div className="tb-row">
                    <div className="tb-cell">
                        <img style={{width:'25px'}} src={item.teacher_photo} />
                    </div>
                    <div className="tb-cell">
                    <Link to={'/teachers/'+item.techer_user.unique_id}><p>{item.teacher_name}</p></Link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        :<></>
    )
}