import React, {useState,useEffect,useContext} from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import { useHistory } from "react-router-dom";
import parse from 'html-react-parser';
import Success from "../../../layout/alert/Success";

import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';


export default function CardDetails(props)
{
    let history = useHistory();
    const section = props.SectionInfo;
    const couponInfo = props.couponInfo;
    const [alertShow,setAlertShow] = useState(false);
    const [successMessage,setsuccessMessge] = useState();
    const [errorMessage,setErrorMessage] = useState();
    const [isSubmitting,setIsSubmitting] = useState();
    //stripe
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
       setErrorMessage('');
    }, [])

    const handleSubmit = async (event) => {
        setIsSubmitting(true);
        setErrorMessage('');
        event.preventDefault();
        // We don't want to let default form submission happen here,
        // which would refresh the page.
    
        if (!stripe || !elements) {
            setIsSubmitting(false);
          // Stripe.js has not yet loaded.
          // Make  sure to disable form submission until Stripe.js has loaded.
          return;
        }
    
        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);
    
        if (result.error) {
            setIsSubmitting(false);
            setErrorMessage(result.error.message)
          // Show error to your customer.
          console.log(result.error.message);
        } else {
          // Send the token to your server.
          // This function does not exist yet; we will define it in the next step.
          stripeTokenHandler(result.token);
        }
      };

      async function stripeTokenHandler(token) {
        const paymentData = {token: token.id,learners:props.SelectedChildrens,teacher_message:props.TeacherMessage,total_amount:props.totalPrice, section_id:section.section_id};
        if(couponInfo && couponInfo.coupon_code){
            paymentData.coupon_code = couponInfo.coupon_code;
            paymentData.coupon_amount = couponInfo.amount;
            paymentData.coupon_id = couponInfo.id;
        }
        const response = await axios.post('stripe/checkout', paymentData).then(res=>{
            setIsSubmitting(false);
            if(res.data.status == 'success'){
                setAlertShow(true);
                props.setCardModalShow(false)
                setsuccessMessge(res.data.message);
                setTimeout(function(){
                    history.push("/purchases");
                },5000);
            }else{
                setErrorMessage(res.data.message);
            }
        }).catch(error=>{
            setIsSubmitting(false);
            if (error.response) {
                setErrorMessage(error.response.data.message);
                console.log(error.response.status);
                console.log(error.response.headers);
              }
        });
      }


    return (
        <>
        {successMessage && <Success setAlertShow={setAlertShow} message={parse(successMessage)} show={alertShow} /> }
        <Modal backdrop="static" keyboard={false}  dialogClassName="singup-form-div" show={props.CardModalShow} onHide={()=>{props.setCardModalShow(false)}}>
            <Modal.Header closeButton>
                <div className="img-box  align-items-center">
                    <img src={require('../../../../images/icon-logo.svg')} />
                </div>
            </Modal.Header>
            <Modal.Body>
            <div className="form-box">
                <h3>Actyvate Learning</h3>
                <p><strong>{Object.keys(props.SelectedChildrens).length}x {section.title}</strong></p>
                <p>{section.section_name}</p>   
                <p><a href="#" onClick={(e)=>e.preventDefault()} >{props.email}</a></p>
                <div className="row">
                <div className="col-md-12">
                    <form className="form-horizontal login_model" onSubmit={handleSubmit}>
                    <fieldset>
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                        <div className="control-group pb-3">
                        <div className="row">
                            <div className="col-md-12">
                            <CardElement 
                            />
                            {/* <div className="controls">
                                <input id="fname" name="fname" className="form-control input-large" type="text" placeholder="Card Number" required />
                                <img src="/assets/img/pay.svg" />
                            </div> */}
                            </div>
                        </div>
                        </div>
                        {/* <div className="control-group">
                        <div className="row">
                            <div className="col-md-6">
                            <div className="controls">
                                <input id="date" name="date" className="form-control input-large" type="text" placeholder="MM/YY" required />
                                <img src="/assets/img/calendar.svg" />
                            </div>
                            </div>
                            <div className="col-md-6">
                            <div className="controls">
                                <input id="cvc" name="cvc" className="form-control input-large" type="text" placeholder="CVC" required />
                                <img src="/assets/img/form-password.svg" />
                            </div>
                            </div>
                        </div>
                        </div> */}
                        <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="control-group">
                            <div className="controls">
                                <button id="confirmsignup" name="confirmsignup" disabled={!stripe || isSubmitting} className="btn btn-success register-btn">Pay ${props.totalPrice}</button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </fieldset>
                    </form>
                </div>
                </div>
            </div>
            </Modal.Body>
            </Modal>
        </>
    )
}