import React,{useContext} from 'react';
import { useState } from 'react';
import EnrollClass from '../../../classes/blocks/enroll/EnrollClass';
import { Link } from 'react-router-dom';
import {IsAuthenticated} from '../../../auth/Athentication'
import InviteCohortModal from '../../../users/InviteCohortModal'
import { GetStringForUrl } from '../../../helpers/StringHelper'
import GlobalContext from '../../../../GlobalContext'
import {isFovoriteClass,addToFavorites,removeFavorites} from '../../../api/FavoritesApi'
export default function SearchResultClassItem(props){
    const item = props.classItem;
    const {setLoginModelOpen,loggedIn,setIsModelBackdropOpen,myFavorites,setMyFavorites} = useContext(GlobalContext)
    const [EnrollModalShow,setEnrollModalShow] = useState(false);
    const [showInvitationModal,setShowInvitationModal] = useState(false);
    const EnrollSectionID = item.section_id;

    function handleEnroll(e){
      e.preventDefault();
      if(loggedIn){
      setEnrollModalShow(true);
      }else{
        setLoginModelOpen(true)
        setIsModelBackdropOpen(true)
      }
    }
    const handleInvitation = (e) =>{
      e.preventDefault();
      setShowInvitationModal(true)
    }
    async function handleFavorite(event,itemId,action)
    {
      event.preventDefault();
      if(action=='add'){
        const res = await addToFavorites(itemId);
        if(res){
          const obj = {call_id:itemId,id:res};
          setMyFavorites([...myFavorites,obj]);
        }
      }else{
        const res = await removeFavorites(itemId);
        if(res){
            setMyFavorites(myFavorites =>myFavorites.filter(fav => fav.call_id !== itemId));
        }
      }
    }
        return( 
          <>   
            <div className="topic-box" >
                <div className="tb-row">
                  <div className="tb-cell">
                    <Link to={"/class-details/"+GetStringForUrl(item.title)+'/'+item.class_uuid}>
                      <img className="img-responsive teacher-img" src={item.class_photo} />
                      {item.pricing_type =='Free' && <div className="free_tag">Included at no cost</div>}
                      {item.channel_type =='exclusive' && <div className="nocost_span">Exclusive</div>}
                    </Link>
                  </div>
                  <div className="tb-cell text-left pd-left">
                 <h3 className="channel_info"> {item.channel_name}</h3>
                 <h2>
                    <Link to={"/class-details/"+GetStringForUrl(item.title)+'/'+item.class_uuid}>{item.title}</Link>
                    { IsAuthenticated() ? 
                      isFovoriteClass(myFavorites,item.class_id) ? <a href="#" onClick={(event)=>handleFavorite(event,item.class_id,'remove')} ><span className="favorite"><i className="bx bxs-heart"/></span></a> :
                      <a href="#" onClick={(event)=>handleFavorite(event,item.class_id,'add')}><span className="favorite"><i className="bx bx-heart" /></span></a>
                    :'' }
                  </h2>
                  <p>{item.short_description}</p>
                 <p className="fw-600 teacher-p"> <Link to={'/teachers/'+item.teacher_unique_id}>{item.teacher_name}</Link><br /><span>Crossroads School for Music</span></p>
                    <ul className="info-list">
                      <li><span>Age:</span> {item.ages}</li>
                      <li><span>Class Frequency:</span> {item.frequency}</li>
                      <li><span>Duration:</span>{item.duration}</li>
                    <li><span>Price: </span> {item.pricing_type == 'Discounted' ? <span className="cross white">{item.actual_price}</span> : ''} {item.price}</li>
                      <li><span>​Next Start Time &amp; Date:</span> {item.next_meeting}</li>
                      <li className="last_li"><a href="#" onClick={handleEnroll} >Enroll</a></li>
                      { IsAuthenticated() ? <li className="p-0 search-invite-btn"><button className="btn1" onClick={handleInvitation} >Invite</button></li> : ''}
                    </ul>
                  </div>
                </div>
              </div> 
            {EnrollModalShow ? <EnrollClass EnrollModalShow={EnrollModalShow} EnrollSectionID={EnrollSectionID} setEnrollModalShow={setEnrollModalShow} /> : ''}

            { IsAuthenticated() && showInvitationModal ? <InviteCohortModal invitationFor={'searched_class'} sectionId={EnrollSectionID} show={showInvitationModal} setShowInvitationModal={setShowInvitationModal} /> :'' }                                    
          </>
    );
}