import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';

export default function RecordingsTab(props) {

    return (
        <div className="recordings ">
        <div className="row">
          <div className="col-md-12">
            <h2>Recordings</h2>
            <p>New recordings may take up to four hours to appear. This list only shows recordings over 3 minutes in length.</p>
            <p>Recordings may be shared with parents enrolled in this section upon request, and may be used by teachers to review and improve your classes. Recordings may not be distributed to anyone else or used for any other purposes.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 video-recordings">
            <img src="/assets/img/video-recordings.jpg" className="img-responsive" />
          </div>
          <div className="col-md-6">
            <h3>Music Practice Sharing Club- Share What You Are Practicing &amp; Be Encouraged!</h3>
            <p><strong>Wed Aug 5 at 9am - 9:55am IST</strong></p>
            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s when an unknown printer took.lorem Ipsum has been the industry's standard dummy text ever since the 1500s when an unknown printer took.</p>
          </div>
        </div>
        </div>
    )
}