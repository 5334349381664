import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DashboardClassListItem from './block/DashboardClassListItem';
import DashboardSectionListItem from './block/DashboardSectionListItem';
import {Link} from 'react-router-dom';
import LoadingIcon from '../../../helpers/LoadingIcon';

export default function TeacherDashboardTab(props) {
  const [records, setRecords] = useState([]);
  const [currentSections, setCurrentSections] = useState([]);
  const [upcomingSections, setUpcomingSections] = useState([]);
  const [counters, setCounters] = useState({});
  const [isFetched,setIsFetched]=useState(false)
  
  useEffect(() => {
    axios.get('teacher/get-classes/8').then(res=>{      
      setRecords(res.data.data.classes);
      setIsFetched(true)
    });
    axios.get('sections/current-sections').then(res=>{
      setCurrentSections(res.data.data);
    });
    axios.get('sections/upcoming-sections/5').then(res=>{
      setUpcomingSections(res.data.data);
    });
    axios.get('teacher/dashboard-counters').then(res=>{
      setCounters(res.data.data);
    });
  },[]);

    return (
      <div role="tabpanel" id="one">
        {isFetched ? 
        <div className="row teacher-dashboard">
          <div className="col">
            <div className="teacher-box">
              <i className="icofont-settings-alt" />
              <h2>
              <span>{ Object.keys(counters).length && counters.sections? counters.sections :'0'}</span>  Sections in Progress
              </h2>
            </div>
          </div>
          <div className="col">
            <div className="teacher-box">
              <i className="icofont-users-alt-3" />
              <h2>
                <span>5</span> Followers
              </h2>
            </div>
          </div>
          <div className="col">
            <div className="teacher-box">
              <i className="icofont-presentation" />
              <h2>
               <span>{ Object.keys(counters).length && counters.classes? counters.classes : '0' }</span> Available Classes
              </h2>
            </div>
          </div>
          <div className="col">
            <div className="teacher-box">
              <i className="icofont-star" />
              <h2>
                <span>-</span>Average Star Rating
              </h2>
            </div>
          </div>
          <div className="col">
            <div className="teacher-box last-child">
              <i className="icofont-dollar-plus" />
              <h2>
                <span>${ Object.keys(counters).length && counters.teacher_earnings ? counters.teacher_earnings:'0'}</span> Lifetime Earnings
              </h2>
            </div>
          </div>
        </div> : ''}
        <div className="row class-board-sec">
          <div className="col-md-8">
            <h2>My Classes</h2>
            <div className="row">
              {isFetched && records ?
          records.map((record, index) => {
          return  <div className="myclass col-md-6" key={index}>
                    <DashboardClassListItem class={record} />
                  </div>
          }) :'' }
          {!isFetched ? <LoadingIcon /> : ''}
            </div>
          </div>
          <div className="col-md-4">
          {Object.keys(currentSections) ? 
              Object.keys(currentSections).map((index)=>{
                const section = currentSections[index];
              return(
              <>
                <h2>Current Section</h2>
            <div className="myclass">
              <div className="tb-row">
                <div className="tb-cell">
                  <img
                    className="img-responsive teacher-img-new"
                    src={section.class_photo}
                  />
                  <span className="date_span">{section.section_date}</span>
                </div>
                <div className="tb-cell text-left pd-left">
                  <p>
                    <Link onClick={()=>{ localStorage.setItem('sectionAcitveTab','EditSection')}} to={'section/'+section.call_uid+'/'+section.section_uid} className="time_link">
                      <span>{section.section_name}</span>
                    </Link>
                  </p>
                  <p className="class-name">
                    <a href="#teacher">
                      <strong>{section.class_name}</strong>
                    </a>
                  </p>
                  <p> {section.enroll_count} enrolled</p>
                  <ul className="favorite_ul">
                    <li>
                      <a href className="not-list cls-progress">
                        In Progress
                      </a>
                    </li>
                    <li>
                      <a href className="not-list">
                        {section.visibility}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            </>
            )
              }) : ''}
            <br />
            {Object.keys(upcomingSections).length>0
            ?
            <><h2>Upcoming Section</h2>
            {Object.keys(upcomingSections) ? 
              Object.keys(upcomingSections).map((index)=>{
                const section = upcomingSections[index];
              return(
                <DashboardSectionListItem key={index} section={section} />
              )
            }) : ''}
            <p>
              <a href="#" className="schedule_link">
                View Schedule
              </a>
            </p>
            </>
            :'No sections created yet.'}
          </div>
        </div>
      </div>
    );
}
