import React from 'react'

export default function SubmittingLoadingImg(props)
{
    const imgWidth = props.width?props.width:100;
    return (
        <div className="text-center w-100">
            <img src="/assets/img/loading1.gif" width={imgWidth} alt="Loading" />
        </div>
    )
}