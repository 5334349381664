import React from 'react';

export default function ReviewItem(props)
{
    const review = props.review;
    const userReview = () =>{
        const rating = parseInt(review.rating);
        for(let i = 1; i<=rating; i++){
            return (
                <li><i className="bx bxs-star" /></li>
            )
        }
    }

    return (
        <>
        <div className="single-review">
            <div className="tb-row">
            <div className="tb-cell">
                <img className="img-responsive" src="/assets/img/smile.png" />
            </div>
            <div className="tb-cell text-left pd-left">
                <p><strong>{review.fullname}</strong>, on {review.review_date}</p>
                <ul>
                    {userReview()}
                </ul>
            </div>
            </div>
                <p>{review.review}</p>
        </div>
        </>
    )
}