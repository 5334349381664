import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import LoadingIcon from '../../../../helpers/LoadingIcon'
import './css/learners.css'

export default function LearnersTab(props) {
    const sectionId = props.section_id;
    const [enrollers,setEnrollers] = useState([]);
    const [isFetched,setIsFetched]=useState(false);

    useEffect(()=>{
        getEnrollers();
    },[]); 
    async function getEnrollers(){
        const res = await axios.get('teacher/section-enrollers',{params:{section_id:sectionId}});
        console.log(res.data.status)
        if(typeof(res.data.status) !='undefined' && res.data.status == 'success'){
            setEnrollers(res.data.data);
            setIsFetched(true)
        }else{
            alert('something went wrong while fetching enrollers.')
            return;
        }
    }
    return (
        <div className="learner_sec">
            {isFetched ? 
            <div className="row">
                <div className="col-md-3 left_div">
                    <h3>Enrolled ({enrollers.length})</h3>
                    {enrollers.map((enroll,index)=>{
                        return (
                            <p key={index}>{enroll.child?enroll.child.first_name+' '+enroll.child.last_name : enroll.enrolled_user ? enroll.enrolled_user.first_name+' '+enroll.enrolled_user.last_name :<p key={index}>-</p> }</p>
                        )
                    })}
                </div>
                <div className="col-md-9 text-center">
                    <p>No learners selected yet.</p>
                    <p>Awaiting for teacher/volunteer approval </p>
                </div>
            </div>
            : <LoadingIcon />}
        </div>
    )
}