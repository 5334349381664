import React from 'react';
import {Link} from 'react-router-dom';
import {getStatusLableClass,getCleanStatus} from '../../../../helpers/StringHelper';

export default function DashboardClassListItem(props) {

return (
    <div className="tb-row">
                  <div className="tb-cell">
                    <img
                      className="img-responsive teacher-img-new"
                      src={props.class.class_photo}
                    />
                  </div>
                  <div className="tb-cell text-left pd-left">
                    <a href="#" className="not-list">
                      {getCleanStatus(props.class.status)}
                    </a>
                    <p className="class-name">
                      <Link onClick={()=> { localStorage.setItem('classDefaultTab','ClassSummaryTab')}} to={`class/edit/${props.class.class_uid}`}>
                        <strong>
                        {props.class.title}
                        </strong>
                      </Link>
                    </p>
                    <ul className="favorite_ul"> 
                      <li>
                        <span className="favorite">
                          <i className="bx bxs-heart active" />
                        </span>
                      </li>
                      <li>
                        <span>{props.class.call_favorites_count}</span> saves
                      </li>
                    </ul>
                  </div>
                </div>
)
}