import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import {logout} from  '../../../auth/Athentication'; 
import {Redirect} from 'react-router-dom';
import { HiddenCropper } from "react-bootstrap-image-cropper";
import { useForm } from "react-hook-form";
import LoadingIcon from '../../../helpers/LoadingIcon';

export default function TeacherProfileTab(props) {
  const initialInputs = {};
  const [inputs, setInputs] = useState(initialInputs);
  const triggerRef = useRef();
  const [formErrors, setFormErrors] = useState(null);      
  const [isSubmitting, setIsSubmitting] = useState(false);        
  const [profilePhoto, setProfilePhoto] = useState(null);      
  const [profilePhotoSrc, setProfilePhotoSrc] = useState('/assets/img/smile.png');      
  const [introVideo, setIntroVideo] = useState(null);      
  const [introVideoSrc, setIntroVideoSrc] = useState(null);      
  const [successMessage, setSuccessMessage] = useState(null);     
  const [isLoggedOut, setIsLoggedOut] = useState(false);     
  const [isFetched,setIsFetched]=useState(false)
  const { register,handleSubmit,clearErrors,setError,errors } = useForm();
  const formdata = new FormData();   //formdata object

  useEffect(() => {
    getTeacherDetails();
  },[]);

   const userLogout = async () =>{
      const loggedOut = await logout();
      if(loggedOut == true){
        setIsLoggedOut(true);
      }
  }
  async function getTeacherDetails()
  {
    const response = await axios.get("teacher/get-detail");
    if(typeof(response.data.data)!='undefined' && response.data.status=='success'){
      const info = response.data.data;
      const formInputs = {
        first_name:info.user.first_name,
        last_name:info.user.last_name,
        nick_name:info.user.nick_name,
        profile_url:info.profile_url,
        headline:info.headline?info.headline:'',
        about_me:info.about_me?info.about_me:'',
        current_school:info.current_school?info.current_school:'',
        current_school_city:info.current_school_city?info.current_school_city:'',
        current_school_state:info.current_school_state?info.current_school_state:'',
        previous_school1:info.previous_school1?info.previous_school1:'',
        previous_school1_city:info.previous_school1_city?info.previous_school1_city:'',
        previous_school1_state:info.previous_school1_state?info.previous_school1_state:'',
        previous_school2:info.previous_school2?info.previous_school2:'',
        previous_school2_city:info.previous_school2_city?info.previous_school2_city:'',
        previous_school2_state:info.previous_school2_state?info.previous_school2_state:'',
      }
      setInputs(formInputs);
      setProfilePhotoSrc(info.teacher_photo);
      setIntroVideoSrc(info.teacher_video);
      setIsFetched(true)
    }else{
      alert('Internal server Errro.')
      return;
    }
    
  }

  const handleOnChangeVideo = (event) => {
    var inputName = event.target.name;
    const file = event.target.files[0];
    if(!file){
        return;
    }
    const fileSize = Math.round((file.size / 1024)); 
        if (fileSize >= 5096) { 
            setError("intro_video",{type:"manual",message:"File too large, please select less then 5MB."});
        }else{
            clearErrors(['intro_video']);
            setIntroVideo({file:file,name:file.name});
            }
    }

   //for form inputs 
   const handleInputChange = async (event) => {
    event.persist();
    const input = event.target;
        setInputs(inputs => ({...inputs, [input.name]: input.value}));
   };

   //class Image  
   function handleCropped(file) {
    setProfilePhotoSrc(URL.createObjectURL(file));  
    setProfilePhoto({file:file,name:'profilePhoto.png'}); 
  }


  //submission
const formSubmit = (data)=>{
  setIsSubmitting(true);
for ( var key in inputs ) {
  formdata.append(key, inputs[key]);
}
if(profilePhoto){
  formdata.append('photo',profilePhoto.file,profilePhoto.name)
}
if(introVideo){
  formdata.append('video',introVideo.file,introVideo.name)
}


var post_url ='teacher/update-profile';
axios.post(post_url, formdata)
    .then(response => {
        let res = response.data;
        if(res.data){
            window.scrollTo(0, 0)
            setSuccessMessage(res.message);
            setIsSubmitting(false);
        }else{
            let msg_str ='';
            // console.log(res.message.max_guests[0]);
            Object.keys(res.message).map((ele)=>{
                res.message[ele].map((msg,key) => {
                    console.log(msg)
                    msg_str += msg+'\n';
                    setIsSubmitting(false);
                    setFormErrors(msg_str);
                    window.scrollTo(0, 0)
                });

            });
            
        }     
    })
    .catch(error => {
        setIsSubmitting(false);
        console.log(error);
        alert('Internal server error');
    });

};
    return (
      isLoggedOut ? <Redirect to="../" /> : 
      <div role="tabpanel" id="five">
        {successMessage ? <div className="alert alert-success">{successMessage}</div> :(formErrors?<div className="alert alert-danger">{formErrors}</div> : '')}
        <div className="row">
          {isFetched ? 
          <div className="col-md-8 offset-2">
            <Form className="form-horizontal teacher_form" onSubmit={handleSubmit(formSubmit)}>
              <h2>Public Profile (Teacher)</h2>
              <img src={profilePhotoSrc} />
              <br />
              <a onClick={() => triggerRef.current.trigger()} className="clr_link">
                Select Photo..
              </a>
              <div className="control-group">
                <div className="row">
                  <div className="col-md-6">
                    <label className="label_info" htmlFor="first_name">
                      First Name
                    </label>
                    <div className="controls">
                      <input
                        id="first_name"
                        name="first_name"
                        onChange={handleInputChange}
                        ref={register({ required: 'This field is required'})}
                        className="form-control input-large"
                        type="text"
                        value={inputs.first_name?inputs.first_name:''}
                      />
                    {errors.first_name && <p className="text-danger">{errors.first_name.message}</p>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="label_info" htmlFor="teacher">
                      Last Name
                    </label>
                    <div className="controls">
                      <input
                        id="last_name"
                        name="last_name"
                        className="form-control input-large"
                        ref={register({ required: 'This field is required'})}
                        onChange={handleInputChange}
                        type="text"
                        value={inputs.last_name?inputs.last_name:''}
                        />
                        {errors.last_name && <p className="text-danger">{errors.last_name.message}</p>}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label className="label_info" htmlFor="nick_name">
                      Nickname to be displayed
                    </label>
                    <div className="controls">
                      <input
                        id="nick_name"
                        name="nick_name"
                        className="form-control input-large"
                        ref={register({ required: 'This field is required'})}
                        onChange={handleInputChange}
                        type="text"
                        value={inputs.nick_name?inputs.nick_name:''}
                        />
                        {errors.nick_name && <p className="text-danger">{errors.nick_name.message}</p>}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="label_info" htmlFor="headline">
                      Headline
                    </label>
                    <div className="controls">
                      <input
                        id="headline"
                        name="headline"
                        ref={register({ required: 'This field is required'})}
                        onChange={handleInputChange}
                        className="form-control input-large"
                        type="text"
                        value={inputs.headline?inputs.headline:''}
                        />
                        {errors.headline && <p className="text-danger">{errors.headline.message}</p>}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label className="label_info" htmlFor="teacher">
                      About Me
                    </label>
                    <div className="controls">
                      <textarea
                        name="about_me"
                        value={inputs.about_me?inputs.about_me:''}
                        ref={register({ required: 'This field is required'})}
                        onChange={handleInputChange}
                        className="form-control text-area"
                        />
                        {errors.about_me && <p className="text-danger">{errors.about_me.message}</p>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="control-group">
                <label className="label_info" htmlFor="teacher">
                  Are you currently teaching, Where? (Optional)
                </label>
                <br clear="all" />
                <div className="row">
                  <div className="col-md-6">
                    <div className="controls">
                      <input
                        id="current_school"
                        className="form-control input-large"
                        ref={register({})}
                        onChange={handleInputChange}
                        name="current_school"
                        type="text"
                        value={inputs.current_school?inputs.current_school:''}
                        placeholder="Name of school"
                        />
                        {errors.current_school && <p className="text-danger">{errors.current_school.message}</p>}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="controls">
                      <input
                        id="current_school_city"
                        className="form-control input-large"
                        ref={register({})}
                        onChange={handleInputChange}
                        name="current_school_city"
                        type="text"
                        value={inputs.current_school_city?inputs.current_school_city:''}
                        placeholder="City"
                        />
                        {errors.current_school_city && <p className="text-danger">{errors.current_school_city.message}</p>}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="controls">
                      <input
                        id="current_school_state"
                        ref={register({})}
                        onChange={handleInputChange}
                        className="form-control input-large"
                        value={inputs.current_school_state?inputs.current_school_state:''}
                        name="current_school_state"
                        type="text"
                        placeholder="State"
                      />
                      {errors.current_school_state && <p className="text-danger">{errors.current_school_state.message}</p>}
                    </div>
                  </div>
                </div>
                <label className="label_info" htmlFor="teacher">
                  List of schools previously taught? (Optional)
                </label>
                <br clear="all" />
                <div className="row">
                  <div className="col-md-6">
                    <div className="controls">
                      <input
                        id="previous_school1"
                        ref={register({})}
                        onChange={handleInputChange}
                        className="form-control input-large"
                        name="previous_school1"
                        value={inputs.previous_school1?inputs.previous_school1:''}
                        type="text"
                        placeholder="Name of school"
                      />
                      {errors.previous_school1 && <p className="text-danger">{errors.previous_school1.message}</p>}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="controls">
                      <input
                        id="previous_school1_city"
                        className="form-control input-large"
                        ref={register({})}
                        onChange={handleInputChange}
                        name="previous_school1_city"
                        type="text"
                        value={inputs.previous_school1_city?inputs.previous_school1_city:''}
                        placeholder="City"
                      />
                      {errors.previous_school1_city && <p className="text-danger">{errors.previous_school1_city.message}</p>}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="controls">
                      <input
                        id="previous_school1_state"
                        className="form-control input-large"
                        ref={register({})}
                        onChange={handleInputChange}
                        name="previous_school1_state"
                        type="text"
                        placeholder="State"
                        value={inputs.previous_school1_state?inputs.previous_school1_state:''}
                      />
                      {errors.previous_school1_state && <p className="text-danger">{errors.previous_school1_state.message}</p>}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="controls">
                      <input
                        id="previous_school2"
                        className="form-control input-large"
                        name="previous_school2"
                        ref={register({})}
                        onChange={handleInputChange}
                        value={inputs.previous_school2?inputs.previous_school2:''}
                        type="text"
                        placeholder="Name of school"
                      />
                      {errors.previous_school2 && <p className="text-danger">{errors.previous_school2.message}</p>}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="controls">
                      <input
                        id="previous_school2_city"
                        ref={register({})}
                        onChange={handleInputChange}
                        value={inputs.previous_school2_city?inputs.previous_school2_city:''}
                        className="form-control input-large"
                        name="previous_school2_city"
                        type="text"
                        placeholder="City"
                        />
                      {errors.previous_school2_city && <p className="text-danger">{errors.previous_school2_city.message}</p>}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="controls">
                      <input
                        id="previous_school2_state"
                        value={inputs.previous_school2_state?inputs.previous_school2_state:''}
                        className="form-control input-large"
                        ref={register({})}
                        onChange={handleInputChange}
                        name="previous_school2_state"
                        type="text"
                        placeholder="State"
                      />
                      {errors.previous_school2_state && <p className="text-danger">{errors.previous_school2_state.message}</p>}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="control-group">
                <label className="label_info" htmlFor="teacher">
                  Certifications obtained in the last 5 years? (Optional)
                </label>
                <div className="row">
                  <div className="col-md-12">
                    <div className="controls">
                      <input
                        id="certificates"
                        ref={register({})}
                        value={inputs.certificates?inputs.certificates:''}
                        onChange={handleInputChange}
                        className="form-control input-large"
                        name="certificates"
                        type="text"
                        placeholder="Certificates"
                      />
                      {errors.certificates && <p className="text-danger">{errors.certificates.message}</p>}
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="control-group">
                <div className="row">
                  <div className="col-md-12">
                    <label className="label_info" htmlFor="teacher">
                      <strong>Intro Video</strong>
                    </label>
                    <br />
                    <div className="controls">
                      <label htmlFor="intro-video" className="get_button">
                        Record or Upload Video
                      </label>
                      <input type="file" id="intro-video" accept="video/*" name="intro_video" ref={register} className="hidden-input-file" onChange={handleOnChangeVideo} />
                        {errors.intro_video?<p className="text-danger">{errors.intro_video.message}</p>:''}
                      {introVideo?<p className="text-success">Video selected successfully.</p>:''}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="control-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="controls arrow_img">
                      <span>
                        <strong>You should:</strong>
                      </span>
                      <p>
                        {" "}
                        Show us (and show off!) the space where you will teach.{" "}
                      </p>
                      <p> Show us your teaching personality.</p>
                      <p>
                        {" "}
                        Share your expertise! Teach us something that you are
                        excited to share with learners on TunedIn.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="control mb-2">
                  <a onClick={userLogout} className="get_button">Logout from Actyvate Learning</a>   
                </div>
              </div>
              <div className="control-group mt-10">
                <div className="row">
                  <div className="col-md-12">
                    <label className="label_info" htmlFor="teacher">
                      Public Profile URL
                    </label>
                    <div className="controls">
                      <input
                        id="profile_url"
                        name="profile_url"
                        className="form-control input-large"
                        type="text"
                        defaultValue={inputs.profile_url}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="control-group">
                <div className="row">
                  <div className="col-md-6">
                    <div className="controls">
                      <button
                        disabled={Object.keys(inputs).length ? false : true}
                        id="confirmsignup"
                        type="submit"
                        name="confirmsignup"
                        disabled={isSubmitting}
                        className="btn btn-success register-btn"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div> : <LoadingIcon />}
        </div>
        <HiddenCropper
        triggerRef={triggerRef}
        onCropped={handleCropped}
        cropOptions={{ aspect: 4 / 3, maxZoom: 10 }}
        outputOptions={{ maxWidth: 300, maxHeight: 300 }}
        previewOptions={{ width: 300, height: 300 }}
        displayOptions ={{ removeButtonText :'Cacel' }}
      />
      </div>
    );
}
