import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import SubmittingLoadingImg from '../../../helpers/SubmittingLoadingImg'
import LoadingIcon from '../../../helpers/LoadingIcon'


export default function TeacherSettingsTab(props) {
  const initialInputs = {};
  const [inputs, setInputs] = useState(initialInputs);
  const triggerRef = useRef();
  const [formErrors, setFormErrors] = useState(null);      
  const [isSubmitting, setIsSubmitting] = useState(false);            
  const [allowOtherEmail, setAllowOtherEmail] = useState(false);            
  const [countries, setCountries] = useState(null);    
  const [isFetched,setIsFetched]=useState(false)
  const [successMessage, setSuccessMessage] = useState(null);     
  const { register,handleSubmit,clearErrors,setError,errors } = useForm();
  const formdata = new FormData();   //formdata object

  useEffect(() => {
    getCountries();
    getUserSettingsDetails();
  },[]);

  async function getCountries(){
    const dbcountries = await axios.get("get-countries");
    setCountries(dbcountries.data.data);
  }
  async function getUserSettingsDetails(){
    const response = await axios.get("teacher/get-detail");
    if(typeof(response.data.data)!='undefined' && response.data.status=='success'){
      const info = response.data.data;
      const formInputs = {
        phone:info.user.phone,
        email:info.user.email,
        other_email:info.user.other_email?info.user.other_email:'',
        teacher_type:info.teacher_type?info.teacher_type:'',
        status:info.status?info.status:'',
        autopost_recording:info.autopost_recording,
        paypal:info.paypal,
        city:info.city,
        state:info.state,
        country_id:info.country_id,
      }
      if(info.user.other_email){
        setAllowOtherEmail(true);
      } 
      setInputs(formInputs);
        setIsFetched(true);
    } 
  }
  const handleAutopostRecordingcheck = (event) =>{
    event.persist();
      const input = event.target;
      if(input.checked == true){
        setInputs(inputs => ({...inputs, autopost_recording: 'yes'}));
      }else{
        setInputs(inputs => ({...inputs, autopost_recording: 'no'}));
      }
  }

     //for form inputs 
     const handleInputChange = async (event) => {
      event.persist();
      const input = event.target;
          setInputs(inputs => ({...inputs, [input.name]: input.value}));
     };
      //submission
const formSubmit = (data)=>{
  setIsSubmitting(true);
  setFormErrors(null);
  setSuccessMessage(null);
for ( var key in inputs ) {
  formdata.append(key, inputs[key]);
}
var post_url ='teacher/update-settings-tab';
axios.post(post_url, formdata)
    .then(response => {
        let res = response.data;
        if(res.status=='success'){
            window.scrollTo(0, 0)
            setSuccessMessage(res.message);
            setIsSubmitting(false);
        }else{
            let msg_str ='';
            // console.log(res.message.max_guests[0]);
            Object.keys(res.message).map((ele)=>{
                res.message[ele].map((msg,key) => {
                    console.log(msg)
                    msg_str += msg+'\n';
                    setIsSubmitting(false);
                    setFormErrors(msg_str);
                    window.scrollTo(0, 0)
                });

            });
            
        }     
    })
    .catch(error => {
        setIsSubmitting(false);
        console.log(error);
        alert('Internal server error');
    });

}; 

    return (
      <div role="tabpanel" id="six">
        {successMessage ? <div className="alert alert-success">{successMessage}</div> :(formErrors?<div className="alert alert-danger">{formErrors}</div> : '')}
        <div className="row">
          {!isFetched ? <LoadingIcon /> :
          <div className="col-md-8 offset-2">
            <Form className="form-horizontal teacher_form" onSubmit={handleSubmit(formSubmit)}>
              <h2>Private settings</h2>
              <div className="control-group">
                <div className="row">
                  <div className="col-md-12">
                    <label className="label_info" htmlFor="teacher">
                      Phone
                    </label>
                    <div className="controls">
                      <input
                        id="phone"
                        ref={register({ required: 'This field is required'})}
                        onChange={handleInputChange}
                        name="phone"
                        className="form-control input-large"
                        type="text"
                        value={inputs.phone?inputs.phone:''}
                      />
                    {errors.phone && <p className="text-danger">{errors.phone.message}</p>}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="label_info" htmlFor="paypal+
                    ">
                      Paypal Email
                    </label>
                    <div className="controls">
                      <input
                        id="paypal"
                        onChange={handleInputChange}
                        ref={register({ required: 'This field is required'})}
                        name="paypal"
                        className="form-control input-large"
                        type="text"
                        value={inputs.paypal?inputs.paypal:''}
                      />
                    {errors.paypal && <p className="text-danger">{errors.paypal.message}</p>}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label className="label_info" htmlFor="teacher">
                      Autopost Recordings
                    </label>
                    <div className="controls">
                      <div className="switch_box box_4">
                        <div className="input_wrapper">
                          <input type="checkbox" name="autopost_recording" checked={inputs.autopost_recording=='yes'} onChange={handleAutopostRecordingcheck} className="switch_4" />
                          <span className="is_checked">ON</span>
                          <span className="is_unchecked">OFF</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="control-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="label_info" htmlFor="teacher">
                        Location
                      </label>
                    </div>
                    <div className="col-md-4">
                      <div className="controls">
                        <input
                          id="city"
                          ref={register}
                          onChange={handleInputChange}
                          className="form-control input-large"
                          name="city"
                          type="text"
                          value={inputs.city?inputs.city:''}
                          placeholder="City"
                        />
                        {errors.city && <p className="text-danger">{errors.city.message}</p>}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="controls">
                        <input
                          id="state"
                          ref={register}
                          onChange={handleInputChange}
                          value={inputs.state?inputs.state:''}
                          className="form-control input-large"
                          name="state"
                          type="text"
                          placeholder="State"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="controls">
                        <select
                          id="country_id"
                          className="form-control input-large"
                          name="country_id" 
                          onChange={handleInputChange}
                          value={inputs.country_id?inputs.country_id:''}
                          >
                            <option value="">Select</option>  
                            {countries && countries.map((country,index)=>{
                              return <option key={index} value={country.id}>{country.country_name}</option>
                            })}
                          </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="control-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="label_info" htmlFor="teacher">
                        Primary Email
                      </label>
                      <div className="controls">
                        <input
                          id="email"
                          className="form-control input-large"
                          name="email"
                          type="text"
                          ref={register({required:'This field is required'})}
                          onChange={handleInputChange}
                          value={inputs.email?inputs.email:''}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="control-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="label_info" htmlFor="teacher">
                        Other Email
                      </label>
                      {allowOtherEmail ? 
                      <input
                          id="other_email"
                          className="form-control input-large"
                          name="other_email"
                          type="text"
                          ref={register}
                          onChange={handleInputChange}
                          value={inputs.other_email?inputs.other_email:''}
                        /> 
                        :
                          <div className="controls">
                          <a onClick={()=>{setAllowOtherEmail(true)}} className="get_button add_mail">
                            + Add another email
                          </a>
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className="control-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="label_info" htmlFor="teacher">
                        Email Settings
                      </label>
                      <div className="controls">
                        <Link to="/teacher/email-settings" className="get_button add_mail">
                          Choose which emails you will receive
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {inputs.teacher_type && inputs.teacher_type == 'volunteer' ?
                <div className="control-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="label_info" htmlFor="convert-teacher">
                        Convert as a Teacher
                      </label>
                      {inputs.status && inputs.status !='volunteer_to_teacher' ?
                        <div className="controls">
                          <Link to="/teacher/convert-to-teacher" className="get_button add_mail">
                            Convert my account to Teacher
                          </Link>
                        </div>
                      :<div className="controls"> <button disabled={true} className="add_mail">
                      Form submitted
                    </button></div>}
                    </div>
                  </div>
                </div>
                :''}
                <div className="control-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="label_info" htmlFor="teacher">
                        Password
                      </label>
                      <div className="controls">
                        <Link
                          to="change-password"
                          className="get_button add_mail">
                            Change your password
                        </Link>
                        <br />
                        <span>
                          Do you have multiple TunedIn accounts?{" "}
                          <a href>Merge accounts</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="control-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="label_info" htmlFor="teacher">
                        Facebook  
                      </label>
                      <div className="controls">
                        <span>
                          Link your Facebook account to log in easily from any
                          device.
                        </span>
                        <br />
                        <a href="#" className="get_button facebook-btn">
                          <span>
                            <img src="assets/img/facebook.svg" />
                          </span>{" "}
                          Connect with Facebook
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="control-group">
                <div className="row">
                  <div className="col-md-6">
                    {isSubmitting ? <SubmittingLoadingImg /> :''}
                    <div className="controls">
                      <button
                        id="confirmsignup"
                        type="submit"
                        name="confirmsignup"
                        disabled={isSubmitting || Object.keys(inputs).length<=0 }
                        className="btn btn-success register-btn"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          }
        </div>
      </div>
    );
}
