import React, { useState,useEffect,useContext } from "react";
import HeaderTextBanner from '../../../layout/HeaderTextBanner';
import LearnersTab from './tabs/LearnersTab'
import RecordingsTab from './tabs/RecordingsTab'
import axios from 'axios';
import ClassRoomTab from './tabs/ClassRoomTab';
import EditSectionTab from './tabs/EditSectionTab';
import { useParams } from 'react-router-dom';
import GlobalContext from "../../../../GlobalContext";
import '../../../../css/edit-section.css'

function SectionsMain(props) {
    
    const {setIsInnerPage} = useContext(GlobalContext)  
    setIsInnerPage(true)
    const {class_id,section_id} = useParams();
    const [classID,setClassID] = useState(null);
    const [sectionID,setSectionID] = useState(null);
    const [defaultTab, setDefaultTab] = useState('Learners');
    const [sectionMeetings,setSectionMeetings] = useState({});
    const [classMeetings,setClassMeetings] = useState({});
    const [totalMeetings,setTotalMeetings] = useState(0);
    const [requiredMeetings,setRequiredMeetings] = useState(1);
    const [pageHeading, setPageHeading] = useState(null); 
    const [classPricing, setClassPricing] = useState({}); 
    const [sectionInfo, setSectionInfo] = useState(null); 
    const [isDateFetched, SetIsDateFetched] = useState(false); 
    
  useEffect(() => {
    if(localStorage.getItem('sectionAcitveTab')!=null && localStorage.getItem('sectionAcitveTab')!='' && typeof(localStorage.getItem('sectionAcitveTab'))!='undefined' ){ 
      setDefaultTab(localStorage.getItem('sectionAcitveTab'));
      }
    getSectionInfo(class_id,typeof(section_id)!='undefined'?section_id:'');
  }, []);

  useEffect(()=>{
    if(defaultTab){
      localStorage.setItem('sectionAcitveTab',defaultTab);
    }
  },[defaultTab])

  async function getSectionInfo(classId,sectionID) {
    var params = sectionID? classId+'/'+sectionID:classId;
    const response = await axios.get("class-section-full-details/"+params);
    console.log(response.data)
    if(!response.data.data){
      alert('Invalid Request');
      return;
    }
    const data = response.data.data;
    setPageHeading(data.heading);
    setSectionMeetings(data.section_meetings);     
    setClassMeetings(data.class_meetings);
    console.log("Max="+data.max_meetings)     
    setRequiredMeetings(data.max_meetings);     
    setTotalMeetings(data.total_meetings);     
    setSectionInfo(data.section_info);
    setClassID(data.class_id)
    setClassID(data.section_id)
    SetIsDateFetched(true)
  }
  return (
    <>
      <HeaderTextBanner heading={pageHeading} />
      <section className="user-dashboard">
        <div className="container-fluid cf">
          <div className="row">
            <div className="col-md-12">
              {/* Bootstrap CSS */}
              <ul
                className="nav nav-tabs user-dashboard-ul"
                role="tablist"
                id="myTab"
              >
                <li className="nav-item">
                  <a
                    onClick={() => setDefaultTab("Learners")}
                    className={
                      "nav-link " +
                      (defaultTab == "Learners" ? " active " : "")
                    }
                    href="#"
                    role="tab"
                    data-toggle="tab"
                  >
                    Learners
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => setDefaultTab("ClassRoom")}
                    className={
                      "nav-link " +
                      (defaultTab == "ClassRoom" ? " active " : "")
                    }
                    href="#"
                    role="tab"
                    data-toggle="tab"
                  >
                    Classroom
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => setDefaultTab("Recordings")}
                    className={
                      "nav-link " +
                      (defaultTab == "Recordings" ? " active " : "")
                    }
                    href="#three"
                    role="tab"
                    data-toggle="tab"
                  >
                    Recordings
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => setDefaultTab("EditSection")}
                    className={
                      "nav-link " +
                      (defaultTab == "EditSection" ? " active " : "")
                    }
                    href="#four"
                    role="tab"
                    data-toggle="tab"
                  >
                    Edit
                  </a>
                </li>
                
              </ul>
              <div className="tab-content profile_form">
                {
                  {
                    Learners: <LearnersTab section_id={section_id} />,
                    ClassRoom: <ClassRoomTab section_id={section_id}/>,
                    Recordings: <RecordingsTab />,
                    EditSection: class_id && isDateFetched ? 
                      <EditSectionTab 
                          class_id={class_id}
                          section_id={typeof(section_id!='undefined')?section_id:''}
                          sectionMeetings={sectionMeetings}
                          classMeetings={classMeetings}
                          totalMeetings={totalMeetings?parseInt(totalMeetings):0}
                          sectionInfo={sectionInfo?sectionInfo:null}
                          requiredMeetings={requiredMeetings?parseInt(requiredMeetings):1} />:'',
                  }[defaultTab]
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionsMain;
