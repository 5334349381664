import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import { Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import Success from "../../../layout/alert/Success";


export default function TeacherTab(props) {
  const [inputs, setInputs] = useState({});
  const triggerRef = useRef();
  const [formErrors, setFormErrors] = useState(null);      
  const [isSubmitting, setIsSubmitting] = useState(false);  
  const [formEle, setFormEle] = useState(null);  
  const [errorMsgBlock, setErrorMsgBlock] = useState({});      
  const [successMessage, setSuccessMessage] = useState(null);     
  const { register,handleSubmit,clearErrors,setError,errors } = useForm();
  const [alertShow, setAlertShow] = useState(false);


  useEffect(() => {
  },[]);
 
    
    //for form inputs 
    const handleInputChange = async (event) => {
        event.persist();
        const input = event.target;
        setInputs(inputs => ({...inputs, [input.name]: input.value}));
   };

  //submission
const formSubmit = (data)=>{
  const formdata = new FormData();   //formdata object
  setIsSubmitting(true);
for ( var key in inputs ) {
  formdata.append(key, inputs[key]);
}


var post_url ='partner/partner-register';
axios.post(post_url, formdata)
    .then(response => {
        let res = response.data;
        if(res.status && res.status == 'success'){
            setAlertShow(true)
            formEle.reset();
            setInputs({});
            setFormErrors(null);
            setSuccessMessage(res.message);
            setIsSubmitting(false);
        }else{
            let msg_str ='';
            if(typeof(res.message)== 'string'){
                setFormErrors(res.message);
                setIsSubmitting(false);
                errorMsgBlock.focus();
            }else{ 
            Object.keys(res.message).map((ele)=>{
                res.message[ele].map((msg,key) => {
                    console.log(msg)
                    msg_str += msg+'\n';
                    setFormErrors(msg_str);
                    setIsSubmitting(false);
                    errorMsgBlock.focus();
                });
            });
        }
            
        }     
    })
    .catch(error => {
        setIsSubmitting(false);
        console.log(error);
        alert('Internal server error');
    });

};
return (
  <div className="tabcontent form-box" id="Partner">
      {successMessage && <Success setAlertShow={setAlertShow} message={parse(successMessage)} show={alertShow} /> }
      { formErrors && <div className="alert alert-danger">{formErrors}</div> } 
        <h1>Apply to Partner</h1>
        <p>ActyvateLearning.com works with schools, camps, extracurricular organizations,
community partners and businesses to bring their programming online. When our
partners offer public and exclusive classes on our platform, we build community
amongst students and teachers so we can strengthen and support student outcomes.
If your organization is ready to Inspire students and families, we’d love to connect with
you. Please complete the form below and our partnership director will contact you.</p>
<h2><span>Step 1:</span> Complete the Partner form</h2>
        <Form className="form-horizontal teacher_form" ref={(el) => setFormEle(el)} onSubmit={handleSubmit(formSubmit)}>
          <div className="control-group">
            <div className="row">
              <div className="col-md-3">
              <input type="radio" className="hidden-input-file" ref={(ele)=>setErrorMsgBlock(ele)}/>
                <div className="controls">
                  <input id="organization_name" name="organization_name" onChange={handleInputChange} className="form-control input-large" ref={register({required:'This field is required.'})} type="text" placeholder="Organizations Name" />
                {errors.organization_name && <p className="text-danger">{errors.organization_name.message}</p>}
                </div>
              </div>
              <div className="col-md-3">
                <div className="controls">
                  <input id="organization_website" name="organization_website" onChange={handleInputChange} className="form-control input-large" ref={register({required:'This field is required.'})} type="text" placeholder="Organizations Website" />
                {errors.organization_website && <p className="text-danger">{errors.organization_website.message}</p>}
                </div>
              </div>
              <div className="col-md-3">
                <div className="controls">
                  <div className="selectdiv">
                    <select name="age_start" ref={register({required:'This field is required.'})} onChange={handleInputChange} id="age_start" className="form-control">
                      <option value="" >Age range of learner Start</option>
                      <option value="3">Age 3</option>
                      <option value="4">Age 4</option>
                      <option value="5">Age 5</option>
                      <option value="6">Age 6</option>
                      <option value="7">Age 7</option>
                      <option value="8">Age 8</option>
                      <option value="9">Age 9</option>
                      <option value="10">Age 10</option>
                      <option value="11">Age 11</option>
                      <option value="12">Age 12</option>
                      <option value="13">Age 13</option>
                      <option value="14">Age 14</option>
                      <option value="15">Age 15</option>
                      <option value="16">Age 16</option>
                      <option value="17">Age 17</option>
                      <option value="18">Age 18</option>
                      <option value="99">Above 18</option>
                    </select>
                  </div>
                </div>
                {errors.age_start && <p className="text-danger">{errors.age_start.message}</p>}
              </div>
              <div className="col-md-3">
                <div className="controls">
                  <div className="selectdiv">
                    <select name="age_end" id="age_end" ref={register({required:'This field is required.'})} onChange={handleInputChange} className="form-control">
                      <option value=''>Age range of learner End</option>
                      <option value="3">Age 3</option>
                      <option value="4">Age 4</option>
                      <option value="5">Age 5</option>
                      <option value="6">Age 6</option>
                      <option value="7">Age 7</option>
                      <option value="8">Age 8</option>
                      <option value="9">Age 9</option>
                      <option value="10">Age 10</option>
                      <option value="11">Age 11</option>
                      <option value="12">Age 12</option>
                      <option value="13">Age 13</option>
                      <option value="14">Age 14</option>
                      <option value="15">Age 15</option>
                      <option value="16">Age 16</option>
                      <option value="17">Age 17</option>
                      <option value="18">Age 18</option>
                      <option value="99">Above 18</option>
                    </select>
                  </div>
                </div>
                {errors.age_end && <p className="text-danger">{errors.age_end.message}</p>}
              </div>

            </div>
          </div>
          <div className="control-group">
            <div className="row">
              <div className="col-md-3">
                <div className="controls">
                  <input id="first_name" className="form-control input-large" onChange={handleInputChange} ref={register({required:'This field is required.'})} name="first_name" type="text" placeholder="Contact1 First Name (required)" />
                </div>
                {errors.first_name && <p className="text-danger">{errors.first_name.message}</p>}
              </div>
              <div className="col-md-3">
                <div className="controls">
                  <input id="last_name" className="form-control input-large" onChange={handleInputChange} ref={register({required:'This field is required.'})} name="last_name" type="text" placeholder="Contact1 Last Name (required)" />
                </div>
                {errors.last_name && <p className="text-danger">{errors.last_name.message}</p>}
              </div>
              <div className="col-md-3">
                <div className="controls">
                  <input id="email" className="form-control input-large" onChange={handleInputChange} ref={register({required:'This field is required.'})} name="email" type="text" placeholder="Contact1 Email (required)" />
                </div>
                {errors.email && <p className="text-danger">{errors.email.message}</p>}
              </div>
              <div className="col-md-3">
                <div className="controls">
                  <input id="phone" className="form-control input-large" onChange={handleInputChange} ref={register({required:'This field is required.'})} name="phone" type="text" placeholder="Contact1 Phone number (required)" />
                </div>
                {errors.phone && <p className="text-danger">{errors.phone.message}</p>}
              </div>
            </div>
          </div>
          <div className="control-group">
            <div className="row">
              <div className="col-md-3">
                <div className="controls">
                  <input id="contact_first_name" className="form-control input-large" onChange={handleInputChange} ref={register} name="contact_first_name" type="text" placeholder="Contact2 First Name (optional)" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="controls">
                  <input id="contact_last_name" className="form-control input-large" onChange={handleInputChange} ref={register} name="contact_last_name" type="text" placeholder="Contact2 Last Name (optional)" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="controls">
                  <input id="contact_email" className="form-control input-large" onChange={handleInputChange} ref={register} name="contact_email" type="text" placeholder="Contact2 Email (optional)" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="controls">
                  <input id="contact_phone" className="form-control input-large" onChange={handleInputChange} ref={register} name="contact_phone" type="text" placeholder="Contact2 Phone number (optional)" />
                </div>
              </div>
            </div>
          </div>
          <div className="control-group">
                <label className="label_info" htmlFor="teacher">Referred by </label><br clear="all" />
                <div className="row">
                  <div className="col-md-4">
                    <div className="controls">
                      <input id="referred_firstname" name="referred_firstname" className="form-control input-large" type="text" ref={register} onChange={handleInputChange} placeholder="First Name"  />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="controls">
                      <input id="referred_lastname" name="referred_lastname" className="form-control input-large" ref={register} onChange={handleInputChange} type="text" placeholder="Last Name (optional)" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="controls">
                      <input id="referred_email" name="referred_email" className="form-control input-large" ref={register} onChange={handleInputChange} type="text" placeholder="Email (optional)" />
                    </div>
                  </div>
                </div>
              </div>
          <div className="control-group">
            <div className="col-md-121">
              <label className="label_info" htmlFor="teacher">Additional information you would like to share about your organization</label> <br />
              <div className="controls">
                <textarea name="additional_information" onChange={handleInputChange} ref={register} className="form-control text-area" />
              </div>
            </div>
          </div>
          <div className="control-group" id="partner">
            <div className="controls">
              <label className="radio inline" htmlFor="terms">
                {/* <input type="checkbox" name="terms" id="terms" value="robot">&nbsp;*/} By submitting your application, you agree to the <a href="#" className="terms">Terms of Service</a> and <a href="#" className="terms">Privacy Policy.</a> </label> 
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              {isSubmitting && <span>Please wait the form is submitting...</span>}
              <div className="control-group">
                <div className="controls">
                  <button id="confirmsignup" disabled={isSubmitting} name="confirmsignup" className="btn btn-success register-btn">Submit Application</button>
                  {/* <a href="age-selection.html" class="btn btn-success register-btn">Register Now</a> */}
                </div>
              </div>
            </div>
          </div>
          </Form>
      </div>
      );
}