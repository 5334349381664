import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import LoadingIcon from '../../../helpers/LoadingIcon';
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal'
import "react-datepicker/dist/react-datepicker.css";
import {dateFormatString,ucFirst} from '../../../helpers/StringHelper';
import Sucess from '../../../layout/alert/Success'
import Axios from 'axios';

export default function TeacherPayoutsTab()
{
    const [isFetching,setIsFetching] = useState(false)
    const [SearchData, setSearchData] = useState();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [requestFormData, setRequestFormData] = useState({requested_amount:''});
    const [payoutsRecords, setPayoutsRecords] = useState([]);
    const [earningsData, setEarningsData] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [show, setShow] = useState(false);
    const [startDate, setStartDate] = useState();
    const [Skip, setSkip] = useState(0);
    const [endDate, setEndDate] = useState();
    useEffect(() => {
        setIsFetching(true);
        getTeacherPayouts(Skip)
    }, [Skip])
    
    
    const handleInput = (event)=>{
        event.preventDefault();
        const input = event.target; 
        setSearchData(SearchData => ({...SearchData, [input.name]: input.value}));
    }

    const handleReqFormInput = (event) =>{
        event.preventDefault();
        const input = event.target; 
        if(input.name == 'requested_amount'){
            if(input.value > earningsData.available_earnings){
                input.value = earningsData.available_earnings;
            }
            // else if(input.value < earningsData.minimum_payout_value){
            //     input.value = earningsData.minimum_payout_value;
            // }
        }
        setRequestFormData(requestFormData => ({...requestFormData, [input.name]: input.value}));
    }

    const handleReqFormSubmit = (event) =>{
        setErrorMessage('');
        event.preventDefault();
        if(!requestFormData.requested_amount){
            setErrorMessage('Please enter Requested amount.');
            return false;
        }else if(requestFormData.requested_amount < earningsData.minimum_payout_value || requestFormData.requested_amount > requestFormData.available_earnings ){
            setErrorMessage('Please check entered amount.');
            return false;
        }else{
            Axios.post('teacher/send-payout-request',{amount:requestFormData.requested_amount}).then(res=>{
                if(res.data.status== 'success'){
                    setSearchData({});
                    setIsFormSubmitted(true);
                    setShow(false);
                }
            }).catch(err=>{
                alert('Internal server error.')
                console.log(err);
            });
        }

    }
    const handleSearchFormSubmit = (event) =>{
         event.preventDefault();
        getTeacherPayouts(0);
    }
    async function getTeacherPayouts(skip)
    {
        const getData = {skip:skip};
        getData.startDate = startDate;
        getData.endDate = endDate;
        getData.sortList = SearchData?SearchData.sortDate:'';
        const res =  await Axios.get('teacher/get-payouts',{params:getData});
        setPayoutsRecords(res.data.data.records);
        if(skip == 0){
            setEarningsData(res.data.data.earningsInfo);
        }
        setIsFetching(false)
    }
    const handleClearSearch = (e) =>{
       window.location.reload()
    }

    return (
        <>
        <div role="tabpanel" id="Payouts">
            {isFormSubmitted ? 
                <Sucess show={isFormSubmitted} message={'Request submitted successfully.'} setAlertShow={setIsFormSubmitted} />
            :''}
            <section className="teacher-payouts-main user-dashboard">
            <div className="container-fluid cf">
                {isFetching ? <LoadingIcon /> : 
                <div className="row">
                    {earningsData ? 
                    <>
                    <div className="col-md-6">
                        <div className="card card-body bg-secondary text-white">
                            <div className="row">
                                <div className="col-md-7">
                                    <p className="mb-0">Your total Earnings: <strong>${earningsData.total_earnings} </strong></p>
                                    <p className="mb-0">{process.env.REACT_APP_SITE_NAME} Commission: <strong>${earningsData.admin_commission}</strong></p>
                                    <p className="mb-0">{process.env.REACT_APP_SITE_NAME} Paid You: <strong>${earningsData.paid_earnings}</strong></p>
                                    <p className="mb-0">Available: <strong>${earningsData.available_earnings}</strong></p>
                                </div>
                                <div className="col-md-5 text-right align-self-top">
                                    <button type="button" onClick={(e)=>{setShow(true)}} className="btn btn-warning">Request Payout</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                     : ''}
                    <div className="col-md-12">
                    <div className="payout_div">
                        <form action="#" method="post" role="form" onSubmit={handleSearchFormSubmit} className="php-email-form mb-20">
                            <div className="row search_section">
                            <div className="col-md-3">
                                <select name="sortDate" id="sortDate" onChange={handleInput} className="form-control">
                                    <option value="">All</option>
                                    <option value="-1 month">Last 30 days</option>
                                    <option value="-3 month">Last 3 months</option>
                                    <option value="-6 month">Last 6 months</option>
                                </select>
                            </div>
                            <div className="col-md-3">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={date => setStartDate(date)}
                                        selectsStart
                                        placeholderText="Start date"
                                        className="form-control"
                                        maxDate={new Date()}
                                        startDate={startDate}
                                        endDate={endDate}
                                    />
                            </div>
                            <div className="col-md-3">
                                    <DatePicker
                                        selected={endDate}
                                        className="form-control"
                                        placeholderText="End date"
                                        onChange={date => setEndDate(date)}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        maxDate={new Date()}
                                        minDate={startDate}
                                    />
                                </div>
                                <div className="col-md-3 align-self-center">
                                    <button className="btn btn-primary search_btn"><img src="/assets/img/search.svg" /> Search</button>
                                    <button onClick={handleClearSearch} className="btn btn-default ml-1">Clear</button>
                                </div>
                            </div>
                            
                        </form>
                        <table className="payout_table">
                            <thead>
                                <tr>
                                <th>Requested Amount</th>
                                <th>Requested On</th>
                                <th>Payout Date</th>
                                <th>Payout Note</th>
                                <th>Status</th>
                                </tr>
                            </thead>
                            <tbody> 
                                { payoutsRecords && payoutsRecords.length ? payoutsRecords.map((record,index)=>{
                                    return (
                                        <tr key={index}>
                                        <td> ${record.amount_requested}</td>
                                        <td>{record.created_at ? dateFormatString(record.created_at) : '-'}</td>
                                        <td>{record.payout_date ? dateFormatString(record.payout_date)+'(PayPal)' : '-'}</td>
                                        <td>{record.payout_note?record.payout_note:'-'}</td>
                                        <td><span className="status">{ucFirst(record.payout_status)}</span></td>
                                    </tr>
                                    )
                                }) :
                                <tr>
                                    <td colSpan={6}>No records found.</td>
                                </tr>
                                } 
                               </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                }
            </div>
        </section>
        </div>

        <Modal 
            backdrop="static"
            keyboard={false} 
            dialogClassName="enroll_form singup-form-div"
            show={show}
            onHide={()=>{setShow(false)}}>
        <Modal.Header closeButton>
            <div className="img-box  align-items-center">
                <img src={require('../../../../images/icon-logo.svg')} />
            </div>
        </Modal.Header>
        <Modal.Body>
            <div className="form-box">
                <form onSubmit={handleReqFormSubmit}>
                    <div className="row mt-20">
                            {earningsData ? 
                        <div className="col-md-10 m-auto">
                            {errorMessage ? 
                            <div className-="alert alert-danger">{errorMessage}</div> : ''}
                            <div className="card card-body bg-light">
                                <p className="mb-0">Total Earnings: <strong>${earningsData.total_earnings} </strong></p>
                                <p className="mb-0">Paid: <strong>${earningsData.paid_earnings}</strong></p>
                                <p className="mb-0">Available: <strong>${earningsData.available_earnings}</strong></p>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-4 align-self-center text-right">
                                    <label htmlFor="">Request Amount ($)</label>
                                </div>
                                <div className="col-md-8">
                                    <input type="number" required="required" name="requested_amount" className="form-control" onChange={handleReqFormInput} max={earningsData.available_earnings} value={requestFormData.requested_amount} min={earningsData.minimum_payout_value}/>
                                </div>
                            </div>
                                <p>
                                    <small><strong>Note:</strong>Requested amount should be minimum ${earningsData.minimum_payout_value} </small>
                                    <button type="submit" className="float-right btn btn-success">Send Request</button>
                                </p>
                        </div>
                            :''}
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    </>
    )
}