import React,{useState,useEffect} from "react";
import axios from 'axios';
import ReviewItem from './ReviewItem';

export default function ReviewList(props)
{
    const user_id = props.teacherId;
    const [classReviews,setClassReviews] = useState({});
    
    useEffect(() => {
        getClassReviews();
    }, []);

    async function getClassReviews() {
        const response = await axios.get("teacher/teacher-reviews",{params:{teacher_user_id:user_id}});
        if(!response.data.data){
          alert('Invalid Request');
          return;
        }
        setClassReviews(response.data.data);
    }

    return (
        Object.keys(classReviews).length ?
            classReviews.map((review, index) => { 
            return (
                <>
                <div className="review-sec">
                      <ul className="review-list">
                        <li><span>{Object.keys(classReviews).length} Reviews</span></li>
                        <li><i className="bx bxs-star" /></li>
                        <li><i className="bx bxs-star" /></li>
                        <li><i className="bx bxs-star" /></li>
                        <li><i className="bx bxs-star" /></li>
                        <li><i className="bx bxs-star" /></li>
                      </ul>
                    </div>
                <ReviewItem review={review} />
                    {/* <a href="javascript:void(0);" class="get_button request_btn">See More Reviews</a> */}
                </>)    
        }) : <></>
    )
}