import React,{useState,useEffect} from 'react'
import Axios from 'axios';
import LoadingIcon from '../../../helpers/LoadingIcon'

export default function AboutSec() {
  const [contactInfo,setContactInfo] = useState([]);
  const [isLoaded,setIsLoaded] = useState(false);

  useEffect(() => {
    getContactDetails();
  }, [])

  async function getContactDetails()
  {
    const res = await Axios.get('get-contact-details');
    if(res && res.data.status == 'success'){
      setContactInfo(res.data.data);
      setIsLoaded(true)
    }
  }
    return (
      <>
    <section className="user-dashboard">
        <div className="container-fluid cf">
          <div className="row">
            <div className="col-md-12">
              {isLoaded && Object.keys(contactInfo).length ? 
              <div className="payout_div">
                <div className="row">
                  <div className="col-md-6">
                    <div className="cnt_box">
                      <h3>Contact Info</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <ul className="ftr-links">
                            <li><span><i className="icofont-google-map" /></span> {contactInfo.contact_address}
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <ul className="ftr-links">
                            <li><span><img src="assets/img/phone.svg" className="icon" /></span> Phone No: <a href={'tel:'+contactInfo.site_phone}>{contactInfo.site_phone}</a></li>
                            <li><span><img src="assets/img/mail.svg" className="icon" /></span> Email ID: <a href={'mailto:'+contactInfo.site_email}>{contactInfo.site_email}</a></li>
                          </ul>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-md-12">
                          <form className="form-horizontal teacher_form">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="controls">
                                  <input id="fname" name="fname" className="form-control input-large" type="text" placeholder="Name" required />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="controls">
                                  <input id="email" name="email" className="form-control input-large" type="text" placeholder="Email" />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="controls">
                                  <input id="phone" name="phone" className="form-control input-large" type="text" placeholder="Phone Number" required />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="controls">
                                  <textarea name="experience" className="form-control text-area" defaultValue={""} />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="controls">
                                  <button id="confirmsignup" name="confirmsignup" className="btn btn-success register-btn">Submit</button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9552209.414043237!2d-13.425761316038678!3d54.23173073395106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x25a3b1142c791a9%3A0xc4f8a0433288257a!2sUnited%20Kingdom!5e0!3m2!1sen!2sin!4v1596882933827!5m2!1sen!2sin" width="100%" height={540} frameBorder={0} style={{border: 0}} allowFullScreen aria-hidden="false" tabIndex={0} />
                  </div>
                </div>
              </div>
              : <LoadingIcon /> }
            </div>
          </div>
        </div>
      </section>
      </>
    );
}
