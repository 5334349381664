import React,{ useState,useEffect,useContext } from "react";
import Modal from 'react-bootstrap/Modal'
import { Link,useHistory } from "react-router-dom";
import CardDetails from  './CardDetails';
import parse from 'html-react-parser';
import { useForm } from "react-hook-form";
import { getAge, getAgeRange,GetStringForUrl } from "../../../helpers/StringHelper";
import axios from 'axios';
import LoadingIcon from "../../../helpers/LoadingIcon";
import Success from "../../../layout/alert/Success";

export default function EnrollClass(props)
{
    let history = useHistory();
    const sectionID = props.EnrollSectionID;
    const [Childrens,setChildrens] = useState([]);
    const [ClickedAddLearner,setClickedAddLearner] = useState(false);
    const [formErrors,setFormErrors] = useState(null);
    const [UsedCouponInfo,setUsedCouponInfo] = useState();
    const [couponCode,setCouponCode] = useState();
    const [IsSubmitting,setIsSubmitting] = useState(false);
    const [isDisabledEnroll,setIsDisabledEnroll] = useState(false);
    const [isCouponApplied,setIsCouponApplied] = useState(false);
    const [couponMessage,setCouponMessage] = useState();
    const [showCouponBlock,setShowCouponBlock] = useState(false);
    const [IsFetching,setIsFetching] = useState(true);
    const [totalPrice,setTotalPrice] = useState(0);
    const [SectionPrice,setSectionPrice] = useState(0);
    const { register,handleSubmit,clearErrors,setError,errors } = useForm();
    const [SectionInfo,setSectionInfo] = useState([]);
    const [LoggedUserEmail,setLoggedUserEmail] = useState();
    const [TeacherMessage,setTeacherMessage] = useState(null);
    const [SelectedChildrens,setSelectedChildrens] = useState([]);
    //For free class enroll
    const [alertShow,setAlertShow] = useState(false);
    const [successMessage,setsuccessMessge] = useState();
    const [errorMessage,setErrorMessage] = useState();

    const [CardModalShow,setCardModalShow] = useState(false);

    const formdata = new FormData();

    useEffect(() => {
       get_logged_user_info();
       get_section_pricing(sectionID); 
    }, [sectionID])
    useEffect(() => {
       if(SectionPrice && Object.keys(SelectedChildrens).length){
           let price = SectionPrice * Object.keys(SelectedChildrens).length;
           if(UsedCouponInfo && UsedCouponInfo.amount){
             price = price - (price * (UsedCouponInfo.amount/100))
             setTotalPrice(price.toFixed(2));
           }else{
             setTotalPrice(price.toFixed(2));
           }
       } 
    }, [SelectedChildrens])

    async function get_logged_user_info()
    {
        const response = await axios.get('user/get-learners');
        setChildrens(response.data.data.learners);
        if(response.data.data.email){
          setLoggedUserEmail(response.data.data.email);
        }
        setIsFetching(false);
        
    }
    
    async function get_section_pricing(section_id)
    {
        setIsFetching(true);
        const secRes = await axios.get('get-section-price-details',{params:{section_id:section_id}});
        setSectionInfo(secRes.data.data);
        setSectionPrice(secRes.data.data.final_price);
        setIsFetching(false);
    }

    const handleChildrens = (child) =>{
        const isExists =  SelectedChildrens.filter((element) => {
          return element.id === child.id;
        })

        let newChecked = [...SelectedChildrens];
        if (!isExists.length) {
            newChecked.push(child)
        } else {
          newChecked = SelectedChildrens.filter(ele => ele.id !== child.id);
        }
        setSelectedChildrens(newChecked);
    }
    const handleInput = (event) =>{
      event.persist();
      const input = event.target;
      setTeacherMessage(input.value);
    }

    function errorAgeValidation(age){
        const childAge = parseInt(age)
        const age_start = parseInt(SectionInfo.age_range_start);
        const age_end = parseInt(SectionInfo.age_range_end);
        if(childAge >= age_start && childAge<=age_end){
            return false; // No error
        }
        return true; //Error
    }

//submit add child form
//submission
const childFormSubmit = (data)=>{
    setIsSubmitting(true);
    axios.post('parent/add-child', data)
        .then(response => {
            let res = response.data;
            if(res.status=='success'){
                setChildrens([...Childrens,res.data]);
                setIsSubmitting(false);
            }else{
                let msg_str ='';
                // console.log(res.message.max_guests[0]);
                Object.keys(res.message).map((ele)=>{
                    res.message[ele].map((msg,key) => {
                        msg_str += msg+'\n';
                        setIsSubmitting(false);
                        setFormErrors(msg_str);
                    });
    
                });
                
            }     
        })
        .catch(error => {
            setIsSubmitting(false);
            console.log(error);
            alert('Internal server error');
        });
    
    }; 
    const handleCouponChnage = (event) =>{
      const input = event.target;
      setCouponCode(input.value);
      setCouponMessage(couponMessage => ({...couponMessage, errorMessage:null} ) );
      
    }

  //enroll action
  const handleOnclickEnroll = async (event) => {
    setIsDisabledEnroll(true);
    event.persist();
    if(totalPrice > 0){
      setCardModalShow(true);
    }else{
      const enrollData = {
        section_id:sectionID,
        learners:SelectedChildrens,
        teacher_message:TeacherMessage,
      };
      if(couponCode && UsedCouponInfo.coupon_code){
        enrollData.coupon_code = UsedCouponInfo.coupon_code;
        enrollData.amount = UsedCouponInfo.amount;
        enrollData.coupon_id = UsedCouponInfo.id;
      }
      const response = await axios.post('stripe/checkout-free-class', enrollData).then(res=>{
        if(res.data.status == 'success'){
            setsuccessMessge(res.data.message);
            setAlertShow(true);
            setTimeout(function(){
                history.push("/purchases");
            },5000);
        }else{
            setErrorMessage(res.data.message);
        }
    }).catch(error=>{
        setIsSubmitting(false);
        if (error.response) {
            setErrorMessage(error.response.data.message);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
    });
    }
  }  

    const applyCouponAction = (event) =>{
      event.persist();
      const postData = {
        coupon_code:couponCode,
        section_id:sectionID
      }
      if(couponCode){
        axios.post('user/enroll-apply-coupon',postData).then(res=>{
          if(res.data.status=='success'){
            const couponData = res.data.data;
            setCouponMessage(couponMessage => ({...couponMessage, successMessage:res.data.message} ) );
            setIsCouponApplied(true);
            setUsedCouponInfo(couponData);
            if(totalPrice){
              let Totalprice = totalPrice - (totalPrice * (couponData.amount/100))
              setTotalPrice(Totalprice.toFixed(2));
            }
          }else{
            setCouponMessage(couponMessage => ({...couponMessage, errorMessage:res.data.message} ) );
          }
        });
      }else{
        setCouponMessage(couponMessage => ({...couponMessage, errorMessage:'Please enter Coupon'} ) );
      }
    }
    return (
        <>
        { SectionInfo ? 
    <Modal 
      backdrop="static"
      keyboard={false} 
      dialogClassName="enroll_form singup-form-div"
      show={props.EnrollModalShow}
      onHide={()=>{props.setEnrollModalShow(false)}}>
      <Modal.Header closeButton>
          <div className="img-box  align-items-center">
              <img src={require('../../../../images/icon-logo.svg')} />
          </div>
      </Modal.Header>
      <Modal.Body>
      <div className="form-box">
          <div className="row">
            <div className="col-md-12 mt-20">
              <div className="tb-row enroll_box">
                <div className="tb-cell">
                  <Link to={"/class-details/"+GetStringForUrl(SectionInfo.title)+'/'+SectionInfo.class_uuid}><img className="img-responsive enroll-img" src={SectionInfo.class_photo} /></Link>
                </div>
                <div className="tb-cell text-left pd-left">
                  <h2><Link to={"/class-details/"+GetStringForUrl(SectionInfo.title)+'/'+SectionInfo.class_uuid}>{SectionInfo.title}</Link></h2>
                  <p>{SectionInfo.section_name}</p>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-0">
              <h3>Learners</h3>
              {IsFetching ? <LoadingIcon /> : ''}
              {/* Terms input*/}
              <div className="control-group">
                  { Childrens && Childrens.map((child,index)=>{
                    const classAgeFrom = parseInt(SectionInfo.age_range_start); 
                    const classAgeTo = parseInt(SectionInfo.age_range_end);
                    let IsDisabled = false;
                    if(child.is_logged_user && classAgeFrom < 20){
                      IsDisabled = true;
                    }else if(!child.is_logged_user && classAgeFrom > 20){
                      IsDisabled = true;
                    }
                      return (
                      <div className="controls" key={index}>
                          <label className="radio inline" htmlFor={'child-'+index}>
                      <input type="checkbox" disabled={IsDisabled} checked={SelectedChildrens.find(ele=>ele.id == child.id)}  name="learners" onChange={()=>handleChildrens(child)} id={'child-'+index} value={child.id} />&nbsp; {child.first_name+' '+child.last_name} (age {getAge(child.age)} {child.is_logged_user?'/me':''})</label>
                      { SelectedChildrens.filter(element =>element.id === child.id).length && SectionInfo && errorAgeValidation(child.age) ?
                      <p className="text-danger">This Class is for ( {getAgeRange(SectionInfo.age_range_start,SectionInfo.age_range_end)} ) Some teachers may withdraw your learner. Please message them after enrolling to explain why your learner should participate</p> : ''}
                  </div>
                      )
                  })}
              </div>
              { ClickedAddLearner == true ?
                  <>
                  {formErrors ? <div className="alert alert-danger">{formErrors}</div>:''}
                  <form className="form-horizontal teacher_form mb-3" onSubmit={handleSubmit(childFormSubmit)}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="controls">
                        <input id="first_name" ref={register({required:'This field is required.'})} className="form-control input-large" name="first_name" type="text" placeholder="First Name" />
                      </div>
                      {errors.first_name && <p className="text-danger">{errors.first_name.message}</p>}
                    </div>
                    <div className="col-md-3">
                      <div className="controls">
                        <input id="last_name" ref={register({required:'This field is required.'})} className="form-control input-large" name="last_name" type="text" placeholder="Last Name" />
                      </div>
                      {errors.last_name && <p className="text-danger">{errors.last_name.message}</p>}
                    </div>
                    <div className="col-md-3">
                      <div className="controls">
                        <div className="selectdiv">
                          <select name="age" id="age" ref={register({required:'This field is required.'})} className="form-control">
                            <option value="">Age</option>
                            <option value="3">Age 3</option>
                            <option value="4">Age 4</option>
                            <option value="5">Age 5</option>
                            <option value="6">Age 6</option>
                            <option value="7">Age 7</option>
                            <option value="8">Age 8</option>
                            <option value="9">Age 9</option>
                            <option value="10">Age 10</option>
                            <option value="11">Age 11</option>
                            <option value="12">Age 12</option>
                            <option value="13">Age 13</option>
                            <option value="14">Age 14</option>
                            <option value="15">Age 15</option>
                            <option value="16">Age 16</option>
                            <option value="17">Age 17</option>
                            <option value="18">Age 18</option>
                            <option value="19">Age 19</option>
                          </select>
                        </div>
                      </div>
                      {errors.age && <p className="text-danger">{errors.age.message}</p>}
                    </div>
                    <div className="align-self-center text-center col-md-3">
                        <button type="submit" disabled={IsSubmitting} className="btn btn-primary">+ Add</button>
                        <a className="ml-3 text-warning" onClick={()=>setClickedAddLearner(false)} href="#">Close</a>
                    </div>
                  </div>
                  </form>
                  </>
              :''}
              { ClickedAddLearner == false ?
              <div className="row">
                <div className="col-md-12">
                  <div className="controls">
                    <a href="#" onClick={()=>{setClickedAddLearner(true)}} className="add_learners">+ Add another learners</a>
                  </div>
                </div>
              </div>
              :''}
              
              <div className="row">
                <div className="col-md-12">
                  {SectionInfo.allow_teacher_message == 'yes' ? 
                  <div className="controls">
                    <textarea name="class-promoted" onChange={handleInput} placeholder="Message for teacher" className="form-control enroll_text-area" defaultValue={""} />
                  </div>
                  : '' }
                </div>
                <div className="col-md-12">
                    { Childrens && Object.keys(SelectedChildrens).length ? 
                  <>
                  <table className="payout_table enroll_table">
                    <tbody>
                    { Childrens.map((child,index)=>{
                      return (
                          SelectedChildrens.filter((element) => {
                            return element.id === child.id;
                          }).length ?
                      <tr key={index}>
                        <td>{child.first_name+' '+child.last_name} (age {getAge(child.age)})</td>
                        <td>${SectionInfo.final_price} USD</td>
                      </tr>
                      : <></>
                      ) 
                    })}
                    {UsedCouponInfo ?
                        <tr>
                          <td>Coupon {UsedCouponInfo.coupon_code} Applied.</td>
                          <td>Discount {UsedCouponInfo.amount}%</td>
                        </tr>
                      : 
                      ''}
                    <tr>
                        <td><strong>Total</strong></td>
                        <td><strong>{totalPrice} USD</strong></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="row">
                  {/**
                   * Coupon block
                   */}
                  { !showCouponBlock ?
                    <div className="col-md-12">
                      <div className="controls">
                        <a href="#" onClick={()=>{setShowCouponBlock(!showCouponBlock)}} className="add_learners">+ Apply Coupon</a>
                      </div>
                    </div>
                    :
                      <> 
                        <div className="col-md-6">
                          <input type="text" readOnly={isCouponApplied} name="coupon" className="form-control" onChange={handleCouponChnage} value={couponCode} />
                          <p className="text-danger">{couponMessage && typeof(couponMessage.errorMessage =='string') ? couponMessage.errorMessage : ''}</p>
                        </div>

                        <div className="col-md-4 align-self-center">
                          <button disabled={isCouponApplied} onClick={applyCouponAction} className="btn btn-success">Apply Coupon</button>
                        </div>
                      </> 
                    }
                  </div>
                    <p className="font-weight-bold text-success">{couponMessage && typeof(couponMessage.successMessage =='string') ? couponMessage.successMessage : ''}</p>
                    </>
                    : ''}
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-12">
                  {SectionInfo.enroll_cms ? parse(SectionInfo.enroll_cms) : ''}
                </div>
              </div>
              {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
              <div className="row align-items-center">
                <div className="col-md-6">
                  {/* Button */}
                  <div className="control-group">
                    <div className="controls">
                      {/* <button id="confirmsignup" name="confirmsignup" class="btn btn-success register-btn">Enroll</button> */}
                      <button id="confirmsignup" disabled={isDisabledEnroll || Object.keys(SelectedChildrens).length<=0} onClick={handleOnclickEnroll} name="confirmsignup" className="btn btn-success register-btn">Enroll</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  {/* Button */}
                  <div className="control-group">
                    <div className="controls">
                      <button id="confirmsignup" name="confirmsignup" onClick={()=>{props.setEnrollModalShow(false)}}className="btn btn-success register-btn clr_change">Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
 : ''}

    {Object.keys(SelectedChildrens).length ? <CardDetails email={LoggedUserEmail} TeacherMessage={TeacherMessage} CardModalShow={CardModalShow} SelectedChildrens={SelectedChildrens} SectionInfo={SectionInfo} couponInfo={UsedCouponInfo} totalPrice={totalPrice} setCardModalShow={setCardModalShow} /> : ''} 

    {successMessage && <Success setAlertShow={setAlertShow} message={parse(successMessage)} show={alertShow} /> }
        </>
    )

}