import React,{useState,useEffect} from "react";
import axios from 'axios';
import ClassSingleItem from './ClassSingleItem';
import {getUserFavorites} from '../../api/FavoritesApi';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SuggestedClasses(props)
{
    const classId = props.class_id;
    const teacherId = props.teacher_id;
    const teacherName = props.teacher_name;
    const [classItems,setClassItems] = useState({});
    useEffect(() => {
        getTeacherclasses();
        const favorites =  getUserFavorites();
    }, []);
    async function getTeacherclasses() {
        const response = await axios.get("class/teacher-classes",{params:{teacher_id:teacherId}});
        if(!response.data.data){
          alert('Invalid Request');
          return;
        }
        setClassItems(response.data.data.classes);
    }

    return (
        Object.keys(classItems).length > 1 ?
        <section className="trending_section gray_bg">
          <div className="container-fluid cf">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>{teacherName} suggests this class next</h2>
              </div>
            </div>
            <div className="row m-auto">
            { classItems.map((item, index) => { 
            return (
                item.id != classId && 
                        <ClassSingleItem key={index} class={item} />
                )
            })}
            </div>
          </div>
        </section>
        : <></>
    )

}
