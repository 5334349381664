import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import {useHistory} from 'react-router-dom'
import parse from 'html-react-parser';
import { useForm } from "react-hook-form";
import Success from "../../../layout/alert/Success";


export default function TeacherTab(props) {
  const displayTeachVideo = props.displayTeachVideo;
  let history = useHistory();
  const [inputs, setInputs] = useState({});
  const triggerRef = useRef();
  const [formErrors, setFormErrors] = useState(null);      
  const [isSubmitting, setIsSubmitting] = useState(false);  
  const [formEle, setFormEle] = useState(null);  
  const [errorMsgBlock, setErrorMsgBlock] = useState({});  
  const [countries, setCountries] = useState(null);             
  const [introVideo, setIntroVideo] = useState({});          
  const [resume, setResume] = useState({});          
  const [isIntroVideoSelected, setIsIntroVideoSelected] = useState(null);  
  const [successMessage, setSuccessMessage] = useState(null);     
  const { register,handleSubmit,clearErrors,setError,errors } = useForm();
  const [alertShow, setAlertShow] = useState(false);
  
  
  useEffect(() => {
    setCountries(props.Countries);
  },[]);

  const handleOnChangeVideo = (event) => {
    var inputName = event.target.name;
    const file = event.target.files[0];
    if(!file){
        return;
    }
    const fileSize = Math.round((file.size / 1024)); 
        if (fileSize >= 5096) { 
            setError("intro_video",{type:"manual",message:"File too large, please select less then 5MB."});
            setIsIntroVideoSelected(false);
            setIntroVideo({});
        }else{
            clearErrors(['intro_video']);
            setIsIntroVideoSelected(true);
            setIntroVideo({file:file,name:file.name});
        }
    }
    
    //for form inputs 
    const handleInputChange = async (event) => {
        event.persist();
        const input = event.target;
        if(input.type == 'file'){
            const file = input.files[0];
            const fileSize = Math.round((file.size / 1024)); 
            if(input.name == 'resume'){
                var allowedExtensions = /(\.pdf|\.doc|\.docx)$/i;
                if(!allowedExtensions.exec(input.value)){
                    setError("resume",{type:"manual",message:"File extension should be PDF,DOC or DOCX."});
                    return false;
                }else if(fileSize >= 2084){
                    setError("resume",{type:"manual",message:"Resume should not exceeds 2MB."});
                    return false;
                }else{
                    clearErrors(['resume']);
                }
                setResume({file:file,name:file.name});
            }
        }else{
            setInputs(inputs => ({...inputs, [input.name]: input.value}));
        }
   };

  //submission
const formSubmit = (data)=>{
  const formdata = new FormData();   //formdata object
  setIsSubmitting(true);
for ( var key in inputs ) {
  formdata.append(key, inputs[key]);
}

if(Object.keys(introVideo).length){
  formdata.append('introduction_video',introVideo.file,introVideo.name)
}
if(Object.keys(resume).length){
  formdata.append('resume',resume.file,resume.name)
}

var post_url ='teacher/register/teacher';
axios.post(post_url, formdata)
    .then(response => {
        let res = response.data;
        if(res.status && res.status == 'success'){
            setAlertShow(true)
            formEle.reset();
            setInputs({});
            setFormErrors(null);
            setSuccessMessage(res.message);
            setIsSubmitting(false);
        }else{
            let msg_str ='';
            if(typeof(res.message)== 'string'){
                setFormErrors(res.message);
                setIsSubmitting(false);
                errorMsgBlock.focus();
            }else{ 
            Object.keys(res.message).map((ele)=>{
                res.message[ele].map((msg,key) => {
                    console.log(msg)
                    msg_str += msg+'\n';
                    setFormErrors(msg_str);
                    setIsSubmitting(false);
                    errorMsgBlock.focus();
                });
            });
        }
            
        }     
    })
    .catch(error => {
        setIsSubmitting(false);
        console.log(error);
        alert('Internal server error');
    });

};
      return (
        <div className="tabcontent form-box" id="Teacher">
        {successMessage && <Success setAlertShow={setAlertShow} message={parse(successMessage)} show={alertShow} /> }
          <h1>Apply to Teach</h1>
            <Form className="form-horizontal teacher_form" ref={(el) => setFormEle(el)} onSubmit={handleSubmit(formSubmit)}>
            <fieldset>
              {/* Sign Up Form */}
              <div className="step-div">
                <div className="row">
                  <div className="col-md-12">
      <p>It's easy to get started on ActyvateLearning.com. Complete a short application form,
record a brief introduction video, and pass a background check. We don’t require formal
teaching credentials, just passion for the classes you offer. But at this time, we do
require that our teachers and volunteers be residents of the US or Canada.</p>
                    <div className="row">
                      <div className="col-md-4 m-auto">
                        {displayTeachVideo == '1' ? <img className="w-100" src="/assets/img/sample-vid.png"/> : ''}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <div className="teacher_block box">
                <div className="step-div">
                    { formErrors && <div className="alert alert-danger">{formErrors}</div> } 
                </div>
                <h2><span>Step 1:</span> Complete the Teacher form</h2>
                {/* Text input*/}
                <div className="control-group">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="controls">
                        <input id="first_name" name="first_name" ref={register({required:'This field is required.'})} onChange={handleInputChange} className="form-control input-large" type="text" placeholder="First Name" />
                        {errors.first_name && <p className="text-danger">{errors.first_name.message}</p>}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="controls">
                      <input id="last_name" name="last_name" ref={register({required:'This field is required.'})} onChange={handleInputChange} className="form-control input-large" type="text" placeholder="Last Name" />
                        {errors.last_name && <p className="text-danger">{errors.last_name.message}</p>}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="controls">
                        <input id="nick_name" name="nick_name" className="form-control input-large" type="text" placeholder="Preferred name to be displayed (optional)" />
                        {errors.nick_name && <p className="text-danger">{errors.nick_name.message}</p>}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Text input*/}
                <div className="control-group">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="controls">
                        <input id="email" name="email"  ref={register({required:'This field is required.'})} onChange={handleInputChange} className="form-control input-large" type="text" placeholder="Email" />
                        {errors.email && <p className="text-danger">{errors.email.message}</p>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="controls">
                        <input id="phone" name="phone" className="form-control input-large" type="text" placeholder="Phone Number"  ref={register} onChange={handleInputChange} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="control-group">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="controls">
                        <input type="radio" className="hidden-input-file" ref={(ele)=>setErrorMsgBlock(ele)}/>
                        <input id="city" className="form-control input-large" name="city" ref={register({required:'This field is required.'})} onChange={handleInputChange} type="text" placeholder="City" />
                      </div>
                      {errors.city && <p className="text-danger">{errors.city.message}</p>}
                    </div>
                    <div className="col-md-4">
                      <div className="controls">
                        <input id="state" className="form-control input-large" name="state" type="text" placeholder="State" ref={register({required:'This field is required.'})} onChange={handleInputChange}  />
                      </div>
                      {errors.state && <p className="text-danger">{errors.state.message}</p>}
                    </div>
                    <div className="col-md-4">
                      <div className="controls">
                        <select id="country_id" className="form-control input-large" name="country_id" placeholder="Country" ref={register({required:'This field is required.'})} onChange={handleInputChange} >
                        <option value="">Country</option>  
                            {countries && countries.map((country,index)=>{
                              return <option key={index} value={country.id}>{country.country_name}</option>
                            })}
                        </select>
                        {errors.country_id && <p className="text-danger">{errors.country_id.message}</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="control-group">
                  <label className="label_info" htmlFor="teacher">Education </label><br clear="all" />
                  <div className="row">
                    <div className="col-md-4">
                      <div className="controls">
                        <input id="education1_school" className="form-control input-large" name="education1_school" type="text" ref={register} onChange={handleInputChange}  placeholder="School/Program (Optional)"  />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="controls">
                        <input id="education1_degree" className="form-control input-large" ref={register} onChange={handleInputChange} name="education1_degree" type="text" placeholder="Degree (Optional)"  />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="controls">
                        <input id="education1_year" className="form-control input-large" ref={register} onChange={handleInputChange} name="education1_year" type="text" placeholder="Graduation Year (Optional)" />
                      </div>
                    </div>
                  </div>
                  {/* <label class="label_info" for="teacher">Education 2: </label><br clear="all"> */}
                  <div className="row">
                    <div className="col-md-4">
                      <div className="controls">
                        <input id="wifi" className="form-control input-large" ref={register} onChange={handleInputChange} name="education2_school" type="text" placeholder="School/Program (Optional)" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="controls">
                        <input id="wifi" className="form-control input-large" ref={register} onChange={handleInputChange} name="education2_degree" type="text" placeholder="Degree (Optional)" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="controls">
                        <input id="wifi" className="form-control input-large" ref={register} onChange={handleInputChange} name="education2_year" type="text" placeholder="Graduation Year (Optional)" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="controls">
                        <input id="linkedin" ref={register} onChange={handleInputChange} name="linkedin" className="form-control input-large" type="text" placeholder="LinkedIn (optional)" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="control-group">
                <label className="label_info" htmlFor="teacher">Referred by </label><br clear="all" />
                <div className="row">
                  <div className="col-md-4">
                    <div className="controls">
                      <input id="referred_firstname" name="referred_firstname" className="form-control input-large" type="text" ref={register} onChange={handleInputChange} placeholder="First Name"  />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="controls">
                      <input id="referred_lastname" name="referred_lastname" className="form-control input-large" ref={register} onChange={handleInputChange} type="text" placeholder="Last Name (optional)" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="controls">
                      <input id="referred_email" name="referred_email" className="form-control input-large" ref={register} onChange={handleInputChange} type="text" placeholder="Email (optional)" />
                    </div>
                  </div>
                </div>
              </div>
                <h2><span>Step 2:</span> Share your skills and Interests</h2>
                <div className="control-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="label_info" htmlFor="teacher">What topics are you passionate about teaching on Actyvatelearning.com and why?</label> <br />
                      <div className="controls">
                        <textarea name="passion"  ref={register} onChange={handleInputChange} className="form-control text-area" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="label_info" htmlFor="teacher">How do you keep your students engaged and motivated? </label> <br />
                      <div className="controls">
                        <textarea name="engaged_motivated" ref={register} onChange={handleInputChange} className="form-control text-area" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="label_info" htmlFor="teacher">Describe how you promote diversity, equity, and inclusion in your classes? </label> <br />
                      <div className="controls">
                        <textarea name="diversity_equity" ref={register} onChange={handleInputChange} className="form-control text-area" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="label_info" htmlFor="teacher">Any additional information you want to share?</label>  <br />
                      <div className="controls">
                        <textarea name="additional_information" ref={register} onChange={handleInputChange} className="form-control text-area" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="control-group">
                  <label className="label_info" htmlFor="teacher">Are you currently teaching, Where? (Optional)</label><br clear="all" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="controls">
                        <input id="current_school" className="form-control input-large" name="current_school" ref={register} onChange={handleInputChange} type="text" placeholder="Name of school" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="controls">
                        <input id="current_school_city" className="form-control input-large" name="current_school_city" ref={register} onChange={handleInputChange} type="text" placeholder="City" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="controls">
                        <input id="current_school_state" className="form-control input-large" name="current_school_state" ref={register} onChange={handleInputChange} type="text" placeholder="State" />
                      </div>
                    </div>
                  </div>
                  <label className="label_info" htmlFor="teacher">List of schools previously taught? (Optional)</label><br clear="all" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="controls">
                        <input id="previous_school1" className="form-control input-large" name="previous_school1" ref={register} onChange={handleInputChange} type="text" placeholder="Name of school" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="controls">
                        <input id="previous_school1_city" className="form-control input-large" name="previous_school1_city" ref={register} onChange={handleInputChange}  type="text" placeholder="City"  />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="controls">
                        <input id="previous_school1_state" className="form-control input-large" name="previous_school1_state" ref={register} onChange={handleInputChange}  type="text" placeholder="State" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="controls">
                        <input id="previous_school2" className="form-control input-large" name="previous_school2" ref={register} onChange={handleInputChange} type="text" placeholder="Name of school" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="controls">
                        <input id="previous_school2_city" className="form-control input-large" name="previous_school2_city" ref={register} onChange={handleInputChange} type="text" placeholder="City" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="controls">
                        <input id="previous_school2_state" className="form-control input-large" name="previous_school2_state" ref={register} onChange={handleInputChange} type="text" placeholder="State" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label className="label_info" htmlFor="teacher">Resume</label> <br />
                      <div className="controls">
                        <input id="resume" name="resume" accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" ref={register({required:'This field is required.'})} onChange={handleInputChange} className="form-control input-large resume-input" type="file"  />
                        {errors.resume && <p className="text-danger">{errors.resume.message}</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h2><span>Step 3:</span> Introduce yourself</h2>
                <div className="control-group">
                  <label className="label_info" htmlFor="teacher">Record a 3-5 minute video where you teach a topic you described above. Show us how you
will convey an authentic, supportive, and enthusiastic personality. And don’t worry, these
videos will remain private and only be viewed by our team so we can learn a little more
about you and your teaching style.
                  </label>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="controls">
                        <label className="btn btn-success register-btn" htmlFor="intro_video">Submit Video</label>
                        <input type="file" name="intro_video" id="intro_video" accept="video/*" onChange={handleOnChangeVideo} className="hidden-input-file"/>
                      </div>
                        {isIntroVideoSelected && <p className="text-success">Selected video: {introVideo.name?introVideo.name:''}</p>}
                        {errors.intro_video && <p className="text-danger">{errors.intro_video.message}</p>}
                    </div>
                    <div className="col-md-8">
                      <div className="controls arrow_img">
                        <span><strong>You should:</strong></span>
                        <p> Show us your online teaching space. </p>
                        <p> Make sure your personality shines through.</p>
                        <p> Show us how you’ll keep students actively engaged.</p>
                        <p> Teach us something that you are excited about.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="control-group" id="volunteer">
                <div className="controls">
                  <label className="radio inline" htmlFor="terms">
                  </label></div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="control-group">
                        {isSubmitting && <span>Please wait the form is submitting...</span>}
                    <div className="controls">
                      <button id="confirmsignup" name="confirmsignup" disabled={isSubmitting} className="btn btn-success register-btn">Submit Application</button>
                    </div>
                  </div>
                </div>
                
              </div>
            </fieldset>
          </Form>
        </div>
      );
    }