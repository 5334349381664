import React,{useState,useEffect} from "react";
import axios from 'axios';
import ClassSingleItem from './../../classes/blocks/ClassSingleItem';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import {IsAuthenticated} from '../../auth/Athentication'
import "slick-carousel/slick/slick-theme.css";

export default function ClassGrid(props)
{
    const [classItems,setClassItems] = useState([]);
    const [showLoadMore,setShowLoadMore] = useState(true);
    const [loadingBtnText,setLoadingBtnText] = useState('Load More');
    const [Limit,setLimit] = useState(4);
    const [TotalRecords,setTotalRecords] = useState(0);
    const [Skip,setSkip] = useState(0);

    useEffect(() => {
        get_classes();
    }, []);

    useEffect(() => {
      console.log("Total: "+TotalRecords+', Skip='+Skip)
      if(Skip && Skip>=TotalRecords){
        setShowLoadMore(false);
      }
    }, [Skip,TotalRecords]);

    async function get_classes() {
        const limit='/'+Limit;
        let response;
        if(IsAuthenticated()){
            response = await axios.get("class/get-user-classes"+limit,{params:{skip:Skip,type:'interested'}});
        }else{
          response = await axios.get("class/get-classes"+limit,{params:{skip:Skip,type:'all'}});
        }
        if(!response.data.data){
          alert('Invalid Request');
          return;
        }
        if(Skip == 0){
          console.log('total'+response.data.data.total);
          setTotalRecords(response.data.data.total);
          setClassItems(response.data.data.classes);          
        }else{
          setClassItems([...classItems, ...response.data.data.classes])
        }
        setSkip(Skip+Limit);
        setLoadingBtnText('Load More')
    }
    
    const handleLoadMore = () =>{
      setLoadingBtnText('Please wait...');
      get_classes();
    }

    return (
        Object.keys(classItems).length ?
        <section className="trending_section">
          <div className="container-fluid cf">
            <div className="row">
                <div className="col-md-12">
                  <h2>Classes to get you started</h2>
                </div>
            </div>
          <div className="row">
            { classItems.map((item, index) => { 
            return ( 
               <ClassSingleItem key={index} class={item} />)
            })}
            </div>
          </div>
          {showLoadMore==true ?
          <div className="row">
            <div className="col-md-12 text-center">
            <button onClick={handleLoadMore} className="get_button">{loadingBtnText}</button>
            </div>
          </div>
          :''}
        </section>
        : <></>
    )
}