import React, { useState,useEffect }  from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import {getUserFavorites} from './components/api/FavoritesApi'
import Axios from "axios";
import GlobalContext from './GlobalContext';


import PrivateRoute from './components/auth/PrivateRoute'
import PublicRoute from './components/auth/PublicRoute'

import Home from './components/home/Home'
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import BodyContainer from './components/layout/BodyContainer'

import TeacherDashboard from './components/teacher/TeacherDashboard';
import AgeSelection from './components/page/user/age-selection/AgeSelection'
import UserSchedule from './components/page/user/schedule/UserSchedule'
import QuestionnairePage from './components/page/user/questionnaire/QuestionnairePage'
import AddNewClassStep2 from './components/teacher/classes/AddNewClassStep2';
import AddNewClassStep1 from './components/teacher/classes/AddNewClassStep1';
import ViewClassDetails from './components/classes/ViewClassDetails';
import ChangePassword from './components/users/ChangePassword';
import ConvertToTeacher from './components/page/convert-to-teacher/ConvertToTeacher';
import EmailSettings from './components/users/EmailSettings';
import ForgotPassword from './components/users/ForgotPassword';
import EmailConfirmation from './components/users/EmailConfirmation';

import TeachPage from './components/page/teach/TeachPage';
import PurchasesPage from './components/page/user/purchases/PurchasesPage';
import UserProfile from './components/page/user/profile/UserProfile';
import UserSettings from './components/page/user/settings/UserSettings';

import LoadingIcon from './components/helpers/LoadingIcon'
import ScrollToTop from './components/helpers/ScrollToTop'
import "bootstrap/dist/css/bootstrap.min.css";
import './css/style.css'

import SearchPage from './components/page/search/SearchPage';
import AboutPage from './components/page/about/AboutPage';
import BlogPage from './components/page/blog/BlogPage';
import ContactPage from './components/page/contact/ContactPage';
import FaqPage from './components/page/faq/FaqPage';
import ModerateTeacher from './components/page/moderate-teacher/ModerateTeacher';
import FriendsPage from './components/page/friends-group/FriendsPage';
import FavouriteClasses from './components/page/favourite-classes/FavouriteClasses';


import * as serviceWorker from './serviceWorker';

import SectionsMain from './components/teacher/classes/sections/SectionsMain';
import SectionZoomClassRoom from './components/teacher/classes/sections/SectionZoomClassRoom';
import MyChannelsPage from './components/page/my-channels/MyChannelsPage';
import TermsPage from './components/page/terms/TermsPage';
import SignUpPage from './components/page/signup/SignUpPage';
import UserDashboard from './components/page/user/user-dashboard/UserDashboard';

//strip
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import SetNewPassword from './components/users/SetNewPassword';
import CmsPage from './components/page/cms/CmsPage';
import MyClassRoom from './components/page/user/classroom/MyClassRoom';
import HelpPage from './components/page/help/HelpPage';

function Main() { 
  Axios.defaults.baseURL = "https://tunedin.webstaging.info/admin/public/api";

  Axios.defaults.headers.common['Authorization'] =`Bearer ${localStorage.getItem("tunedInappToken")}`;
  Axios.defaults.headers.common['Content-Type'] =`multipart/form-data`
  
  const [loggedIn, setLoggedIn] = useState(
    Boolean(localStorage.getItem("tunedInappToken"))
  );

  const [isModelBackdropOpen, setIsModelBackdropOpen] = useState(false);
  const [isInnerPage, setIsInnerPage] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [myFavorites, setMyFavorites] = useState({});
  const [loginModelOpen, setLoginModelOpen] = useState(false);

  const stripePromise = loadStripe("pk_test_LouhovbieCsIpMp2Kuj8ndYl");
  useEffect(() => {
    getMyFavorites();
    handlePageLoader();
  }, []);
  function handlePageLoader()
  {
    const preLoader = setTimeout(() => setIsPageLoaded(true), 3000);
  }

  
  async function getMyFavorites()
  {
    if(loggedIn){
      const favorites = await getUserFavorites();
      setMyFavorites(favorites);
    }
  }

  return (  
    <GlobalContext.Provider value={{setIsInnerPage,loggedIn,setLoggedIn,isModelBackdropOpen,setIsModelBackdropOpen,loginModelOpen,setLoginModelOpen,myFavorites,setMyFavorites}} > 
    <Elements stripe={stripePromise}>
    <BrowserRouter>
      <ScrollToTop>
    {isPageLoaded == false ? 
      <div className="pre-load-main">
        <LoadingIcon />
      </div> 
    : ''}
      <BodyContainer isInnerPage={isInnerPage}>
        {isInnerPage}
      <Header
        loggedIn={loggedIn}
      />
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/class-details/:class_title/:classId" exact>
              <ViewClassDetails/>
        </Route>
        <Route path="/teach" exact>
          <TeachPage/>
        </Route>
        {/* <PublicRoute> */}
        <Route path="/forgot-password" exact>
          <ForgotPassword/>
        </Route>
        {/* </PublicRoute> */}
        <Route path="/user-email-confirmation/:token" exact>
          <EmailConfirmation/>
        </Route>
        <Route path="/user/set-new-password/:token" exact>
          <SetNewPassword/>
        </Route>
        <Route path="/search" exact>
          <SearchPage/>
        </Route>
        <Route path="/about" exact>
          <AboutPage />
        </Route>
        <Route path="/blog" exact>
          <BlogPage />
        </Route>
        <Route path="/safety" exact>
          <CmsPage />
        </Route>
        <Route path="/terms" exact>
          <TermsPage />
        </Route>
        <Route path="/privacy-policy" exact>
          <CmsPage />
        </Route>
        <Route path="/my-channels" exact>
          <MyChannelsPage />
        </Route>
        <Route path="/contact" exact>
          <ContactPage />
        </Route>
        <Route path="/faq" exact>
          <FaqPage />
        </Route>
        <Route path="/teachers/:teacherId" exact>
          <ModerateTeacher/>
        </Route>
        <Route path="/help" exact>
          <HelpPage />
        </Route>
        <Route path="/diversity-equity-inclusion" exact>
          <CmsPage />
        </Route>
        <Route path="/partners" exact>
          <CmsPage />
        </Route>
        <Route path="/volunteers" exact>
          <CmsPage />
        </Route>
        <Route path="/cancellation-policy" exact>
          <CmsPage />
        </Route>
        <Route path="/favourite-classes" exact>
          <FavouriteClasses />
        </Route>
        <Route path="/signup" exact>
            <SignUpPage />
        </Route>
        
        
        <PrivateRoute>
            <Route path="/account-age-selection" exact>
                  <AgeSelection/>
            </Route>
            <Route path="/account-interest-quesionnaire" exact>
                  <QuestionnairePage/>
            </Route>
            {/*  User routes */}
            <Route path="/user-dashboard" exact>
                  <UserDashboard/>
            </Route>
            <Route path="/user-schedule" exact>
                  <UserSchedule/>
            </Route>
            <Route path="/purchases" exact>
              <PurchasesPage />
            </Route>
            <Route path="/user-profile" exact>
              <UserProfile />
            </Route>
            <Route path="/user-settings" exact>
              <UserSettings />
            </Route>
            {/*  User routes */}
            <Route path="/teacher-dashboard" exact>
                  <TeacherDashboard/>
            </Route>
            <Route path="/add-class-step1" exact>
                <AddNewClassStep1/>
            </Route>
            <Route path="/class/:reqType/:title" exact>
                <AddNewClassStep2 />
            </Route>              
            <Route path="/section/:class_id/:section_id?" exact>
                <SectionsMain/>
            </Route>
            <Route path="/classroom-zoom-meting-start/:zoom_meeting_id/:meeting_id" exact>
                <SectionZoomClassRoom/>
            </Route>
            <Route path="/change-password" exact>
                <ChangePassword />
            </Route>
            <Route path="/teacher/convert-to-teacher" exact>
                <ConvertToTeacher />
            </Route>
            <Route path="/teacher/email-settings" exact>
                <EmailSettings />
            </Route>
            <Route path="/friends-group" exact>
              <FriendsPage />
            </Route>
            <Route path="/user/class-room" exact>
              <MyClassRoom />
            </Route>
            <Route path="/user/email-settings" exact>
                <EmailSettings />
            </Route>
        </PrivateRoute>
          {/* <Route path="/class-details/:class_title/:classId" exact>
          <Elements stripe={stripePromise}>
              <ViewClassDetails/>
          </Elements>
          </Route> */}

      </Switch>
      <Footer />
      <div
        className={
          "modal-backdrop fade " +
          (isModelBackdropOpen == true ? "show" : "hide")
        }
        ></div>
      </BodyContainer>
      </ScrollToTop>
    </BrowserRouter>
    </Elements>
    </GlobalContext.Provider>
  );
}

ReactDOM.render(
  <Main />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
