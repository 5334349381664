import React,{useState,useEffect,useContext} from 'react';
import axios from 'axios';
import { Link,useParams } from 'react-router-dom';
import NotFoundPage from '../layout/NotFoundPage'
import AvailableTimes from './blocks/AvailablTimes';
import TeacherOtherClasses from './blocks/TeacherOtherClasses';
import SuggestedClasses from './blocks/SuggestedClasses';
import ChannelClasses from './blocks/ChannelClasses';
import TeacherInfo from './blocks/TeacherInfo';
import {UserRole,IsAuthenticated,getUserEmail} from '../auth/Athentication'
import Accordion from 'react-bootstrap/Accordion'
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ModalVideo from 'react-modal-video'
import { useForm } from "react-hook-form";
import LoadingIcon from '../helpers/LoadingIcon';
import './../../css/modal-video.css';
import './../../css/view-class-details.css';
import './../../css/icofont.min.css';
import parse from 'html-react-parser';
import Success from '../layout/alert/Success'
import GlobalContext from './../../GlobalContext';
import {isFovoriteClass,addToFavorites,removeFavorites} from '../api/FavoritesApi'
export default function ViewClassDetails()
{
  const { class_title, classId } = useParams();
  const [classID,setClassID] = useState(classId);
  const {setIsInnerPage,myFavorites,setMyFavorites} = useContext(GlobalContext);
  setIsInnerPage(false);
  const [classPhotoSrc,setClassPhotoSrc] = useState(null);
  const [classInfo,setClassInfo] = useState({});
  const [teacherInfo,setTeacherInfo] = useState({});
  const [isInvalid,setIsInvalid] = useState(false);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const { register,handleSubmit} = useForm();
  const [reqFormError, setReqFormError] = useState(null);
  const [reqFormSucess, setReqFormSucess] = useState(null);
  const [reqformSubmitting, setReqformSubmitting] = useState(false);
  const [alertShow, setAlertShow] = useState(false);
  
    const [reqTimeModalShow, setReqTimeModalShow] = useState(false);
    const handleReqTimeModalClose = () => setReqTimeModalShow(false);
    const handleReqTimeModalShow = () => setReqTimeModalShow(true); 
  
  useEffect(() => {
    getClassDetails();
  }, [classId]);
  
  useEffect(() => {
  if(UserRole() && classId){
      trackRecentlyViewed();
    }
  }, []);
  
  function trackRecentlyViewed()
  {
    const trackClass = axios.post('class/track-recently-viewed-class',{'class_id':classId});
  }

  async function getTeacherDetails()
  {
    const res = await axios.get("class/class-tacher-deatails/"+classId);
      if(typeof(res.data.status) !='undefined' && res.data.status == 'success'){
          var response = res.data.data;
          setTeacherInfo(response);
        }else{
          setIsInvalid(true);
        }
  }
  async function getClassDetails()
  {
    const classInfo = await axios.get("class/class_deatails/"+classId);
      if(typeof(classInfo.data.status) !='undefined' && classInfo.data.status == 'success'){
          var response = classInfo.data.data;
          setClassID(response.id);
          setClassInfo(response);
          setClassPhotoSrc(response.classPhotoSrc);
          getTeacherDetails();
        }else{
          setIsInvalid(true);
        }
      }
 
// request another time submission
const reqAnotherTimeformSubmit = (data)=>{
  // setReqformSubmitting(true)
  setReqFormError(null);
  data.call_id =classInfo.id;  
  data.teacher_user_id =teacherInfo.user_id;  
  axios.post('class/post-request-another-time',data).then(res=>{
    setReqformSubmitting(false)
    const result = res.data;
    if(result.status != 'success'){
      setReqFormError(result.message);
    }else{
      setReqFormSucess(result.message);
      setAlertShow(true);
      handleReqTimeModalClose();
    }
  }).catch(err=>{
    alert('form submission faild.');
    return;
  })
}

    function ContextAwareToggle({ children, eventKey, callback }) {
      const currentEventKey = useContext(AccordionContext);
    
      const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
      );
    
      const isCurrentEventKey = currentEventKey === eventKey;
    
      return (
        <button
          type="button"
          className="btn btn-link"
          style={{ backgroundColor: isCurrentEventKey ? '#FFC107' : '#6464AD' }}
          onClick={decoratedOnClick}
        >
          {children}
        </button>
      );
    }

    async function handleFavorite(event,itemId,action)
    {
      console.log(myFavorites);
      event.preventDefault();
      if(action=='add'){
        const res = await addToFavorites(itemId);
        if(res){
          const obj = {call_id:itemId,id:res};
          setMyFavorites([...myFavorites,obj]);
        }
      }else{
        const res = await removeFavorites(itemId);
        if(res){
            setMyFavorites(myFavorites =>myFavorites.filter(fav => fav.call_id !== itemId));
        }
      }
    }
      
      return (
        isInvalid ? <NotFoundPage /> :
        (Object.keys(classInfo).length <= 0 || Object.keys(teacherInfo).length <= 0 ?<div key={1} className="w-100 p-5 m-auto"> <LoadingIcon /></div>:
        <div>
        <section className="class-details-sec">
          <div className="container-fluid cf">
            <div className="row fixed-div">
              <div className="col-md-12">
                <div className="fixed_sidebar">
                  <div className="sidebar__inner">
                    <div className="row">
                      <div className="col-md-4 class-detail-img">
                        <img src={classPhotoSrc} className="class-main-img" />
                        {classInfo.pricing.pricing_type =='Free' && <div className="nocost_span">Included at no cost</div>}
                        {classInfo.channel.channel_type =='exclusive' && <div className="free_tag">Exclusive</div>}
                      </div>
                      <div className="col-md-8 class-left-cnt">
                        <div className="row">
                          <div className="col-md-12">
                            <h3 className="channel_info"> {classInfo.channel_name}</h3>
                            <h1>{classInfo.title}</h1>
                          </div>
                        </div>
                        <div className="class-report">
                          <ul>
                            <li><p><i className="icofont-users" /> Registered for call: <span>{classInfo.enrolled_count?classInfo.enrolled_count:'0'}</span></p></li>
                            {/* <li><p><i className="icofont-eye-alt"></i> Views: <span>75</span></p></li> */}
                            <li><p><i className="icofont-checked" /> Completed this class: <span>{classInfo.completed_meetings?classInfo.completed_meetings:'0'}</span></p></li>
                          </ul>
                        </div>
                        <p>{classInfo.short_description}</p>
                        <p><a href="#Description" className="readmore_link">Read More</a></p>
                        <div className="teacher-block1">
                          <div className="tb-row">
                            <div className="tb-cell">
                              <img className="img-responsive teacher-img" src={teacherInfo.teacher_photo} />
                            </div>
                            <div className="tb-cell text-left pd-left">
                              <p><Link to={"/teachers/"+teacherInfo.user.unique_id}><strong>{teacherInfo.teacher_name}</strong></Link></p>
                            </div>
                          </div>
                          {/* <p>Passionate, Caring, and Award-Winning Certified Teacher</p> */}
                          <ul>
                            <li><i className="bx bxs-star" /></li>
                            <li><i className="bx bxs-star" /></li>
                            <li><i className="bx bxs-star" /></li>
                            <li><i className="bx bxs-star" /></li>
                            <li><i className="bx bxs-star" /></li>
                            <li><span>{teacherInfo.reviews_count} total reviews</span></li>
                          </ul>
                        </div>
                        <ul className="connect_ul">
                          <li><a href="#Available" className="active"><img src="/assets/img/schedule.svg" className="icon-size" /> See Class Schedule</a></li>
                          { IsAuthenticated() ? 
                            isFovoriteClass(myFavorites,classInfo.id) ? <li><a onClick={(event)=>handleFavorite(event,classInfo.id,'remove')} href="#"><img src="/assets/img/heart-filled.svg" /> Saved</a></li> 
                            : <li><a onClick={(event)=>handleFavorite(event,classInfo.id,'add')} href="#"><img src="/assets/img/save.svg" /> Save</a></li>
                        :''}

                          <li><a href="#"><img src="/assets/img/share.svg" className="icon-size" /> Share</a></li>
                          {classInfo.class_video ? <li><a onClick={()=>{setIsVideoOpen(true)}} ><img src="/assets/img/play-button.svg" className="icon-size" /> Class Video</a></li> : ''}
                        </ul>
                      </div>
                    </div>
                    <div className="class-table">
                      <div className="row">
                        <ul className="class-table-ul">
                          <li>
                            <div className="tb-row">
                              <div className="tb-cell">
                                <p><i className="icofont-people" /></p>
                              </div>
                              <div className="tb-cell text-left pd-left">
                                <p>Age Range <span>{parseInt(classInfo.age_range_start) > 19 ? 'Parents' :classInfo.age_range_start+'-'+classInfo.age_range_end}</span></p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="tb-row">
                              <div className="tb-cell">
                                <p><i className="icofont-mic" /></p>
                              </div>
                              <div className="tb-cell text-left pd-left">
                                <p className="euqal-p">Class is taught <span>{classInfo.language_name}</span></p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="tb-row">
                              <div className="tb-cell">
                                <p><i className="icofont-dollar" /></p>
                              </div>
                              <div className="tb-cell text-left pd-left">
                                <p>Price Per Learner 
                                    <span>{classInfo.pricing_type == 'Free'? 'Included at no cost': 
                                    <>
                                    {classInfo.pricing_type == 'Discounted' ? <span className="cross">${classInfo.actual_price}</span>:''} ${classInfo.price}
                                    </>}
                                    </span>
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="tb-row">
                              <div className="tb-cell">
                                <p><i className="icofont-users-social" /></p>
                              </div>
                              <div className="tb-cell text-left pd-left">
                                <p>Class Size <span>{classInfo.min_guests+'-'+classInfo.max_guests} Kids</span></p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="tb-row">
                              <div className="tb-cell">
                                <p><i className="icofont-headphone-alt-1" /></p>
                              </div>
                              <div className="tb-cell text-left pd-left">
                                <p>Call Length <span> {classInfo.length_name}</span></p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="tb-row">
                              <div className="tb-cell">
                                <p><i className="icofont-network-tower" /></p>
                              </div>
                              <div className="tb-cell text-left pd-left">
                                <p className="euqal-p">Class Frequency <span>{classInfo.frequency.no_of_weeks} Week{parseInt(classInfo.frequency.no_of_weeks)>1?'s':''}, {classInfo.frequency.calls_in_week}x Week</span></p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <AvailableTimes class_id={classID} />
                    {UserRole() && UserRole()!='teacher' ?
                    <div className="col-md-12 text-center">
                        <p><strong>Would a different time be better for your schedule?</strong></p>
                        <a className="get_button request_btn" onClick={handleReqTimeModalShow}>Request Another Time</a>
                    </div>
                    :<></> }
                    <div className="col-md-12 accordion_sec">
                        <Accordion defaultActiveKey="0">
                          <Card id="Description">
                            <Card.Header>
                              <ContextAwareToggle as={Button} variant="link" eventKey="0" >Description</ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>{parse(classInfo.description)}</Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        {classInfo.asignments &&
                          <Card>
                            <Card.Header>
                              <ContextAwareToggle as={Button} variant="link" eventKey="2" >Assignments</ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                              <Card.Body>{parse(classInfo.asignments)}</Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          }
                        {classInfo.learning_objective &&
                          <Card>
                            <Card.Header>
                              <ContextAwareToggle as={Button} variant="link" eventKey="3" >Learning Objective</ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                              <Card.Body>{parse(classInfo.learning_objective)}</Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          }
                        {classInfo.time_commitments &&
                          <Card>
                            <Card.Header>
                              <ContextAwareToggle as={Button} variant="link" eventKey="4" >Time commitments</ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="4">
                              <Card.Body>{parse(classInfo.time_commitments)}</Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          }
                        {classInfo.supply_required &&
                          <Card>
                            <Card.Header>
                              <ContextAwareToggle as={Button} variant="link" eventKey="5" >Supplies</ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="5">
                              <Card.Body>{parse(classInfo.supply_required)}</Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          }
                          {classInfo.certificate_program &&
                          <Card>
                            <Card.Header>
                              <ContextAwareToggle as={Button} variant="link" eventKey="6" >Certificate Program Transfer Credits</ContextAwareToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="6">
                              <Card.Body>{classInfo.certificate_program}
                                {classInfo.certificate_image ? <p><img src={classInfo.certificate_image} className="img-responsiv" /></p> : ''}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card> }
                        </Accordion> 
                      </div>
                  </div>
                </div>
              </div>
              <TeacherInfo teacherInfo={teacherInfo} classInfo={classInfo} />
            </div>
          </div>
        </section>
        
        <SuggestedClasses teacher_name={teacherInfo.teacher_name} teacher_id={teacherInfo.user_id} class_id = {classID} />
        <TeacherOtherClasses teacher_name={teacherInfo.teacher_name} teacher_id={teacherInfo.user_id} class_id = {classID} />
        <ChannelClasses teacher_name={teacherInfo.teacher_name} channel_id={classInfo.channel_id} class_id = {classID} />

        {reqFormSucess && alertShow ? <Success setAlertShow={setAlertShow } show={alertShow} message={reqFormSucess} /> : ''}                    
        <Modal dialogClassName="login_model" show={reqTimeModalShow} onHide={handleReqTimeModalClose}>
          <Modal.Header closeButton>
              <div className="img-box  align-items-center">
                  <img src={require('../../images/icon-logo.svg')} />
              </div>
          </Modal.Header>
          <Modal.Body>
                <div className="form-box">
                  <h3>Request another time</h3>
                  <p>Send a message to the teacher telling them what times would work best for you.</p> 
                  <div className="row">
                    <div className="col-md-12">
                          {reqFormError ? <div className="alert alert-danger">{reqFormError}</div> : ''}
                      <form className="form-horizontal login_model" onSubmit={handleSubmit(reqAnotherTimeformSubmit)}>
                        <fieldset>
                        <table className="table">
                          <tbody>
                            <tr>
                              <td></td>
                              <td>Mon</td>
                              <td>Tue</td>
                              <td>Wed</td>
                              <td>Thu</td>
                              <td>Fri</td>
                              <td>Sat</td>
                              <td>Sun</td>
                            </tr>
                            <tr>
                              <td>8am - 12pm</td>
                              <td><input type="checkbox" value="Monday" name="morningTimes" ref={register} /></td>
                              <td><input type="checkbox" value="Tuesday" name="morningTimes" ref={register} /></td>
                              <td><input type="checkbox" value="Wednessday" name="morningTimes" ref={register} /></td>
                              <td><input type="checkbox" value="Thursday" name="morningTimes" ref={register}/></td>
                              <td><input type="checkbox" value="Friday" name="morningTimes" ref={register}/></td>
                              <td><input type="checkbox" value="Saturday" name="morningTimes" ref={register}/></td>
                              <td><input type="checkbox" value="Sunday" name="morningTimes" ref={register}/></td>
                            </tr>
                            <tr>
                              <td>12pm - 4pm</td>
                              <td><input type="checkbox" value="Monday" name="noonTimes" ref={register} /></td>
                              <td><input type="checkbox" value="Tuesday" name="noonTimes" ref={register} /></td>
                              <td><input type="checkbox" value="Wednessday" name="noonTimes" ref={register} /></td>
                              <td><input type="checkbox" value="Thursday" name="noonTimes" ref={register}/></td>
                              <td><input type="checkbox" value="Friday" name="noonTimes" ref={register}/></td>
                              <td><input type="checkbox" value="Saturday" name="noonTimes" ref={register}/></td>
                              <td><input type="checkbox" value="Sunday" name="noonTimes" ref={register}/></td>
                            </tr>
                            <tr>
                              <td>4pm - 8pm</td>
                              <td><input type="checkbox" value="Monday" name="eveningTimes" ref={register} /></td>
                              <td><input type="checkbox" value="Tuesday" name="eveningTimes" ref={register} /></td>
                              <td><input type="checkbox" value="Wednessday" name="eveningTimes" ref={register} /></td>
                              <td><input type="checkbox" value="Thursday" name="eveningTimes" ref={register}/></td>
                              <td><input type="checkbox" value="Friday" name="eveningTimes" ref={register}/></td>
                              <td><input type="checkbox" value="Saturday" name="eveningTimes" ref={register}/></td>
                              <td><input type="checkbox" value="Sunday" name="eveningTimes" ref={register}/></td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="control-group mt-2 mb-2">
                          <div className="controls">
                            <textarea name="message" ref={register} placeholder="Write message here" id="" className="form-control"></textarea>
                          </div>
                        </div>
                          <p>Responses will go to {getUserEmail()}</p>
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <button disabled={reqformSubmitting} className="btn btn-success btn-block mb-2">Send request</button>
                            <button type="button" className="btn btn-default btn-block" onClick={handleReqTimeModalClose}>Cancel</button>
                          </div>
                        </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </div>
                </Modal.Body>
            </Modal>

        <ModalVideo channel='custom' isOpen={isVideoOpen} url={classInfo.class_video} onClose={() =>setIsVideoOpen(false)} />                                 
      </div>
    )
    )
}