import React,{useState,useEffect} from "react";
import axios from 'axios';
import FeaturedTeacherItem from './FeaturedTeacherItem';
import {Link} from 'react-router-dom';

function FeaturedTeachers() {

  const [teachers,setTeachers] = useState({});
  useEffect(() => {
    getFeaturedTeachers();
  }, []);

  async function getFeaturedTeachers() {
    const response = await axios.get("home/featured-teachers");

      if(!response.data.data){
        alert('Invalid Request');
        return;
      }
      setTeachers(response.data.data);
  }

    return (
      Object.keys(teachers).length ? 
        <section className="teachers-sec">
          <div className="container-fluid cf">
            <div className="row">
              <div className="col-md-12">
                <h2>Featured Teachers</h2>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-7">
                <div className="row">
                  {teachers.map((teacher,index)=>{
                    return <FeaturedTeacherItem key={index} teacher={teacher} />
                  })}
                </div>
              </div>
              <div className="col-md-5">
                <div className="teachers-box">
                  <h2>Interested in teaching?</h2>
                  <p>Join our inspiring community of teachers</p>
                  <Link to={'/teach'} className="get_button">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        :<></>
    );
  }

export default FeaturedTeachers;
