import React, { useState, useEffect,useRef,useContext } from 'react';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import HeaderTextBanner from '../../../layout/HeaderTextBanner'
import LoadingIcon from '../../../helpers/LoadingIcon';
import {UserRole} from  '../../../auth/Athentication'; 
import Modal from 'react-bootstrap/Modal'
import {Link,useLocation} from 'react-router-dom';
import { HiddenCropper } from "react-bootstrap-image-cropper";
import { useForm } from "react-hook-form";

import '../../../../css/about.css'
import GlobalContext from "../../../../GlobalContext";

export default function UserProfile(props) {
  const {setIsInnerPage} = useContext(GlobalContext)
  setIsInnerPage(true)
  const triggerRef = useRef();
  let location = useLocation();
  const userRole = UserRole();
  const [alertShow, setAlertShow] = useState(true);
  const [Languages, setLanguages] = useState([]);
  const [channels, setChannels] = useState([]);
  const [profilePhoto, setProfilePhoto] = useState(null);      
  const [isSubmitting, setIsSubmitting] = useState(false);            
  const [timezones, setTimezones] = useState([]);      
  const [profilePhotoSrc, setProfilePhotoSrc] = useState('/assets/img/smile.png');        
  const [formErrors, setFormErrors] = useState(null);      
  const [successMessage, setSuccessMessage] = useState(null);
  const { register,handleSubmit,getValues,setValue,reset,setError,errors } = useForm();
  const {register: register2,reset:reset2,errors: errors2,setError:setError2,handleSubmit: handleSubmit2} = useForm();
  const [children,setChildren] = useState([]);
  const [isChildModalOpen,setIsChildModalOpen]= useState(false);
  const [isAlertShown, setIsAlertShown] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  
  useEffect(() => {
    getTimezones();
    getChannels();
    getLanguages();
    getUserdetail();
  },[]);
  async function getTimezones(){
    const dbTimezones = await axios.get("get-timezones");
    setTimezones(dbTimezones.data.data);
  }
  async function getChannels(){
    const dbChannels = await axios.get("get-channels",{params:{show_in_interests:'yes'}});
    setChannels(dbChannels.data.data);
  }
  async function getLanguages(){
    const dbLanguages = await axios.get("get-class_languages");
    setLanguages(dbLanguages.data.data);
  }
    async function getUserdetail()
    {
      const response = await axios.get("user/get-user-profile");
      if(typeof(response.data.data)!='undefined' && response.data.status=='success'){
        const info = response.data.data;
        if(info.role.role == 'parent' && info.children.length){
          setChildren(info.children);
        }
        const formInfo = {
          first_name:info.first_name,
          last_name:info.last_name,
          nick_name:info.nick_name,
          interests:info.search_interests,
          zipcode:info.user_setting.zipcode,
          languages:info.user_setting.languages,
          timezone_id:info.user_setting.timezone_id,
        }
        if(info.profile_picture){
          setProfilePhotoSrc(info.profile_picture);
        }
        reset(formInfo);
        setTimeout(() => {
          let values =getValues();
          if(!values.languages && info.user_setting.languages){
            setValue('languages',info.user_setting.languages)
          }
          setIsFetched(true);
      }, 2800);
      } 
    }
    useEffect(() => {
      if(isAlertShown == false && location.state && typeof(location.state.message)!='undefined'){
        setSuccessMessage(location.state.message);
        setAlertShow(true);
      }
      setIsAlertShown(true);
  });

     //profile pic  
     function handleCropped(file) {
      setProfilePhotoSrc(URL.createObjectURL(file));  
      setProfilePhoto({file:file,name:'profilePhoto.png'}); 
    }
    const handleChildModal = (event,child=null)=>{
      event.preventDefault();
      if(child){
        const modalChild = {
          id:child.id,
          first_name:child.first_name,
          last_name:child.last_name,
          age:child.age,
          about_learner:child.about_learner,
          email:child.email,
        }
        reset2(modalChild);
      }else{
        reset2({
          id:'',
          first_name:'',
          last_name:'',
          age:'',
          about_learner:'',
          email:'',
        });
      }
      setIsChildModalOpen(true);
    }

    const formSubmit = (data)=>{
      const formdata = new FormData();
      for ( var key in data ) {
        formdata.append(key, data[key]);
      }
      if(profilePhoto){
        formdata.append('photo',profilePhoto.file,profilePhoto.name)
      }
      setIsSubmitting(true);
      setFormErrors(null);
      setSuccessMessage(null);
      axios.post("user/update-user-profile", formdata).then(response => {
        let res = response.data;
        if(res.status=='success'){
            window.scrollTo(0, 0)
            setSuccessMessage(res.message);
            setIsSubmitting(false);
        }else{
            let msg_str ='';
            // console.log(res.message.max_guests[0]);
            Object.keys(res.message).map((ele)=>{
                res.message[ele].map((msg,key) => {
                  setError(ele,{type:'manual',message:msg,shouldFocus:true});
                    console.log(msg)
                    msg_str += msg+'\n';
                    setIsSubmitting(false);
                    setFormErrors(msg_str);
                    window.scrollTo(0, 0)
                });

            });
            
        }     
    })
    .catch(error => {
        setIsSubmitting(false);
        console.log(error);
        alert('Internal server error');
    });

    }

//child form submit
const childFormsubmit = (data) => {
  setIsSubmitting(true);
axios.post('user/save-child', data)
.then(response => {
        let res = response.data;
        if(res.status=='success'){
         const childrensObj = children.filter((ch) => ch.id !== res.data.id);

          setChildren([...childrensObj,res.data]);
          setIsSubmitting(false);
          setIsChildModalOpen(false)
        }else{
            let msg_str ='';
            // console.log(res.message.max_guests[0]);
            Object.keys(res.message).map((ele)=>{
                res.message[ele].map((msg,key) => {
                  setError2(ele,{type:'manual',message:msg,shouldFocus:true});
                    msg_str += msg+'\n';
                    setIsSubmitting(false);
                });

            });
            
        }     
    }).catch(error => {
        setIsSubmitting(false);
        console.log(error);
        alert('Internal server error');
    });
}    
  return (
    <>
    <HeaderTextBanner heading={userRole == 'parent'? 'Parent Profile':'Student Profile'} />
    <section className="user-dashboard">
        <div className="container-fluid cf">
          <div className="parent-profile">
            <div className="row">
              <div className="col-md-8 offset-2">
              {successMessage ? 
      <div className="alert alert-success">{successMessage}</div> :(formErrors?<div className="alert alert-danger">{formErrors}</div> 
      : '')}
      {isFetched ?
                <form className="form-horizontal teacher_form" onSubmit={handleSubmit(formSubmit)}>
                  <h2>{userRole == 'parent'? 'Parent Profile':'Student Profile'}</h2>
                  <img src={profilePhotoSrc} />
              <br />
              <a onClick={() => triggerRef.current.trigger()} className="clr_link">
                Select Photo..
              </a>
                  <div className="control-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label className="label_info" htmlFor="first_name">First Name</label> 
                        <div className="controls">
                          <input id="first_name" name="first_name" ref={register} className="form-control input-large" type="text"/>
                        </div>
                          {errors.first_name && <p className="text-danger">{errors.first_name.message}</p>}
                      </div>
                      <div className="col-md-6">
                        <label className="label_info" htmlFor="last_name">Last Name</label> 
                        <div className="controls">
                          <input id="last_name" name="last_name" ref={register} className="form-control input-large" type="text"/>
                        </div>
                          {errors.last_name && <p className="text-danger">{errors.last_name.message}</p>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <label className="label_info" htmlFor="nick_name">Preferred name to be displayed</label> 
                        <div className="controls">
                          <input id="nick_name" name="nick_name" ref={register} className="form-control input-large" type="text"/>
                        </div>
                          {errors.nick_name && <p className="text-danger">{errors.nick_name.message}</p>}
                      </div>
                    </div>
                  </div>
                  {userRole == 'parent' ? 
                  <div className="control-group mt-10">
                    <div className="row">
                      <div className="col-md-12">
                        <h3>My children</h3>
                        <p>We'll show you classes that match your learner ages. Learner names are only shared with teachers and classmates after you enroll in a class</p>
                        {children.length ? children.map((child,index)=>{
                          return (
                            <div key={index} className="tb-row child_name">
                              <div className="tb-cell">
                                <img className="img-responsive teacher-img" src="assets/img/smile.png" />
                              </div>
                              <div className="tb-cell text-left pd-left">
                                <p><strong>{child.first_name+' '+child.last_name+' ('+child.age+')'}</strong></p>
                                <p><Link to="#" onClick={(event)=>handleChildModal(event,child)}>Edit</Link></p>
                              </div>
                            </div>
                          )
                        }) : '' }

                        <div className="controls">
                          <Link to="#" onClick={handleChildModal} className="get_button"><i className="icofont-plus" /> Add Child</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  :''}
                  <div className="control-group mt-20">
                    <div className="row">
                      <div className="col-md-12">
                        <h3>Update User Interests</h3>
                        <div className="row mt-10">
                          {channels.length && channels.map((channel,index)=>{
                            return (
                              <div className="col-md-4" key={index}>
                                <div className="control-group">
                                  <div className="controls">
                                    <label className="radio inline" htmlFor={'interest-'+index}>
                                      <input type="checkbox" ref={register} name="interests" id={'interest-'+index} value={channel.id} />&nbsp; {channel.channel_name} </label>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div> 

                  {/* <div className="control-group mt-10">
                    <div className="row">
                      <div className="col-md-12">
                        <h3>Exclusive Channel Affiliation: 1</h3>
                      </div>
                      <div className="col-md-3">
                        <div className="school_name">
                          Crossroads School for Music
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="controls">
                          <input id="datepicker" name="fname" className="form-control input-large" type="text" placeholder="Valid From" required />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="controls">
                          <input id="datepicker1" name="lname" className="form-control input-large" type="text" placeholder="Valid To" required />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <a href="#" className="delete">Delete</a>
                      </div>
                    </div>
                  </div> */}
                  {/* Text input*/}
                  <div className="control-group">
                    <div className="row">
                    </div>
                  </div>
                  <div className="control-group mt-10">
                    <div className="row">
                      <div className="col-md-12">
                        <h3>Preferred language</h3>
                        <div className="row mt-10">
                        {Languages.length && Languages.map((language,index)=>{
                            return (
                          <div className="col-md-3" key={index}>
                            <div className="control-group">
                              <div className="controls">
                                <label className="radio inline" htmlFor={'language-'+index}>
                                  <input type="checkbox" name="languages" ref={register} className="search_input1" value={language.id} id={'language-'+index} /> {language.language} </label>
                              </div>
                            </div>
                          </div>
                            )
                        })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="control-group mt-10">
                    <div className="row">
                      <div className="col-md-12">
                        <h3>Zipcode</h3>
                        <div className="row mt-0">
                          <div className="col-md-6">
                            <div className="controls">
                                <input type="number" className="form-control" name="zipcode" ref={register}/>
                            </div>
                            {errors.zipcode && <p className="text-danger">{errors.zipcode.message}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="control-group mt-10">
                    <div className="row">
                      <div className="col-md-12">
                        <h3>Time Zone</h3>
                        <div className="row mt-0">
                          <div className="col-md-6">
                            <div className="controls">
                              <div className="selectdiv">
                                <select name="timezone_id" ref={register} id="timezone_id" className="form-control">
                                <option value="">Select</option>  
                                  {timezones.length && timezones.map((timezone,index)=>{
                                    return <option key={index}  value={timezone.id}>{timezone.timezone_name}</option>
                                  })}
                                </select>
                              </div>
                            </div>
                            {errors.timezone_id && <p className="text-danger">{errors.timezone_id.message}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="control-group">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="controls">
                          <button id="confirmsignup" disabled={isSubmitting} name="confirmsignup" className="btn btn-success register-btn">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                </form>
                : <LoadingIcon /> }
              </div>
            </div>
          </div>
        </div>
      </section>
      <HiddenCropper
        triggerRef={triggerRef}
        onCropped={handleCropped}
        cropOptions={{ aspect: 4 / 3, maxZoom: 10 }}
        outputOptions={{ maxWidth: 300, maxHeight: 300 }}
        previewOptions={{ width: 300, height: 300 }}
        displayOptions ={{ removeButtonText :'Cacel' }}
      />

      <Modal dialogClassName="profile-child-modal" show={isChildModalOpen} onHide={()=>{setIsChildModalOpen(false)}} >
        <Modal.Header closeButton>
        <div className="img-box  align-items-center">
            <img src="/assets/img/icon-logo.svg" />
        </div>
        </Modal.Header>
        <Modal.Body>
        <div className="form-box">
            <h3>Add Child</h3>
            <h5>Information for Teachers</h5>
            <div className="row">
              <div className="col-md-12">
                <form className="form-horizontal login_model" onSubmit={handleSubmit2(childFormsubmit)} >
                  <fieldset>
                    <div className="control-group">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="controls">
                            <input id="fname" name="first_name" ref={register2({required:'This field is required.'})} className="form-control input-large" type="text" placeholder="First Name" />
                          </div>
                          {errors2.first_name && <p className="text-danger">{errors2.first_name.message}</p>}
                        </div>
                        <div className="col-md-6">
                          <div className="controls">
                            <input id="lastname" ref={register2({required:'This field is required.'})} name="last_name" className="form-control input-large" type="text" placeholder="Last Name" />
                          </div>
                          {errors2.last_name && <p className="text-danger">{errors2.last_name.message}</p>}
                        </div>
                      </div>
                    </div>
                    {/* Password input*/}
                    <div className="control-group">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="selectdiv">
                            <select name="age" id="age" ref={register2({required:'This field is required.'})} className="form-control">
                              <option value="">Age</option>
                              <option value="3">Age 3</option>
                              <option value="4">Age 4</option>
                              <option value="5">Age 5</option>
                              <option value="6">Age 6</option>
                              <option value="7">Age 7</option>
                              <option value="8">Age 8</option>
                              <option value="9">Age 9</option>
                              <option value="10">Age 10</option>
                              <option value="11">Age 11</option>
                              <option value="12">Age 12</option>
                              <option value="13">Age 13</option>
                              <option value="14">Age 14</option>
                              <option value="15">Age 15</option>
                              <option value="16">Age 16</option>
                              <option value="17">Age 17</option>
                              <option value="18">Age 18</option>
                              <option value="19">Age 19</option>
                            </select>
                          </div>
                          {errors2.age && <p className="text-danger">{errors2.age.message}</p>}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <label className="label_info" htmlFor="teacher">What should teachers know to help support this learner?</label> <br />
                          <div className="controls">
                            <textarea name="about_learner" ref={register2} className="form-control text-area" placeholder="Learner names are only shared with teachers and classmates after you enroll in a class" />
                          </div>
                          {errors2.about_learner && <p className="text-danger">{errors2.about_learner.message}</p>}
                        </div>
                      </div>
                    </div>
                    <h5>Information for Actyvate Learning</h5>
                    <div className="control-group">
                      <div className="row">
                        <div className="col-md-12">
                          <label className="label_info" htmlFor="teacher">Learner email (optional)</label>
                          <p>If entered, class notifications are also sent here. Your learner will need to log in using your account to view class content.</p>
                          <div className="controls">
                            <input id="email" name="email" className="form-control input-large" ref={register2} type="email"placeholder="Enter a valid email address" />
                          </div>
                          {errors2.email && <p className="text-danger">{errors2.email.message}</p>}
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        {/* Button */}
                        <div className="control-group">
                          <div className="controls">
                            <input type="hidden" name="id" ref={register2}/>
                            <button id="confirmsignup" disabled={isSubmitting} type="submit" name="confirmsignup" className="btn btn-success register-btn">Save</button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {/* Button */}
                        <div className="control-group">
                          <div className="controls">
                            <button type="button" onClick={()=>setIsChildModalOpen(false)} className="btn btn-success register-btn clr_change">Cancel</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
}
