import React, { useState,useEffect,useRef,useContext } from "react";
import WelcomeTeacherBanner from './blocks/WelcomeTeacherBanner'
import WhyTeach from './blocks/WhyTeach'
import Press from './blocks/Press'
import GlobalContext from "../../../GlobalContext";
import Faq from './blocks/Faq'
import axios from 'axios';
import TeacherTab from './blocks/TeacherTab'
import VolunteerTab from './blocks/VolunteerTab'
import PartnerTab from './blocks/PartnerTab'
import { useLocation } from "react-router-dom";

import '../../../css/teach.css'

function Teach(props) {
  const {setIsInnerPage} = useContext(GlobalContext) 
  setIsInnerPage(true)
  const [alertShow, setAlertShow] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);
  const [tabFocusInput, setTabfocusInput] = useState({});
  const [currentTab, setCurrentTab] = useState(null);
  const [teachCms, setTeachCms] = useState({});
  const [displayTeachVideo, setDisplayTeachVideo] = useState({});
  const [isAlertShown, setIsAlertShown] = useState(false);
  const [Countries, setCountries] = useState([]);
  let location = useLocation();
  
    useEffect(() => {
      getCountries();
      if(isAlertShown == false && location.state && typeof(location.state.message)!='undefined'){
        setSuccessMessage(location.state.message);
        setAlertShow(true);
      }
      setIsAlertShown(true);
      axios.get('home/site-settings').then(res=>{
        setDisplayTeachVideo(res.data.data.display_teach_video);
      });
      axios.get("get-teachpage-cms").then((res)=>{
        setTeachCms(res.data.data);
      });
  },[]);

  async function getCountries(){
    const dbcountries = await axios.get("get-countries");
    setCountries(dbcountries.data.data)
  }
  const switchCurrentTab = (tab)=>{
    switch(tab) {
      case 'volunteerTab':
        return <VolunteerTab Countries={Countries} displayTeachVideo={displayTeachVideo} />;
      case 'teacherTab':
        return <TeacherTab Countries={Countries} displayTeachVideo={displayTeachVideo}/>;
      case 'partnerTab':
        return <PartnerTab />;
      default:
        return '';
    }
  }

  const updateCurrentTab = (tab) => {
    setCurrentTab(tab);
    tabFocusInput.focus();
  }
  return (
    <>
      <WelcomeTeacherBanner updateCurrentTab={updateCurrentTab} heading={teachCms && Object.keys(teachCms).length ? teachCms.banner_heading : ''} />
      <section className="signup-sec">
        <div className="container-fluid cf">
          <div className="row signup-form-margin" id="main-content">
            <div className="col-md-12 offset-0 singup-form-div teach_bg content" id="content">
              <WhyTeach cms={teachCms}/> 
              {/* <Press images={teachCms?teachCms.press:{}} /> */}
              <Faq faqs={teachCms?teachCms.faq:{} }/>
              <div className="col-md-12">
                <div className="controls text-center">
                  <a className={"btn register-btn upload_video tablinks "+(currentTab=='volunteerTab'?'clr_orange':'')} onClick={()=>{setCurrentTab('volunteerTab')}}>Apply to volunteer</a>
                  <a className={"btn register-btn upload_video tablinks "+(currentTab=='teacherTab'?'clr_orange':'')} onClick={()=>{setCurrentTab('teacherTab')}}>Apply to Teach</a>
                  <a className={"btn register-btn upload_video tablinks "+(currentTab=='partnerTab'?'clr_orange':'')} onClick={()=>{setCurrentTab('partnerTab')}}>Apply to Partner</a>
              </div>
              {switchCurrentTab(currentTab)}
                <input type="radio" className="hidden-input-file" ref={(ele)=>setTabfocusInput(ele)}/>
            </div>
          </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Teach;
