import React,{ useState } from 'react'
import LoadingIcon from '../../../helpers/LoadingIcon';
import SearchResultClassItem from '../blocks/SearchResultClassItem';

export default function SearchListSec(props) {
  const classes = props.classes;
  const [isLoading,setIsLoading]=useState(props.isLoading);
  const handleInputChange = (event) => {
    const value = event.target.checked?'exclusive':'all';
    props.setInputExclusiveChannels(value);
  };
    return (
      <>
      <section className="search-topic">
        <div className="container-fluid cf">
          <div className="row results_records">
            <div className="col-md-12 text-right">
              <p><span className="ex_cls"><input type="checkbox" className="search_input1" checked={props.inputExclusiveChannels == 'exclusive'?true:false} onChange={handleInputChange} value="exclusive" name="channel_type" id="channel_type"  />&nbsp; Exclusive classes</span>  {classes.length} results</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
            {isLoading ? <LoadingIcon />  :''}
             {classes && classes.length ?
              classes.map((item,index)=>{
                 return(
                  <SearchResultClassItem key={index} classItem ={item} />
               )
              }) :'' }

              {!classes.length && !isLoading && <h3 className="text-center">No results found.</h3>}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-12 text-center">
              <a href="javascript:void(0);" className="get_button">Load More</a>
            </div>
          </div> */}
        </div>
      </section>
      </>
    );
}
