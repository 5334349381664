import React,{useState,useEffect} from "react";
import axios from 'axios';
import ClassListItem from '../../../../classes/blocks/ClassListItem';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function UpcomingClasses(props)
{
    const [classItems,setClassItems] = useState({});

    var settings = {
        dots: false,
            rows: 1,
            autoplay: true,
            centerMode: true,
            centerPadding: '60px',
            slidesToShow: 4,
            infinite: Object.keys(classItems).length > 4,
            arrows:true,  
            speed: 200,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 320,
                settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false },
              },
              {
                breakpoint: 580,
                settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false },
              },
              {
                breakpoint: 768,
                settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false },
              },
              {
                breakpoint: 1024,
                settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false },
              },
            ],
      };

    useEffect(() => {
      getUpcomingClasses();
    }, []);

    async function getUpcomingClasses() {
      const response = await axios.get("class/get-user-classes",{params:{type:'upcoming'}});

        if(!response.data.data){
          alert('Invalid Request');
          return;
        }
        setClassItems(response.data.data.classes);
    }

    return (
        Object.keys(classItems).length ?
        <section className="trending_section class-details-sec">
        <div className="container-fluid cf">
          <div className="row">
            <div className="col-md-121">
              <h2 className="ex-padding">Upcoming Classes</h2>
            </div>
          </div>
          <div className="ss-slick price-sec">
              <Slider {...settings}>
            { classItems.map((item, index) => { 
            return (
              <ClassListItem key={index} class={item} />)
            })}
            </Slider>
            </div>
          </div>
        </section>
        : <></>
    )
}