import React, { useState,useEffect,useContext } from "react";
import { useForm } from "react-hook-form";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import GlobalContext from "../../../../GlobalContext";
import Axios from "axios";

import '../../../../css/about.css'

export default function AgeSelection() {
    const {setIsInnerPage} = useContext(GlobalContext)
    setIsInnerPage(true)
    let history = useHistory();
    const [isSubmitting, setIsSubmitting] = useState(false);  
    const [formErrors, setFormErrors] = useState();      
    const { register,handleSubmit,errors } = useForm();
    const [ChildAges,setChildAges] = useState();
    
    useEffect(() => {
        getChildrens();
    },[]);
    const ages = [];
    for (let i = 3; i < 20; i++) {
        ages.push(i);
    }  
    async function getChildrens(){
         const response = await Axios.get('user/childrens');
        if(typeof(response.data.status) !='undefined' && response.data.status == 'success'){
            setChildAges(response.data.data);
        }
    }


    
//submission
const formSubmit = (data)=>{

  setIsSubmitting(true);
  
  axios.post('user/save-child', data)
  .then(response => {
    let res = response.data;
    console.log(res);
    if(res.status && res.status == 'success'){
        history.push("/user-profile");
    }else{
        setIsSubmitting(false);
        let msg_str ='';
        if(typeof(res.message)== 'string'){
            setFormErrors(res.message);
        }else{ 
            Object.keys(res.message).map((ele)=>{
                res.message[ele].map((msg,key) => {
                    console.log(msg)
                    msg_str += msg+'\n';
                    setFormErrors(msg_str);
                });

            });
    }
        
    }     
  })
  .catch(error => {
    setIsSubmitting(false);
    console.log(error);
    alert('Internal server error');
  });
  
  };

  return (
    <section className="signup-sec">
        <div className="container">
          <div className="row signup-form-margin">
            <div className="col-md-8 offset-2 singup-form-div">
                { formErrors && <div className="alert alert-danger">{formErrors}</div> } 
              <div className="form-box">
                <h1>Tell us about your child</h1>
                <h2>The information you share below will help us recommend the right classes for your kiddo!</h2>
                <form className="form-horizontal" onSubmit={handleSubmit(formSubmit)}>
                  <fieldset>
                    <div className="row age_form">
                       <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">Firstname </label>
                            <input type="text" name="first_name" ref={register({required:'This field is required.'})} className="form-control" />
                            {errors.first_name && <p className="text-danger">{errors.first_name.message}</p>} 
                          </div>
                       </div>
                       <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">Lastname </label>
                            <input type="text" name="last_name" ref={register({required:'This field is required.'})} className="form-control" />
                            {errors.last_name && <p className="text-danger">{errors.last_name.message}</p>} 
                          </div>
                       </div>
                    </div>
                    <div className="form-group age_form">
                      <label htmlFor="">Age</label>
                      <select className="form-control" name="age" ref={register({required:'This field is required.'})}>
                        <option value="">Select</option>
                        {ages && ages.map((age,index)=>{
                          return (
                          <option key={index} value={age}>{age} Yrs</option>
                          )
                        })}
                      </select>
                        {errors.age && <p className="text-danger">{errors.age.message}</p>}                         
                    </div>
                    <div className="control-group">
                      <div className="controls text-center1">
                        <button type="submit" disabled={isSubmitting} className="btn btn-success1 register-btn btn_block">Save and continue</button>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}
