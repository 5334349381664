import React, { useState,useEffect,useContext } from "react";
import WelcomeTeacherBanner from './blocks/WelcomeTeacherBanner'
import TeacherDashboardTab from './blocks/tabs/TeacherDashboardTab'
import TeacherClassesTab from "./blocks/tabs/TeacherClassesTab";
import TeacherSectionsTab from "./blocks/tabs/TeacherSectionsTab";
import TeacherPayoutsTab from "./blocks/tabs/TeacherPayoutsTab";
import TeacherScheduleTab from "./blocks/tabs/TeacherScheduleTab";
import TeacherProfileTab from "./blocks/tabs/TeacherProfileTab";
import TeacherSettingsTab from "./blocks/tabs/TeacherSettingsTab";
import Success from "../layout/alert/Success";
import { useLocation } from "react-router-dom";


import '../../css/teacher-dashboard.css'
import GlobalContext from "../../GlobalContext";

function TeacherDashboard(props) {
  const {setIsInnerPage} = useContext(GlobalContext)
  setIsInnerPage(true)
  const [teacherTabPage, setTeacherTabPage] = useState('Dashboard');
  const [alertShow, setAlertShow] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isAlertShown, setIsAlertShown] = useState(false);
  let location = useLocation();
  
  
  // const showAlert = () =>{
    // }
    useEffect(() => {
      if(localStorage.getItem('teacherTabPage')!=null && localStorage.getItem('teacherTabPage')!='' && typeof(localStorage.getItem('teacherTabPage'))!='undefined' ){
        setTeacherTabPage(localStorage.getItem('teacherTabPage'));
        }
        if(isAlertShown == false && location.state && typeof(location.state.message)!='undefined'){
          setSuccessMessage(location.state.message);
          setAlertShow(true);
        }
        setIsAlertShown(true);
      },[]);
      
      useEffect(()=>{
        if(teacherTabPage){
          localStorage.setItem('teacherTabPage',teacherTabPage);
        }
      },[teacherTabPage]);

  return (
    <>
      {successMessage && <Success setAlertShow={setAlertShow} message={[successMessage]} show={alertShow} /> }
      <WelcomeTeacherBanner />
      <section className="user-dashboard">
        <div className="container-fluid cf">
          <div className="row">
            <div className="col-md-12">
              {/* Bootstrap CSS */}
              <ul
                className="nav nav-tabs user-dashboard-ul"
                role="tablist"
                id="myTab"
              >
                <li className="nav-item">
                  <a
                    onClick={() => setTeacherTabPage("Dashboard")}
                    className={
                      "nav-link " +
                      (teacherTabPage == "Dashboard" ? " active " : "")
                    }
                    href="#"
                    role="tab"
                    data-toggle="tab"
                  >
                    Dashboard
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => setTeacherTabPage("Classes")}
                    className={
                      "nav-link " +
                      (teacherTabPage == "Classes" ? " active " : "")
                    }
                    href="#"
                    role="tab"
                    data-toggle="tab"
                  >
                    Classes
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => setTeacherTabPage("Sections")}
                    className={
                      "nav-link " +
                      (teacherTabPage == "Sections" ? " active " : "")
                    }
                    href="#three"
                    role="tab"
                    data-toggle="tab"
                  >
                    Sections
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => setTeacherTabPage("Schedule")}
                    className={
                      "nav-link " +
                      (teacherTabPage == "Schedule" ? " active " : "")
                    }
                    href="#four"
                    role="tab"
                    data-toggle="tab"
                  >
                    Schedule
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => setTeacherTabPage("Profile")}
                    className={
                      "nav-link " +
                      (teacherTabPage == "Profile" ? " active " : "")
                    }
                    href="#five"
                    role="tab"
                    data-toggle="tab"
                  >
                    Profile
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => setTeacherTabPage("Settings")}
                    className={
                      "nav-link " +
                      (teacherTabPage == "Settings" ? " active " : "")
                    }
                    href="#six"
                    role="tab"
                    data-toggle="tab"
                  >
                    Settings
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => setTeacherTabPage("Payouts")}
                    className={
                      "nav-link " +
                      (teacherTabPage == "Payouts" ? " active " : "")
                    }
                    href="#Payouts"
                    role="tab"
                    data-toggle="tab"
                  >
                    Payouts
                  </a>
                </li>
              </ul>
              <div className="tab-content profile_form">
                {
                  {
                    Dashboard: <TeacherDashboardTab />,
                    Classes: <TeacherClassesTab />,
                    Sections: <TeacherSectionsTab />,
                    Schedule: <TeacherScheduleTab />,
                    Profile: <TeacherProfileTab />,
                    Settings: <TeacherSettingsTab />,
                    Payouts: <TeacherPayoutsTab />,
                  }[teacherTabPage]
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TeacherDashboard;
