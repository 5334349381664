import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal'
import { useForm } from "react-hook-form";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import addDays from 'date-fns/addDays'
import "react-datepicker/dist/react-datepicker.css";

export default function EditSectionTab(props) {
    const [show, setShow] = useState(false);
    const initialInputs = {
      startHour:'01',
      startMinute:'00',
      endHour:'01',
      endMinute:'00',
      startTimeType:'AM',
      endTimeType:'AM',
    };

    const initialSectionInputs = {
      visibility:'public',
      timezone_id:'',
      availability:1,
      section_link:'http://tunedin.webstaging.info/designer/html/final/',
    }
    const maxAvailability = 20;
    const sectionId = props.section_id;
    const classId = props.class_id;
    const sectionInfo = props.sectionInfo;

    const { register,handleSubmit,errors } = useForm();
    const [sectionMeetings, setSectionMeetings] = useState(props.sectionMeetings);
    const [classMeetings, setClassMeetings] = useState(props.classMeetings);
    const [totalMeetings, setTotalMeetings] = useState(props.totalMeetings);
    const [requiredMeetings, setRequireMeetings] = useState(props.requiredMeetings);
    const [timezones, setTimezones] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);
    const [inputs, setInputs] = useState(initialInputs);
    const [sectionInputs, setSectionInputs] = useState(initialSectionInputs);
    const [sectionMeetingsString, setSectionMeetingsString] = useState([]);
    const [schduelFormErrors, setSchduelFormErrors] = useState(null);
    const [sectionFormErrors, setSectionFormErrors] = useState(null);
    const [isDisableAddBtn, setIsDisableAddBtn] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    async function getTimezones(){
      const res = await axios.get("get-timezones");
      if(res.data && typeof(res.data.status)!='undefine'){ 
        if(res.data.status == 'success'){
          setTimezones(res.data.data);
        }else{
          alert('Could not fetch timezones from server.');
          return false;
        }
      }else{
        alert('Internal server Error.');
        window.location.reload();
      }   
    }

    const handleInputChange =(event) => {
      event.persist();
      const input = event.target;
          setInputs(inputs => ({...inputs, [input.name]: input.value}));
    };

    function updateSectionmeetingString(meetings)
    {
      meetings && meetings.map((meeting,index)=>{
        var startTime = new Date(meeting.meeting_date+' '+meeting.start_time);
        var endTime = new Date(meeting.meeting_date+' '+meeting.end_time);
        var meetingString = <span>{startTime.toDateString()+' '+startTime.toLocaleTimeString() +'-'+endTime.toLocaleTimeString()}</span>;
         setSectionMeetingsString(sectionMeetingsString => [...sectionMeetingsString,meetingString]);
            
      })
      const sectionInputs = {
        visibility:sectionInfo.visibility,
        timezone_id:sectionInfo.timezone_id,
        availability:sectionInfo.available_space,
        section_link:initialSectionInputs.section_link,
      }
      setSectionInputs(sectionInputs);
      
    }
   
    
    useEffect(() => {
      if(sectionInfo){
        updateSectionmeetingString(sectionMeetings);
      }
      getTimezones();
    },[]);
    useEffect(() => {
      console.log('tota'+totalMeetings)
      console.log('requred'+requiredMeetings)

      if(totalMeetings>=requiredMeetings){
        setIsDisableAddBtn(true);
       }else{
         setIsDisableAddBtn(false);
       }
    }, [totalMeetings])

    const startHours=[],avialibleSpaces=[],startMinutes=[],endHours=[],endMinutes=[];
    for(let i=1; i<13; i++){
      startHours.push(<option key={i} value={(i<10 ? '0'+i:i)}>{(i<10 ? '0'+i:i)}</option>);
      endHours.push(<option key={i} value={(i<10 ? '0'+i:i)}>{(i<10 ? '0'+i:i)}</option>);
    }
    for(let i=0; i<60; i=i+5){
      startMinutes.push(<option key={i} value={(i<10 ? '0'+i:i)}>{(i<10 ?'0'+i:i)}</option>);
      endMinutes.push(<option key={i} value={(i<10 ? '0'+i:i)}>{(i<10 ?'0'+i:i)}</option>);
    }
    for(let i=1; i<maxAvailability; i++){
      avialibleSpaces.push(<option key={i} value={i}>{i}</option>);
    }
    const submitMeetingForm = (event) =>{
      setSchduelFormErrors('');
      event.preventDefault();
      if(!selectedDate){
        setSchduelFormErrors('Please select Meeting date');
        return false;
      }
      var startTime = new Date(selectedDate);
      var start_hour = parseInt(inputs.startHour);
      console.log(inputs);
      if(inputs.startTimeType=='PM'){
        start_hour+=12;
      }
      startTime.setHours(start_hour);
      startTime.setMinutes(inputs.startMinute);
      
      var endTime = new Date(selectedDate);
      var end_hour = parseInt(inputs.endHour);
      if(inputs.endTimeType=='PM'){
        end_hour+=12;
      }
      endTime.setHours(end_hour);
      endTime.setMinutes(inputs.endMinute);
      console.log(startTime.getTime());
      console.log(endTime.getTime());

       if(startTime.getTime() > endTime.getTime()){
        setSchduelFormErrors('Meeting end time should be greter then start time.');
        return false;
      }else if(diff_minutes(endTime,startTime)<15 || diff_minutes(endTime,startTime) > 180){
        setSchduelFormErrors('Meeting time should be minimum 15mins and maximum 180 minutes.');
        return false;
       } 
        var isExisits = classMeetings.length ? classMeetings.filter( meeting =>{ 
          var DBstartTime = new Date(meeting.start_time);
          var DBendTime = new Date(meeting.end_time);
          DBstartTime = DBstartTime.getTime();
          DBendTime = DBendTime.getTime();
          var meetingStartTime = startTime.getTime();  
          var meetingEndTime = endTime.getTime();
          if((meetingStartTime >= DBstartTime && meetingStartTime <= DBendTime) || (meetingEndTime >= DBstartTime && meetingEndTime <= DBendTime) || 
        (DBstartTime <= meetingStartTime && DBendTime >= meetingStartTime ) || (DBstartTime <= meetingEndTime && DBendTime >= meetingEndTime)) {
            return true
          }
         }) : false;
         if(isExisits && isExisits.length>0){
           setSchduelFormErrors('Meeting conflicts occured. some meeting already scheduled in this meeting time.')
           return false;
         }
         var sec_meetings = (totalMeetings>0) && sectionMeetings.length ? sectionMeetings : [];
         sec_meetings.push({
            meeting_date:startTime.toLocaleDateString(),
            start_time:getTime(startTime),
            end_time:getTime(endTime)
          });
          console.log(sec_meetings);
         setSectionMeetings(sec_meetings);
         var meetingString = <span>{startTime.toDateString()+' '+startTime.toLocaleTimeString() +'-'+endTime.toLocaleTimeString()}</span>;
         setSectionMeetingsString(sectionMeetingsString => [...sectionMeetingsString,meetingString]);
         setTotalMeetings(totalMeetings+1);
         setShow(false);
    }

    function deleteMeeting(index)
    {
      var sections = sectionMeetings;
      var sectionString = sectionMeetingsString;
      if(sections.length && sections[index]){
        sections.splice(index,1);
      }
      if(sectionString.length && sectionString[index]){
        sectionString.splice(index,1);
      }
     setSectionMeetings(sections);
     setSectionMeetingsString(sectionString);
     setTotalMeetings(totalMeetings-1)

    }
    
    function getTime(time){
      var hrs = time.getHours();
      var mins = time.getMinutes();
      return hrs+':'+mins;
    }

    function diff_minutes(dt2, dt1) 
    { 
      var diff =(dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60;
      console.log(dt2.getTime() - dt1.getTime());
        return Math.abs(Math.round(diff));
    }

//section form
const handleSectionInputChange = (event) => {
  event.persist();
  const input = event.target;
      setSectionInputs(sectionInputs => ({...sectionInputs, [input.name]: input.value}));
};

//submit section form
const onSubmitSectionForm = (data) =>{
  setSectionFormErrors(null)
  var formdata = new FormData();
  for ( var key in sectionInputs ) {
    formdata.append(key, sectionInputs[key]);    
  }
  console.log(sectionMeetings);
  console.log(totalMeetings)
  console.log(requiredMeetings)
  if(totalMeetings!=requiredMeetings){
    setSectionFormErrors('Please add required meetings for this section.');
    window.scrollTo(0, 0)
    return false;
  }
sectionMeetings.map((meeting,index)=>{
  formdata.append('meeting_date[]',meeting.meeting_date); 
  formdata.append('start_time[]',meeting.start_time); 
  formdata.append('end_time[]',meeting.end_time); 
});
formdata.append('class_id',classId);

var post_url = sectionId? 'class/save-section/'+sectionId : 'class/save-section';
axios.post(post_url, formdata).then(response => {
  let res = response.data;
  if(res.status=='success'){
    localStorage.setItem('teacherTabPage','Sections');
    setSuccessMessage(res.message);
      setIsSubmitted(true);
  }else{
    setSectionFormErrors(res.message);
    window.scrollTo(0, 0)
  }     
})
.catch(error => {
  console.log(error);
      alert('Internal server error');
});

}
    return (
       isSubmitted ? <Redirect 
       to={{pathname:"/teacher-dashboard",state:{message:(successMessage?successMessage:'Section saved Successfully')}}} /> :
      <>  
        <div className="row">
        <div className="col-md-12">
            {sectionFormErrors?<div className="alert alert-danger">{sectionFormErrors}</div>:''} 
          <form className="form-horizontal teacher_form" onSubmit={handleSubmit(onSubmitSectionForm)}>
            <h3>Visibility</h3>
            <div className="row">
              <div className="col-md-6">
                  <input type="radio" onChange={handleSectionInputChange} checked={sectionInputs.visibility=='public'?true:false} ref={register({required: 'This field is required'})} className="hidden-input-file" name="visibility" id="visibility-public" value="public" /> 
                <label className="course-type" htmlFor="visibility-public">
                  <h3>Public</h3>
                  <p>Anyone can enroll in this class, it will show up in search results and your class detail page.</p>
                </label>
              </div>
              <div className="col-md-6">
                  <input type="radio" className="hidden-input-file" checked={sectionInputs.visibility=='private'?true:false} ref={register({required: 'This field is required'})} onChange={handleSectionInputChange} name="visibility" id="visibility-private" value="private" /> 
                <label className="course-type" htmlFor="visibility-private">
                  <h3>Private</h3>
                  <p>Only people with the link provided will be able to see and enroll in this section.</p>
                </label>
              </div>
              {errors.visibility && <p className="text-danger">{errors.visibility.message}</p>}
            </div>

            <h3 className="mt-10">Timezone</h3>
            <div className="row">
              <div className="col-md-4 learners_edit">
                <div className="controls">
                  <select name="timezone_id" value={sectionInputs.timezone_id} ref={register({required: 'This field is required'})} id="timezoneId" onChange={handleSectionInputChange} className="form-control">
                    <option value="">Select timezone</option>  
                  {timezones.length &&
                  timezones.map((timezone, index) => {
                  return  <option key={index} value={timezone.id}>{timezone.timezone_name}</option>
                    })  }
                  </select>
                  {errors.timezone_id && <p className="text-danger">{errors.timezone_id.message}</p>}

                </div>
              </div>
            </div>
            <br />
            <h3 className="mt-10">Meetings</h3>
                  <p>This section should contains exact {requiredMeetings} meeting(s).</p>
            <p>Set meeting times</p>
            <div className="row">
              <div className="col-md-12 learners_edit">
                { sectionMeetingsString.length ? sectionMeetingsString.map((meeting,index) =>{
                  return <p key={index}>{meeting} <a href="#" onClick={()=>deleteMeeting(index)}>X</a></p>
                }) : ''}
                {/* <p>This is a one-time class. You should create a single meeting time for this class. To offer this class more than once, create <a href>additional sections here.</a></p> */}
                <p>Need some help picking a time? <a href="#">Click here</a> for guidance on when to schedule.</p>
                <div className="controls">
                {isDisableAddBtn ?  <a className="get_button mt-10">Maximum meetings added.</a>:
                  <a onClick={handleShow} className="get_button mt-10">Add a make up meeting time...</a>}
                </div>
                <p><strong>We recommend scheduling sections at least one week before they start.</strong></p>
              </div>
            </div>
            <hr />
            <h3 className="mt-10">Integrated video chat</h3>
            <p>Enabled</p>
            <div className="row">
              <div className="col-md-12 learners_edit">
                <p>TunedIn integrates with Zoom for video chat.</p>
              </div>
            </div>
            <hr />
            <h3 className="mt-10">Available spaces</h3>
            <div className="row">
              <div className="col-md-12 learners_edit">
                <div className="controls age_div">
                  <select name="availability" value={sectionInputs.availability} ref={register({required: 'This field is required'})} onChange={handleSectionInputChange} id="mname" className="form-control">
                    {avialibleSpaces}
                  </select>
                  {errors.availability && <p className="text-danger">{errors.availability.message}</p>}
                </div>
                <p>We will try to fill the class, unless you restrict the number of spaces here. Section availability is set by the class size. If you would like to add more spaces to a section, you will need to change the class size advertised in the listing from the class edit page.</p>
              </div>
            </div>
            <hr />
            <h3 className="mt-10">Section link</h3>
            <div className="row">
              <div className="col-md-12 learners_edit">
                <div className="row">
                  <div className="col-md-8">
                    <div className="control-group">
                      <div className="controls">
                        <input type="text" className="form-control" ref={register({required: 'This field is required'})} onChange={handleSectionInputChange} name="section_link" id="copy" value={sectionInputs.section_link} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="controls">
                      <button href="#" className="btn btn-success register-btn upload_video">Copy</button>
                    </div>
                  </div>
                </div>
                <p>People with this link can enroll in this section directly</p>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-3">
                <button type="submit" className="btn btn-success register-btn">Save Section</button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 learners_edit">
                <p>Go back to the <a href="edit-summary-class.html">Class Summary </a>to add more sections.</p>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <Modal.Header closeButton>
                    <div className="img-box  align-items-center">
                        <img src={require('../../../../../images/icon-logo.svg')} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                <h3>Add a meeting</h3>
                    <form onSubmit={submitMeetingForm} autoComplete="off" className="php-email-form mb-20">
                      {schduelFormErrors && <div className="alert alert-danger">{schduelFormErrors}</div> }
                        <div className="row">
                          <div className="col-md-12">
                              {/* Terms input*/}
                              <div className="controls">
                              <span className="calendar_icon"><i className="bx bx-calendar-week" /></span>
                              <DatePicker
                                className="form-control input-large"
                                dateFormat="dd/MM/yyyy"
                                selected={selectedDate?selectedDate:''}
                                onChange={date => setSelectedDate(date)}
                                minDate={addDays(new Date(), 10)}
                                placeholderText="Meeting date"
                                autoComplete='off'
                              />
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="controls meeting_div">
                              <span>Start</span>
                              <select name="startHour" value={inputs.startHour} onChange={handleInputChange} id="mname" className="form-control">
                                {startHours}
                              </select>
                              <span>:</span>
                              <select name="startMinute" id="startMinute" value={inputs.startMinute} onChange={handleInputChange} className="form-control">
                                  {startMinutes}
                              </select>
                              <span>:</span>
                              <select name="startTimeType" id="startTimeType" value={inputs.startTimeType} onChange={handleInputChange} className="form-control">
                                  <option value="AM">AM</option>
                                  <option value="PM">PM</option>
                              </select>
                              </div>
                          </div> 
                          <div className="col-md-12 mb-5">
                              <div className="controls meeting_div">
                              <span>End &nbsp;</span>
                              <select name="endHour" id="endHour" value={inputs.endHour} onChange={handleInputChange} className="form-control">
                                {endHours}
                              </select>
                              <span>:</span>
                              <select name="endMinute" id="endMinute" value={inputs.endMinute} onChange={handleInputChange} className="form-control">
                                  {endMinutes}
                              </select>
                              <span>:</span>
                              <select name="endTimeType" id="endTimeType" value={inputs.endTimeType} onChange={handleInputChange} className="form-control">
                                  <option value="AM">AM</option>
                                  <option value="PM">PM</option>
                              </select>
                              </div>
                          </div>
                          <div className="col-md-8">
                              <div className="control-group">
                              <div className="controls">
                                  <button type="submit" id="confirmsignup" disabled={isDisableAddBtn} name="confirmsignup" className="btn btn-success register-btn meet_btn">Save meeting time</button>
                              </div>
                              </div>
                          </div>
                          <div className="col-md-4">
                              <div className="control-group">
                              <div className="controls">
                                  <button id="confirmsignup" type="button" name="confirmsignup" onClick={handleClose} className="btn btn-success register-btn meet_btn1">Cancel</button>
                              </div>
                              </div>
                          </div>  
                        </div>
                        <div className="clearfix" />
                    </form>
                </Modal.Body>
            </div>
        </div>
      </Modal>
        </>
    )
}