import React,{useState,useEffect} from 'react'
import axios from 'axios'

export default function Faq(props) {
  const [images, setImages] = useState([]);
  useEffect(()=>{
    axios.get("get-teachpage-cms").then((res)=>{
      setImages(res.data.data.press);
    }).catch(err=>{
      alert('Internal server error.')
    });
},[]);
  
    return (
      <>
      <section className="teachers-sec">
          <div className="container-fluid cf">
            <div className="row">
              <div className="col-md-12">
                <h2>In the Press</h2>
              </div>
              <div className="col-md-12 press why bg-white">
                  <div className="pres_img">
                  {images && Object.keys(images).length ? 
                images.map((image,index)=>{
                  return (
                    <img key={index} src={image.image} />
                  )}) :'' }
                  </div>
              </div>
            </div>
          </div>
          </section>
      </>
    );
}
