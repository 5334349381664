export function getCleanStatus(status)
{
    switch(status){
        case 'pending':
            return 'Not Listed';
            break;
        case 'draft':
            return 'Draft';
            break;
        case 'active':
            return 'Listed';
            break;
        case 'closed':
            return 'Rejected/Closed';
            break;
        case 'archive':
            return 'Archived';
            break;
        default:
            return status;
            break;
    }
}
export function ucFirst(str)
{
    const nameCapitalized = str.charAt(0).toUpperCase() + str.slice(1);
    return nameCapitalized;
}

export function getStatusLableClass(status)
{
    switch(status){
        case 'pending':
            return 'info';
            break;
        case 'draft':
            return 'warning';
            break;
        case 'active':
            return 'success';
            break;
        case 'closed':
            return 'danger';
            break;
        case 'archive':
            return 'secondary';
            break;
        default:
            return 'primary';
            break;
    }
}
export function getCleanTeacherStatus(status)
{
    switch(status){
        case 'pending':
            return 'Pending';
            break;
        case 'approved':
            return 'Approved';
            break;
        case 'volunteer_to_teacher':
            return 'Pending Teacher Conversion';
            break;
        case 'rejected':
            return 'Rejected';
            break;
        default:
        return status;
        break;
    }
}

export function getAge(age){
    if(parseInt(age) > 19){
        return 'above 19';
    }else{
        return age;
    }
}
export function getAgeRange(start,end){
    const startAge = parseInt(start);
    const endAge = parseInt(end);
    if(startAge > 19){
        return 'Parents';
    }else{
        if(endAge > 19){
            return startAge+'-'+'above 19yrs';
        }
        return startAge+'-'+endAge+'yrs';
    }
}

export function GetStringForUrl(str)
{
    if(str){
        str = str.replace(/\s+/g, '-').toLowerCase();
    }
        return str;
}
export function dateFormatString(inputDate)
{
    let dateString = new Date(inputDate);
    let date = parseInt(dateString.getMonth()+1) + "-" + dateString.getDate() + "-" + dateString.getFullYear();
    return date
}