import React, { useState,useEffect,useContext } from "react";
import UserDashboardBanner from './blocks/UserDashboardBanner'
import EnrolledClasses from './blocks/EnrolledClasses'
import FavoriteClasses from './blocks/FavoriteClasses'
import LikedClasses from './blocks/LikedClasses'
import UpcomingClasses from './blocks/UpcomingClasses'
import DiscountClasses from './blocks/DiscountClasses'
import RecentClasses from './blocks/RecentClasses'
import Search from "../../../home/blocks/Search";
import RequestAClass from "../../../home/blocks/RequestAClass";
import GlobalContext from "../../../../GlobalContext";
import BlogGrid from "../../../home/blocks/BlogGrid";
import parse from 'html-react-parser';
import HeaderTextBanner from '../../../layout/HeaderTextBanner'
import { useLocation } from "react-router-dom";

import '../../../../css/search.css'

function UserDashboard(props) {
  const {setIsInnerPage} = useContext(GlobalContext)
  setIsInnerPage(true)
  const [alertShow, setAlertShow] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isAlertShown, setIsAlertShown] = useState(false);
  let location = useLocation();
  
    useEffect(() => {
      if(isAlertShown == false && location.state && typeof(location.state.message)!='undefined'){
        setSuccessMessage(location.state.message);
        setAlertShow(true);
      }
      setIsAlertShown(true);
  });

  return (
    <>
      <HeaderTextBanner heading={parse('Welcome, <span>'+localStorage.getItem("Firstname")+' '+localStorage.getItem("Lastname")+'</span>')} />
      <br></br>
      <Search />
      <EnrolledClasses />
      <FavoriteClasses />
      <LikedClasses />
      <UpcomingClasses />
      <DiscountClasses />
      <RecentClasses />
      <RequestAClass />
      <BlogGrid />
    </>
  );
}
export default UserDashboard;
