import React,{useEffect, useState,useRef,createRef} from 'react'
import Modal from 'react-bootstrap/Modal'
import {Link} from 'react-router-dom'
import axios from 'axios'
import LoadingIcon from '../helpers/LoadingIcon'

export default function InviteCohortModal(props)
{
    const sectionID = props.sectionId;
    const invitationFor = props.invitationFor;
    let refs = useRef([createRef(), createRef()]);
    const [invitedGroups,setInvitedGroups] = useState([])
    const [isGroupFetching,setIsGroupFetching] = useState(false)
    const [selectedUser,setSelectedUser] = useState('me');
    const [myChildren,setMyChildren] = useState([]);
    const handleClose = () => props.setShowInvitationModal(false);

    useEffect(() => {
        getChildrens();
        getCohortGroups()
    }, [])
        
      useEffect(()=>{
        if(selectedUser){
            getCohortGroups(selectedUser);
        }
        getChildrens();
      },[selectedUser]);

    async function getChildrens()
    {
        const res = await axios.get('user/get-children');
        if(res && res.data && res.data.status == 'success'){
            setMyChildren(res.data.data);
        }
    }
    const handleChild = (event) =>{
        event.preventDefault();
        
        const value = event.target.value;
        setSelectedUser(value);
    }

    async function getCohortGroups(childId=null)
    {
        if(childId == 'me'){
            childId = null;
        }
        setIsGroupFetching(true);
        let userId = childId ? childId : '';
        let isChild = childId ? 'yes' : '';
        const res = await axios.get('user/friends-goups',{params:{user_id:userId,is_child:isChild}});
        if(res && res.data && res.data.status == 'success'){
            setInvitedGroups(res.data.data);
            setIsGroupFetching(false);
        }
    }

    const handleInvitation = (e,index,id)=>{
        e.preventDefault();
        if(refs.current[index] && refs.current[index].current){
            refs.current[index].current.disabled = true
            refs.current[index].current.innerText = 'Sending...';

        }

        let userId = selectedUser;
        if(selectedUser == 'me'){
            userId = null;
        }
        const data = {
            section_id:sectionID,
            group_id:id,
            user_id:userId,
            invitation_for:invitationFor
        }
        axios.post('user/invite-purchase-class-to-friends', data).then(response => {
            let res = response.data;
            if(res.status=='success'){
                if(refs.current[index] && refs.current[index].current){
                    console.log(refs.current[index].current);
                    refs.current[index].current.innerText = 'Invitation Sent';
                    refs.current[index].current.classList.add("invite-sent");
                }else{
                    alert('Invitation sent successfully.');
                    return;
                }
            }else{
                refs.current[index].current.innerText = 'Invitation Faild';
              alert('Something went wrong while invite.')
            }
          }).catch(error => {
            console.log(error);
            alert('Internal server error');
        });

    }

    return(
        <Modal show={props.show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
            <div className="img-box  align-items-center">
                <img src="/assets/img/icon-logo.svg" />
            </div>
            </Modal.Header>
            <Modal.Body>
                <div className="singup-form-div">
                    <div className="row">
                    {myChildren.length ? 
                        <div className="col-md-3">
                            <div className="controls">
                            {/* <input id="grade" name="grade" class="form-control input-large" type="text" placeholder="Current grade"> */}
                            <div className="selectdiv">
                                <select name="child" id="child" className="form-control" onChange={handleChild}>
                                    <option value="me">Me</option>
                                {myChildren.map((child,index)=>{
                                    return (
                                    <option key={index} value={child.id} >{child.first_name+' '+child.last_name}</option>
                                    )
                                })}
                                </select>
                            </div>
                            </div>
                        </div>
                        : ''}
                        <div className="col-md-12 mt-0 wel_text">
                            <h3 className="cohort_heading">My Cohort List</h3>
                            {isGroupFetching ? <LoadingIcon /> :
                                <>
                                {invitedGroups && invitedGroups.length ? 
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Group</th>
                                    <th>Members</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {invitedGroups.map((group,index1)=>{
                                    return (
                                <tr key={index1}>
                                    <td>{index1+1}</td>
                                        <td>
                                            <ul>
                                                {group.cohort_users.map((member,index2)=>{
                                                    return (
                                                    <li key={index2}>{member.first_name+' '+member.last_name+ (member.email ? '('+member.email+')': '')}</li>
                                                    )
                                                })}
                                            </ul>
                                        </td>
                                    <td>
                                        <button ref={refs.current[index1]} onClick={(e)=>handleInvitation(e,index1,group.id)} className="btn btn-primary">Invite</button>
                                    </td>
                                </tr>
                                    ) 
                                    })}
                                    </tbody>
                                </table>
                            :<p className="no_records">No groups found. To create Friend group <Link to="/friends-group">click here</Link></p>
                            }
                            </> }
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}