import Axios from 'axios';
import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import LoadingIcon from '../../../../helpers/LoadingIcon';
import {GetStringForUrl} from '../../../../helpers/StringHelper'
import InviteCohortModal from '../../../../users/InviteCohortModal'


export default function PurchasesSec() {
  const [isFetching,setIsFetching] = useState(false)
  const [purchases,setPurchases] = useState([]);
  const [selectedSectionId,setSelectedSectionId] = useState()
  const [showInvitationModal,setShowInvitationModal]=useState(false);

  useEffect(() => {
    get_purchases();
  }, [])

  async function get_purchases()
  {
    setIsFetching(true)
    const res = await Axios.get('student/purchases');
    if(res.data.status == 'success'){
      setPurchases(res.data.data);
    }else{
      alert('Data could not be fetched.');
    }
    setIsFetching(false)
  }
  const handleInvite = (e,sectionId)=>{
    e.preventDefault();
    e.target.disabled = true;
    setSelectedSectionId(sectionId);
    setShowInvitationModal(true)
  }
    return (
      <>
         <section className="user-dashboard">
        <div className="container-fluid cf">
          <div className="row">
            <div className="col-md-12">
              <div className="payout_div">
                <table className="purchased_table table table-borderless">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Purchased Classes</th>
                      <th>No of Learners</th>
                      <th>Transaction ID</th>
                      <th>Payment Type</th>
                      <th>Date of Purchase</th> 
                      <th>Total Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isFetching ? <tr><td colSpan={6}><LoadingIcon /></td></tr> :  purchases.length ?
                     purchases.map((purchase,index)=>{
                        return (
                          <tr key={index}>
                            <td>{index+1}.</td>
                            <td width="42%">
                              <div className="myclass">
                                <div className="tb-row">
                                  <div className="tb-cell">
                                    <img className="img-responsive earning_img" src={purchase.class_photo} />
                                  </div>
                                  <div className="tb-cell text-left pd-left">
                                      <p className="class-name"><Link to={"/class-details/"+GetStringForUrl(purchase.class_title)+'/'+purchase.class_uid}><strong>{purchase.class_title}</strong></Link>
                                      </p>
                                      <p><a href="#" onClick={(e)=>{handleInvite(e,purchase.section_id) } }>Invite</a> </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>{purchase.no_of_learners}</td>
                            <td>{purchase.transaction_id}</td>
                            <td>{purchase.payment_type}</td>
                            <td>{purchase.payment_date}</td>
                            <td>${purchase.amount}</td>
                        </tr>
  
                        )
                    }) : <tr><td colSpan={6}>No Purchases found.</td></tr> }

                  </tbody></table>
              </div>
            </div>
          </div>
        </div>
        {selectedSectionId ? <InviteCohortModal invitationFor={'purchased_class'} sectionId={selectedSectionId} show={showInvitationModal} setShowInvitationModal={setShowInvitationModal} /> :''}
      </section>
      </>
    );
}
